import Text from "../text";
import Icon from "react-eva-icons";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles(() => ({
  listItem: {
    cursor: "pointer",
    boxSizing: "border-box",
    padding: "12px 24px",
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    width: "100%",
    background: "white",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(250,250,250)"
    }
  },
  editIcon: {
    position: "absolute",
    top: 12,
    right: 4,
    width: "24px",
    height: "24px"
  }
}));

function AddressItem({ address, onClick, editable = false, last = false }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div onClick={onClick} className={classes.listItem} style={{ borderBottom: last ? "" : "2px solid rgb(239,239,239)", background: editable ? "transparent" : "white", padding: editable ? "8px 8px" : "12px 24px" }}>
      <div style={{ marginTop: "6px" }}>
        <Icon name={"pin-outline"} size="medium" fill={theme.palette.primary.main} />
      </div>
      <div style={{ marginLeft: "16px" }}>
        <Text variant={"body2"} component={"p"}>
          {address.primaryLine}
        </Text>
        <Text variant={"body2"} component={"p"} style={{ opacity: "0.6" }}>
          {address.secondaryLineWithZipCode}
        </Text>
      </div>
      {editable && (
        <div className={classes.editIcon}>
          <Icon name={"edit-outline"} size="medium" fill={"grey"} />
        </div>
      )}
    </div>
  );
}

export default AddressItem;
