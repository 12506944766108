import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ReactBnbGallery from 'react-bnb-gallery';
import { isEmojiOnly,isToday } from '../../utils/methods.utils';
import Audio from '../../components/audio-handler/Audio';
import Text from '../../views/task/edit task/text'
import 'react-bnb-gallery/dist/style.css';
import { Tooltip } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import moment from "moment";
import AttachmentHandler from '../../views/task/edit task/components/attachment-handler';


const MessageListItem = ({ message, taskerUid, nextUid , previous, next}) => {
    let isMine = message.uid;
    let nextContinue = next?.uid == message.uid && (next != null && moment(next.createAt).subtract(moment(message.createAt).get("hours"), "hours").get("hours") < 1);
    let prevContinue = previous?.uid == message.uid && (previous != null && moment(message.createAt).subtract(moment(previous.createAt).get("hours"), "hours").get("hours") < 1);
    const useStyles = makeStyles(theme => ({
      backgroundTooltip: {
          borderRadius: "14px",
          background: "rgb(30,30,30)",
          padding: "6px 8px"
      }
  }));
  const classes = useStyles();  

  const [galleryStatus, setGalleryStatus] = useState({
    isOpen: false,
    currentPhoto: null
  });

 
  const onPhotoPress = (index) => {
    setGalleryStatus({
      isOpen: true,
      currentPhoto: index
    });
  };
  const onGalleryClose = () => {
    setGalleryStatus({
      isOpen: false,
      currentPhoto: null
    });
  };
  const { isOpen } = galleryStatus.isOpen;
  const photosToShow = message.images;
  return (
    <Tooltip
    placement={isMine ? "left" : "right"}
    classes={{
        tooltip: classes.backgroundTooltip
    }}
    title={<Text variant={"caption"} medium style={{ color: "white", padding: "4px 8px" }}>
        {`${isToday(message.createAt) ? "Today" : moment(message.createAt).format("MMM DD")} at ${moment(message.createAt).format("hh:mm A")}`}
    </Text>}
    disableFocusListener style={{ background: "black" }}
    >
  <div style={{
  maxWidth: '400px',
  width: 'fit-content',
  marginTop: '2px',
  marginBottom: message.uid !== nextUid ? '12px' : '1px',
  marginLeft: '24px',
  marginRight: '24px',
  padding: '10px 14px',
  border: message.uid == taskerUid ? '2px solid transparent' : '2px solid rgb(240,240,240)',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  background: message.uid == taskerUid ? 'rgba(233, 233, 236, 0.8)' : 'transparent'
}}
>
 
  {
    message.type === 'text' ? (
      <Typography variant={isEmojiOnly(message.text) ? 'body1' : 'body2'} regular>{message.text}</Typography>)
      :
      message.type === 'audio' ? (
        <Audio
                    style={{
                        padding: "7px 14px",
                        borderTopLeftRadius: (prevContinue && !isMine) ? "4px" : "20px",
                        borderTopRightRadius: (prevContinue && isMine) ? "4px" : "20px",
                        borderBottomLeftRadius: (nextContinue && !isMine) ? "4px" : "20px",
                        borderBottomRightRadius: (nextContinue && isMine) ? "4px" : "20px",
                    }}
                    mine={isMine} id={message.id} src={message?.audio} audioDuration={message?.audioDuration} />
        
        ):
        message.type === 'call' ? (
          <div>
            <Text variant="body2">[Call]</Text>
          </div>
          
          )
      :
        (
          <>
           {message?.type == "image" && message?.images?.length > 0 && <div style={{ margin: "12px 0", width: "300px", }}>
                <AttachmentHandler maxHeight="300px" images={message?.images} />

                </div>}
                      
          </>
        )
  }
  <ReactBnbGallery
    show={galleryStatus.isOpen}
    photos={photosToShow}
    activePhotoIndex={galleryStatus.currentPhoto}
    onClose={onGalleryClose}
    wrap={false}
    showThumbnails={false}
    backgroundColor='#000000'
  />
</div>

  
</Tooltip>
   
  );
};

export default MessageListItem;
 

