import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Messaging from '../private-messaging/messaging-index';
import { useNavigate, useParams } from 'react-router-dom';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },

  },
  contentRoot:{
    padding:'0'
  }
}));

// eslint-disable-next-line react/prop-types,no-unused-vars
const OfferChat = ({ opened = true ,closeDialog,taskId}) => {
  const classes = useStyles();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(opened);

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          navigate(`/app/tasks/${taskId}`);
          setOpenDialog(false);
          closeDialog();
        }}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogContent
          classes={{ root: classes.contentRoot }}
        >
          <Messaging allowSend={false}/>
        </DialogContent>
        {/*        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            {!loading ? 'Close' : <CircularProgress />}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default OfferChat;
