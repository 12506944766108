import React from "react";
import Collapse from "@material-ui/core/Collapse";
import Text from "../../text";

function FormErrorText(
    {
        error,
        showError,
        style
    }) {
    let errorValue;

    if (typeof error === "string")
        errorValue = error;
    else
        errorValue = error?.text;

    return (
        <Collapse in={showError && errorValue !== undefined && errorValue !== ""} style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: '8px 0', background: "rgba(500, 0, 0, 0.05)", borderRadius: "8px", padding: "8px 14px", ...style }}>
                <Text variant={"caption"} medium style={{ color: "rgb(198,53,89)", marginBottom: "2px", minHeight: "12px", textTransform: "none" }} >
                    {errorValue}
                </Text>
            </div>
        </Collapse>
    )
}

export default FormErrorText;