import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../../redux/index.reducers";
import Address from "../address";
// import { updateAddress } from "../../redux/actions/user-actions";
import DialogWrapper from "./dialogs/dialog-wrapper";
import { useTheme } from "@material-ui/core";
import Text from "../text";
import firebaseUtility, {users, privateUsers ,} from "../../../../utils/firebase.utils";
import { FaPlus, FaPlusCircle, FiMapPin, FiSearch } from "react-icons/all";
import ListContainer from "../list-handlers/list-container";
import AddressItem from "../list-handlers/address-tem";
import FormInput from "./input-fields/form-input";
import FormWrapper from "./input-fields/form-wrapper";
import MenuWrapper from "../menu-popper/menu-wrapper";
import MenuListItem from "../menu-popper/menu-list-item";
import CircularProgressBar from "./progress-bar/circular-progress-bar";
import DialogBottomButton from "./dialogs/dialog-bottom-button";
import Fade from "@material-ui/core/Fade";

const AddressHandler = ({ type, height, error = false,onUpdate, addressToEdit }) => {
  const userId =localStorage.getItem('userID');
  const theme = useTheme();

  const [addressDialog, setAddressDialog] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [dialog, setDialog] = useState("loading");
  const [defaultBillingAddressID, setDefaultBillingAddressID] = useState("");
  const [defaultTaskAddressID, setDefaultTaskAddressID] = useState("");
  const [userData , setUserData]=useState(null);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState(null);

  const [autoComplete, setAutoComplete] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);

  useEffect(() => {
   getUserData();
  }, []);

  const getUserData = async () => {
    setAddressLoading(true);
    const googleMaps = window["google"];
    const maps = googleMaps?.maps;
    if (maps?.places) {
      setAutoComplete(new maps.places.AutocompleteService());
      setPlaceDetails(new maps.places.PlacesService(document.createElement("div")));
    }
    const userInfo = (await privateUsers.doc(userId).get()).data();
    setUserData(userInfo);
    const allAddresses = await privateUsers.doc(userId).collection('addresses').get();
    let allUserAddress = []
    for (let index = 0; index < allAddresses.docs.length; index++) {
      const element = allAddresses.docs[index].data();
      allUserAddress.push(new Address(element));
    }
    if(addressToEdit == null){
      let addressToSet = allUserAddress.find((address) => address.id == userInfo.defaultTaskAddress);
    if (addressToSet) {
      setAddress(addressToSet);
    }}
if(allUserAddress.length>0){
  setAddresses(allUserAddress);
  setDialog("select-address");

}else{
    setAddresses([]);
    setDialog("add-address");
}
setDefaultTaskAddressID(userInfo.defaultTaskAddress);
setDefaultBillingAddressID(userInfo.defaultBillingAddressID);

setAddressLoading(false);
  }

  const openDialog = () => {
    if (addresses.length > 0) setDialog("select-address");
    else setDialog("add-address");
    setAddressDialog(true);
  };

  const closeDialog = () => {
    setDialog("loading");
    setAddress(null);
    setQuery("");
    setResults([]);
    setAddressDialog(false);
  };

  const returnToRoot = () => {
    if (dialog==="add-address" && addresses.length > 0) {
      setDialog("");
      setTimeout(() => {
        setDialog("select-address");
      }, 450);
    } else if (dialog==="confirm-address") {
      setDialog("");
      setTimeout(() => {
        setDialog("add-address");
      }, 450);
    } else {
      closeDialog();
    }
  };

  const changeDialog = (value="") => {
    setDialog("");
    setTimeout(() => {
      setDialog(value);
    }, 450);
  };

  const onValueChange = (values) => {
    if (values["address"]) {
      setQuery(values["address"].trim());
      clearTimeout(timer);
      if (values["address"].trim().length > 0) {
        setTimer(
          setTimeout(() => {
            getResults(values["address"].trim()).then();
          }, 500)
        );
      } else {
        setResults([]);
        setLoading(false);
      }
    } else {
      setQuery("");
      setLoading(false);
      setResults([]);
    }
  };

  const getResults = async (q="") => {
    setLoading(true);
    // TODO Location Redux
    // if(currentLocation) {
    //    latitude = tasksProvider.currentLocation.latitude.toString();
    //    longitude = tasksProvider.currentLocation.longitude.toString();
    // }
    autoComplete.getPlacePredictions({ types: ["address"], componentRestrictions: { country: "us" }, input: q }, (p) => {
      setResults(p ?? []);
      setLoading(false);
    });
  };

  const selectAddress = (placeId) => {
    try {
      placeDetails.getDetails({ placeId: placeId }, (place) => {
        let newAddress = new Address();
        let geometry = place["geometry"]["location"];
        newAddress.lat = geometry.lat();
        newAddress.lng = geometry.lng();

        place["address_components"].forEach((element) => {
          let types = element["types"];

          if (types.findIndex((element) => element === "street_number") !== -1) newAddress.addressLineOne = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "route") !== -1) newAddress.addressLineOne = (newAddress.addressLineOne ? newAddress.addressLineOne + " " : "") + element["short_name"] ?? "";

          newAddress.addressLineOne = newAddress.addressLineOne.trim();

          if (types.findIndex((element) => element === "locality") !== -1) newAddress.city = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "administrative_area_level_1") !== -1) newAddress.state = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "country") !== -1) newAddress.country = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "postal_code") !== -1) newAddress.zipCode = element["short_name"] ?? "";
        });

        // Confirm we have the correct city details (Exceptions in NY, USA)
        if (newAddress.city === null || newAddress.city === "") {
          const { maps } = window["google"];
          if (maps.places) {
            let geoCoder = new maps.places.GeoCoder();
            geoCoder.geocode(
              {
                location: {
                  lat: newAddress.lat,
                  lng: newAddress.lng
                }
              },
              (result) => {
                newAddress.city = result[0]["address_components"][0]["short_name"] ?? "";
                newAddress.placeId = place["place_id"];
                setAddress(newAddress);
              }
            );
          }
        } else {
          newAddress.placeId = place["place_id"];
          setAddress(newAddress);
        }
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const saveAddress = (values) => {
    return new Promise(async () => {
      address.addressLineOne = values["addressLineOne"];
      address.addressLineTwo = values["addressLineTwo"];
      address.city = values["city"];
      address.state = values["state"];
      address.zipCode = values["zipCode"];
      onUpdate(address);

      closeDialog();
    });
  };

  return (
    <div>
      {!addressLoading ? (
        <div>
          {(type === "task" && !defaultTaskAddressID) || (type === "billing" && !defaultBillingAddressID) ? (
            <div onClick={openDialog} style={{ border: error ? "2px solid rgba(235, 80, 60, 0.5)" : "2px solid rgb(232,232,232,0.9)", background: "white", borderRadius: "6px", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.02)" }}>
              <FaPlusCircle style={{ color: theme.palette.primary.main, marginRight: "16px" }} size={15} />
              <Text variant={"body2"} style={{ color: theme.palette.primary.main }} medium selectable={false}>
                {addresses.length > 0 ? `Select ${type === "task" ? "Task" : "Billing"} Address` : `Add ${type === "task" ? "Task" : "Billing"} Address`}
              </Text>
            </div>
          ) : type === "task" ? (
            <AddressItem address={addresses.find((a) => a.id === defaultTaskAddressID)} onClick={openDialog} editable last />
          ) : type === "edit-task" ? (
            <AddressItem address={addressToEdit} onClick={openDialog} editable last />
          ) : (
            <AddressItem address={addresses.find((a) => a.id === defaultBillingAddressID)} onClick={openDialog} editable last />
          )}
        </div>
      ) : (
        <div style={{ border: "2px solid rgb(232,232,232,0.9)", background: "white", borderRadius: "6px", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.02)" }}>
          <Text variant={"body2"} style={{ color: "grey" }} medium selectable={false}>
            Retrieving Addresses...
          </Text>
        </div>
      )}
      <DialogWrapper height={height} disableBackdrop root={dialog === "select-address" || dialog === "loading" || (!addressLoading && addresses.length === 0 && dialog === "add-address")} onReturn={returnToRoot} title={(dialog === "add-address" || dialog === "confirm-address" || dialog === "") ? "Add Address" : "Select Address"} open={addressDialog} onClose={closeDialog}>
        <div style={{ height: "500px" }}>
          <div style={{ height: "2px" }} />
          <Fade in={dialog === "select-address"} mountOnEnter unmountOnExit>
            <div>
              <Text variant={"overline"} style={{ opacity: "0.6", margin: "12px 0" }}>
                Saved Addresses
              </Text>
              <ListContainer>
                {addresses.map((address, i) => {
                  return (
                    <AddressItem
                      key={i}
                      address={address}
                      onClick={() => {
                        if (type === "task" || type === "edit-task") {
                          privateUsers.doc(userData.uid).set({ defaultTaskAddress: address.id }, { merge: true }).then();
                          // dispatch(updateAddress({ defaultTaskAddress: address.id }));
                          closeDialog();
                        } else {
                          privateUsers.doc(userData.uid).set({ defaultBillingAddress: address.id }, { merge: true }).then();
                          // dispatch(updateAddress({ defaultBillingAddress: address.id }));
                          closeDialog();
                        }
                      }}
                    />
                  );
                })}
                <div
                  style={{ background: "white", width: "100%", boxSizing: "border-box", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer" }}
                  onClick={() => {
                    changeDialog("add-address");
                  }}
                >
                  <FaPlus style={{ color: theme.palette.primary.main, marginRight: "16px" }} size={16} />
                  <Text variant={"body2"} style={{ color: theme.palette.primary.main }} bold selectable={false}>
                    Add Address
                  </Text>
                </div>
              </ListContainer>
            </div>
          </Fade>
          <Fade in={dialog === "confirm-address" || dialog === "add-address"} mountOnEnter unmountOnExit>
            <div>
              <Fade in={dialog === "add-address"} mountOnEnter unmountOnExit>
                <FormWrapper
                  onSubmit={() => {
                    return new Promise(() => { });
                  }}
                  onValueChange={onValueChange}
                  initialValues={{ address: query ?? "" }}
                  style={{ position: "relative" }}
                >
                  <MenuWrapper
                    live
                    disableToggle
                    items={
                      <div style={{ height: "auto" }}>
                        {query.trim().length === 0 ? (
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px" }}>
                            <FiSearch style={{ color: "grey", marginRight: "12px" }} size={14} />
                            <Text variant={"caption"} style={{ color: "grey", marginRight: "12px" }}>
                              Start Typing...
                            </Text>
                          </div>
                        ) : (
                          <div style={{ minHeight: "300px" }}>
                            <div style={{ opacity: !loading ? 1 : 0, transition: "0.3s" }}>
                              {results && results?.length > 0 ? (
                                results?.map((r) => {
                                  return (
                                    <MenuListItem
                                      key={r["place_id"]}
                                      icon={<FiMapPin size={14} style={{ flexShrink: 0 }} />}
                                      onClick={() => {
                                        setAddress(null);
                                        selectAddress(r["place_id"]);
                                        setDialog("confirm-address");
                                      }}
                                    >
                                      <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                                        <Text component={"span"} variant={"body2"}>
                                          {r["structured_formatting"]["main_text"]}
                                        </Text>
                                        <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                                          {r["structured_formatting"]["secondary_text"]}
                                        </Text>
                                      </p>
                                    </MenuListItem>
                                  );
                                })
                              ) : ((!loading && results?.length === 0) ?
                                <MenuListItem>
                                  <Text variant={"body2"}>We couldn't find anything match your search.</Text>
                                </MenuListItem>
                                      :
                                <div/>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    <FormInput autoComplete={"off"} name={"address"} placeholder={"Search Address"} endAdornment={<FiSearch style={{ color: "grey", marginRight: "12px" }} size={18} />} />
                  </MenuWrapper>
                </FormWrapper>
              </Fade>
              <Fade in={dialog === "confirm-address"} style={{ transitionDelay: "0.4s" }} mountOnEnter unmountOnExit>
                <div style={{ height: "100%" }}>
                  {address ? (
                    <FormWrapper
                      onSubmit={saveAddress}
                      onValueChange={onValueChange}
                      initialValues={{
                        addressLineOne: address.addressLineOne,
                        addressLineTwo: address.addressLineTwo,
                        city: address.city,
                        state: address.state,
                        zipCode: address.zipCode
                      }}
                    >
                      <FormInput name={"addressLineOne"} placeholder={"Street Address"} required />
                      <FormInput name={"addressLineTwo"} placeholder={"Apt. / Suite (Optional)"} />
                      <div style={{ display: "flex" }}>
                        <FormInput name={"city"} rootStyle={{ flexGrow: "unset" }} placeholder={"City"} required />
                        <div style={{ width: "16px", flexShrink: 0 }} />
                        <FormInput name={"state"} maxLength={"2"} rootStyle={{ width: "64px" }} style={{ textTransform: "uppercase" }} placeholder={"State"} required />
                        <div style={{ width: "16px", flexShrink: 0 }} />
                        <FormInput name={"zipCode"} rootStyle={{ width: "112px" }} maxLength={"5"} placeholder={"Zip"} required />
                      </div>
                      <div style={{ height: "64px" }} />
                      <DialogBottomButton>Save</DialogBottomButton>
                    </FormWrapper>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-star",
                        alignItems: "center",
                        padding: "24px 0",
                        height: "300px"
                      }}
                    >
                      <CircularProgressBar />
                    </div>
                  )}
                </div>
              </Fade>
            </div>
          </Fade>
        </div>
      </DialogWrapper>
    </div>
  );
};

export default AddressHandler;
