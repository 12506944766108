import React, { useEffect, useLayoutEffect, useState ,useRef} from 'react';
import { Box } from '@material-ui/core';
import Page from '../../components/Page';
import RichTextEditor from './rich-text-editor'
import PrimaryButton from '../task/edit task/components/input-fields/primary-button';
import firebaseUtility from '../../utils/firebase.utils';
import FormInput from '../task/edit task/components/input-fields/form-input'
import { useParams , useNavigate } from 'react-router-dom';
import TagsInput from "./TagsInput";
import firebase from 'firebase';
import { toast } from 'react-toastify';
import FormWrapper from '../task/edit task/components/input-fields/form-wrapper';
import { BaseButton } from '../task/edit task/components/input-fields/primary-button';
import ImageInput from '../../components/image-input';
const EditBlog = () => {
  const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
  const [blog, setBlog] = useState();
  const [formValues, setFormValues] = useState();
  const [imagePath,setImagePath ]= useState('');
  const [avatarPath,setAvatarPath ]= useState('');
  const params = useParams();
  const storage = firebase.storage();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false); 
  const navigation = useNavigate();

  useEffect(() => {
    firebaseUtility.Firestore()
      .collection('blogs')
      .doc(params.id)
      .get()
      .then(async (blogDoc) => {
        let blogData = blogDoc.data();
        blogData.id = blogDoc.id;
        setBlog(blogData);
        setValue(blogData?.content?blogData?.content:[{ type: 'paragraph', children: [{ text: '' }] }] );
        setTags(blogData?.tags&&blogData?.tags?.length>0 ? blogData?.tags:[])
        setFormValues({
          title:blogData.title?? '',
          auth:blogData.author?? '' ,
          sub:blogData.subTitle??"",
           tags:blogData.tags?? [],
           image:blogData.image?? '',
           file:blogData.avatar ?? ''
          })
      });

  }, []);



  const handleSelecetedTags = (items) => {
    setTags(items);
  }

  const handleSubmit = async () => {
    if (formValues || tags || value) {
      setLoading(true);
      const file = imagePath? '' + imagePath :"";
      const image=avatarPath?''+avatarPath:'';
      const title = formValues?.title ?? '';
      const fileNameStart = 0;
      const fileNameEnd = imagePath?.name?.lastIndexOf('.');
      const fileName = file != '' ? imagePath?.name?.substring(fileNameStart, fileNameEnd):'';
      const content = value;
      let fileUrl = '';
      let blogFile='';
      const imageStart = 0;
      const imageNameEnd = avatarPath?.name?.lastIndexOf('.');
      const imageName = avatarPath?.name?.substring(imageStart, imageNameEnd);

      if (avatarPath&& (imageName != '')) {
        await storage.ref(`/Blogs-Authors/${imageName}`).put(avatarPath);
        fileUrl =  await storage.ref("Blogs-Authors").child(imageName).getDownloadURL();
      }
      if (imagePath && (fileName != '')) {
        await storage.ref(`/Blogs_images/${fileName}`).put(imagePath);
        blogFile = await storage.ref("Blogs_images").child(fileName).getDownloadURL();
      }

      await firebaseUtility.Firestore().collection('blogs').doc(blog.id).set({
        title: title ?? blog.title,
        subTitle: formValues.sub ?? blog.subTitle,
        author: formValues.auth ?? blog.author,
        tags: tags ,
        image:blogFile &&blogFile!=''?blogFile:blog.image,
        avatar: fileUrl &&fileUrl!=''? fileUrl:blog.avatar,
        updateAt: new Date(),
        content:content ,
        slug: title.replace(/\s/g, '-') ?? blog.slug,
      }, { merge: true });
      
      setLoading(false);
      toast.success('Saved!')
      navigation('/app/blogs-list', { replace: true })
    }
  }

  return (
    <Page title="Edit Blog">
      <FormWrapper onValueChange={(values) => { setFormValues(values) }}>
        {blog && <div>
          <Box style={{ width: "650px" }}>

            <FormInput
              label={'Title'}
              placeholder={"Title"}
              name={'title'}
              type={'text'}
              defaultValue={blog.title}
            />

          </Box>
          <Box style={{ width: "650px" }}>
            <FormInput
              label={'Subtitle'}
              placeholder={"Subtitle"}
              name={'sub'}
              defaultValue={blog.subTitle}
              type={'text'}
            />
          </Box>
          <Box style={{ width: "650px" }}>
            <FormInput
              label={'Author Name'}
              placeholder={"Author"}
              name={'auth'}
              type={'text'}
              defaultValue={blog.author}
            />
          </Box>
          <Box style={{ width: "650px" }}>
          <ImageInput onChange={setAvatarPath} name='file' label={'Author Image'} url={blog?.avatar} />
          </Box>
          <div style={{ height: "30px" }}></div>
          <Box style={{ width: "650px" }}>
          <ImageInput onChange={setImagePath} name='image' label={'Blog Image'} url={blog?.image} />
          </Box>
          <div style={{ height: "30px" }}></div>
          <div style={{ width: "650px", maxWidth: '100%' }}>
            <TagsInput
              selectedTags={(item) => handleSelecetedTags(item)}
              fullWidth
              variant="outlined"
              id="tags"
              name="tags"
              defaultValue={tags}
              placeholder="Add Tags"
            />
          </div>
          <div style={{ height: "30px" }}></div>
        </div>}
        {blog && <Box style={{ width: "650px" }}>
          <RichTextEditor value={blog.content} onChange={(newValue) => {setValue(newValue) }} placeholder={'Enter'} />
          <div style={{ height: "50px" }}></div>
        </Box>}
        {
          blog && <Box>
            <div>
              <BaseButton
                isLoading={loading}
                type='button'
                onClick={async () => { await handleSubmit() }}>
                Done
              </BaseButton>
            </div>
          </Box>
        }
      </FormWrapper>
    </Page>
  );
};

export default EditBlog;
