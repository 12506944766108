import React, { useEffect, useState } from "react";
import Text from "./text";
import useTheme from "@material-ui/core/styles/useTheme";
import moment from "moment";
import { Task } from "./task";
import { useNavigate } from 'react-router-dom';
import ListContainer from "./list-container";
import firebaseUtility, { tasks } from "../../../utils/firebase.utils";
import { isToday } from "../../../utils/methods.utils";
import MenuWrapper from "./menu-popper/menu-wrapper";
import NumberFormat from "react-number-format";
import Address from "./address";
import MenuListItem from "./menu-popper/menu-list-item";
import { FiMoon, FiSun, FiSunrise, FiSunset } from "react-icons/fi";
import { FaPlusCircle, FiXCircle } from "react-icons/all";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";
import FormWrapper from "./components/input-fields/form-wrapper";
import FormInput from "./components/input-fields/form-input";
import AddressHandler from "./components/address-handler";
import AttachmentHandler from "./components/attachment-handler";
import FormSwitch from "./components/input-fields/form-switch";
import FormErrorText from "./components/input-fields/form-error-text";
import BudgetInput, { BudgetData } from "./task-components/offers/budget-input";
import DialogBottomButton from "./components/dialogs/dialog-bottom-button";
import DialogWrapper from "./components/dialogs/dialog-wrapper";
import TaskAttachmentsForm from "./dialog-form-post-task/task-attachments-form";
import  "./style.css";

const Fade = require("react-reveal/Fade");

const EditTask = ({ task,onTaskChange, path, onClose }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAttacmentsDialog, setOpenAttacmentsDialog] = useState(false);
  const [error, setError] = useState("");
  const [budgetData, setBudgetData] = useState(new BudgetData({
    budgetType: task?.isHourly ? "hourly" : "total",
    totalBudget: task?.budget,
    hourlyBudget: task?.budgetByHour,
    hours: task?.hours,
    noOfTaskers: task?.taskerRequired,
  }));
  const [date, setDate] = useState(null);
  const [timings, setTimings] = useState([]);
  const [updatedTask, setUpdatedTask] = useState(task ? task : null);
  const [taskReqs, setTaskReqs] = useState(task.requirements.length > 0 ? task.requirements : []);
  const [isRemoteTask, setIsRemoteTask] = useState(task.isRemote);
  const [taskAddress, setTaskAddress] = useState(new Address(task.address));
  const [dateError, setDateerror] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setTimings(updatedTask.timings ?? []);
    setDate(updatedTask.createAt);
  }, [task, isRemoteTask]);

  const submitEditTask = (values) => {
    return new Promise((resolve, reject) => {

      if (budgetData.errors) {
        reject(budgetData.errors);
      } else if (typeof values.title === "string" && values.title.length < 10) {

        reject("Please enter at least 10 characters for the Title.");
      } else if (typeof values.title === "string" && values.title.length > 80) {

        reject("Please enter a maximum of 80 characters for the Title.");
      } else if (typeof values.description === "string" && values.description.length < 25) {

        reject("Please enter at least 25 characters for the Desciption.");
      } else {

        let taskToSave = new Task(updatedTask);
        taskToSave.budget = budgetData.budget();
        if (budgetData.budgetType === "hourly") {
          taskToSave.budgetByHour = budgetData.hourlyBudget;
          taskToSave.hours = budgetData.hours;
          taskToSave.taskerRequired = budgetData.noOfTaskers;
          taskToSave.isMultipleTasker = budgetData.isMultipleTaskers;
        }
        taskToSave.title = values.title && values.title !== "" && typeof values.title == "string" ? values.title : updatedTask.title;
        taskToSave.description = values.description && values.description !== "" && typeof values.description == "string" ? values.description : updatedTask.description;
        taskToSave.address = isRemoteTask ? null : taskAddress;
        taskToSave.requirements = taskReqs;
        taskToSave.timings = timings;

         taskToSave.city= isRemoteTask ? null :taskAddress.city;
          taskToSave.isRemote = taskToSave.city != null ? false :true;

        let t = JSON.parse(JSON.stringify(taskToSave.toJson()));
        t["createAt"] = new Date(t["createAt"]);
        t["dateToStart"] = new Date(t["dateToStart"]);
        if(dateError!=""){
          reject(dateError); 
        }else{
        setSubmitLoading(true);
        tasks
          .doc(updatedTask.id)
          .update(t)
          .then((res) => {
            onTaskChange(taskToSave);
            setSubmitLoading(false);
            onClose();
            return resolve("Success");
          })
          .catch((err) => {
            setSubmitLoading(false);
            return reject(err);
          });
        }
      }
    });
  };
  const toggleTiming = (value) => {
    let taskData = updatedTask;
    let t = [...timings];
    if (t.includes(value)) t.splice(t.indexOf(value), 1);
    else t.push(value);
    setTimings(t);
    taskData.timings = t;
    setUpdatedTask(taskData);
  };
  const checkDate = (hours, customDate) => {
    if (customDate) return (customDate && ((isToday(customDate) && new Date().getHours() < hours) || !isToday(customDate)));
    else return (date && ((isToday(date) && new Date().getHours() < hours) || !isToday(date)));
  };
 
  const onDateTimingValueChange = (values) => {
    let taskData = updatedTask;
    taskData.dateToStart = new Date(values.dateToStart + "T22:00:00");
    if (taskData.dateToStart >= new Date()) {
      setDateerror("");
      setUpdatedTask(taskData);
      let dateCheck = new Date(values.dateToStart + "T22:00:00");
      if (isToday(taskData.dateToStart)) {
        dateCheck = new Date();
      }
      setDate(new Date(dateCheck));
      let removal = [];
      if (!checkDate(10, dateCheck)) removal.push("morning");
      if (!checkDate(14, dateCheck)) removal.push("midday");
      if (!checkDate(18, dateCheck)) removal.push("afternoon");
      if (!checkDate(23, dateCheck)) removal.push("evening");
      removeTimings(removal);
    } else {
      setDateerror("Task date shouldn't be in the past.");
    }
  }
  const removeTimings = (values = []) => {
    let taskData = updatedTask;
    if (values.length > 0) {
      let t = [...timings];
      values.forEach((value) => {
        if (t.includes(value)) t.splice(t.indexOf(value), 1);
      });
      setTimings(t);
      taskData.timings = t;
      setUpdatedTask(taskData);
    }
  };
  const submitRequerment = (values) => {
    return new Promise((resolve, reject) => {
      const newReq = typeof values?.reqText == "string" ? values?.reqText : "";
      if (newReq !== "") {
        setTaskReqs([...taskReqs, newReq]);
        resolve("Success");
      }
    });
  };
  const deleteRequerment = (indexToRemove) => {
    const requerments = [];
    for (let index = 0; index < taskReqs?.length; index++) {
      if (index !== indexToRemove) {
        requerments.push(taskReqs[index]);
      }
    }
    setTaskReqs(requerments);
  };

  const onValueChange = (values) => {
    if (values?.isRemote !== isRemoteTask && (values?.isRemote == true || values?.isRemote == false)) {
      setIsRemoteTask(values?.isRemote);
    }
  };
  const getHorizontalRule = () => (
    <div
      style={{
        height: "2px",
        background: "rgb(240,240,240)",
        width: "100%",
        margin: "12px 0"
      }}
    />
  );

  return (
    <FormWrapper onValueChange={onValueChange}
      onSubmit={submitEditTask} initialValues={{ isRemote: isRemoteTask !== null ? isRemoteTask : false, }}>
      <Text variant={"body2"} style={{ color: theme.palette.primary.main, marginTop: "10px" }} medium>
        Task Title
      </Text>
      <FormInput name={"title"} defaultValue={task?.title} autoCapitalize={"sentences"} placeholder={"What do you need done?"} required />
      <FormWrapper
        onSubmit={(values) => new Promise((resolve, reject) => { })}
        initialValues={{
          dateToStart: updatedTask && updatedTask.dateToStart ? moment(updatedTask.dateToStart).format("YYYY-MM-DD") : ""
        }}
        style={{ position: "unset" }}
        onValueChange={onDateTimingValueChange}
      >
        <Text variant={"body2"} style={{ color: theme.palette.primary.main, marginTop: "10px" }} medium>
          Task Date
        </Text>
        <FormInput min={moment(new Date()).format("YYYY-MM-DD")} placeholder={"Select a date"} name={"dateToStart"} type={"date"} required />
      </FormWrapper>
      <MenuWrapper
        style={{ width: "83%", left: 10 }}
        live
        disableToggle
        items={
          <div style={{ height: "auto", margin: "0px auto" }}>
            <div>
              <div>
                <MenuListItem
                  key={"Morning"}
                  icon={<FiSunrise size={22} style={{ color: timings.includes("Morning") ? theme.palette.primary.main : "grey", flexShrink: 0 }} />}
                  onClick={() => {
                    if (checkDate(10)) toggleTiming("Morning");
                  }}
                >
                  <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                    <Text component={"span"} variant={"body2"}>
                      Morning
                    </Text>
                    <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                      Before 10AM
                    </Text>
                  </p>
                </MenuListItem>
                <MenuListItem
                  key={"Midday"}
                  icon={<FiSun size={22} style={{ color: timings.includes("Midday") ? theme.palette.primary.main : "grey", flexShrink: 0 }} />}
                  onClick={() => {
                    if (checkDate(10)) toggleTiming("Midday");
                  }}
                >
                  <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                    <Text component={"span"} variant={"body2"}>
                      Midday
                    </Text>
                    <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                      10AM - 2PM
                    </Text>
                  </p>
                </MenuListItem>
                <MenuListItem
                  key={"Afternoon"}
                  icon={<FiSunset size={22} style={{ color: timings.includes("Afternoon") ? theme.palette.primary.main : "grey", flexShrink: 0 }} />}
                  onClick={() => {
                    if (checkDate(10)) toggleTiming("Afternoon");
                  }}
                >
                  <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                    <Text component={"span"} variant={"body2"}>
                      Afternoon
                    </Text>
                    <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                      2PM - 6PM
                    </Text>
                  </p>
                </MenuListItem>
                <MenuListItem
                  key={"Evening"}
                  icon={<FiMoon size={22} style={{ color: timings.includes("Evening") ? theme.palette.primary.main : "grey", flexShrink: 0 }} />}
                  onClick={() => {
                    if (checkDate(10)) toggleTiming("Evening");
                  }}
                >
                  <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                    <Text component={"span"} variant={"body2"}>
                      Evening
                    </Text>
                    <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                      After 6PM
                    </Text>
                  </p>
                </MenuListItem>
              </div>
            </div>
          </div>
        }

      >
        <ListContainer style={{ padding: "3% 3%", width: "100%", background: "white" }}>
          <div style={{ width: "100%", height: "80%", color: "black" }}>{`${timings?.length > 0 && timings?.length < 4 ? timings : timings?.length == 4 ? "Any Time" : "Day Timing"}`}</div>
        </ListContainer>
      </MenuWrapper>
      <Text variant={"body2"} style={{ color: theme.palette.primary.main, marginTop: "16px" }} medium>
        Task Description
      </Text>
      <FormInput name={"description"} defaultValue={updatedTask?.description} placeholder={"Add a more detailed description about the task you want done"} rowsMin={3} rowsMax={4} rows={3} multiline required />

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <FormWrapper onSubmit={submitRequerment} reset initialValues={{ reqText: "" }}>
          <ListContainer style={{ margin: "16px 0", padding: "3%", width: "100%", background: "white" }}>
            <div style={{ width: "100%" }}>
              <Text variant={"overline"} component={"p"} style={{ color: "#707070" }}>
                Requirements{" "}
              </Text>
              <FormInput
                placeholder={"e.g. Tools or Vehicles"}
                autoCapitalize={"sentences"}
                name={"reqText"}
                type={"text"}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton type={"submit"} disableFocusRipple disableTouchRipple disableRipple style={{ padding: "8px", marginRight: "12px", height: "32px", width: "32px" }}>
                      <FaPlusCircle fill={theme.palette.primary.main} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {taskReqs?.length > 0
                ? taskReqs?.map((req, index) => (
                  <div style={{ height: "auto", width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center", height: "auto", padding: "4px 10px", width: "100%", boxSizing: "border-box", justifyContent: "space-between" }} key={index}>
                      <Text variant={"body2"} regular component={"span"} style={{ textTransform: "capitalize" }}>
                        {req}
                      </Text>
                      <FiXCircle
                        size={18}
                        style={{ color: theme.palette.error.main, width: "18px", cursor: "pointer", verticalAlign: "middle" }}
                        onClick={() => {
                          deleteRequerment(index);
                        }}
                      />
                    </div>
                    {taskReqs?.length - 1 !== index ? getHorizontalRule() : null}
                  </div>
                ))
                : null}
            </div>
          </ListContainer>
        </FormWrapper>
      </div>
      {updatedTask?.attachments && updatedTask.attachments.length > 0 ? (
        <div style={{ margin: "12px 0" }}>
          <AttachmentHandler
            images={updatedTask.attachments}
            handleOpen={() => {
              setOpenAttacmentsDialog(true);
            }}
          />
        </div>
      ) : (
        <div
          onClick={() => {
            setOpenAttacmentsDialog(true);
          }}
          style={{ border: "2px solid rgb(232,232,232,0.9)", background: "white", borderRadius: "6px", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.02)" }}
        >
          <FaPlusCircle style={{ color: theme.palette.primary.main, marginRight: "16px" }} size={15} />
          <Text variant={"body2"} style={{ color: theme.palette.primary.main }} medium selectable={false}>
            Add Attachments
          </Text>
        </div>
      )}

      <DialogWrapper
        onClose={() => {
          navigate(`/app/${path}/${task?.id}/edit`);
        }}
        onReturn={() => {
          navigate(`/app/${path}/${task?.id}/edit`);
          setOpenAttacmentsDialog(false);
        }}
        open={openAttacmentsDialog}
        title={`Edit Attachments`}
      >
        <TaskAttachmentsForm
          task={updatedTask}
          updateTask={(updated) => {
            setUpdatedTask(updated);
          }}
          onContinue={() => {
            setOpenAttacmentsDialog(false);
          }}
        />
      </DialogWrapper>
      <Text variant={"body2"} style={{ color: theme.palette.primary.main, marginTop: "20px" }} medium>
        {task?.isHourly ? "Hourly Rate" : "Budget"}
      </Text>
      <BudgetInput
        task={task}
        placeholder="Budget"
        initialBudgetData={budgetData}
        initialTypeToView={budgetData?.budgetType}
        disableSwitchingBetweenTabs={true}
        onBudgetChange={(b) => {
          let t = new BudgetData(b.toJson());
          setBudgetData(t);
        }}
      />
      <FormErrorText style={{ marginTop: "16px" }} />
      <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Text variant={"body2"} medium>
          Can this task be completed remotely?
        </Text>
        <FormSwitch name={"isRemote"} />
      </div>
      <Fade duration={300} when={isRemoteTask !== null && !isRemoteTask} mountOnEnter unmountOnExit>
        <div style={{ marginTop: "12px" }} />
        <Text variant={"body2"} style={{ color: theme.palette.primary.main, margin: "8px 0" }} medium>
          Task Address
        </Text>
        <AddressHandler onUpdate={(address) => setTaskAddress(address)} height={"550px"} type={"edit-task"} addressToEdit={taskAddress} />
      </Fade>
      <div style={{ marginTop: error ? "200px" : "150px" }} />
      <DialogBottomButton
        isLoading={submitLoading} type={"submit"}
        data={
          <ListContainer style={{ padding: "12px", width: "90%", marginBottom: "12px", boxSizing: "border-box", background: "white" }}>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <div>
                  <Text variant={"body2"} bold component={"body2"} selectable={false}>
                    Estimated budget {budgetData.isMultipleTaskers ? `  ( × ${budgetData.noOfTaskers} )` : ""}
                  </Text>
                </div>
                <div>
                  <Text variant={"caption"} style={{ color: "grey" }} medium selectable={false}>
                    Final payment will be agreed on later.
                  </Text>
                </div>
              </div>
              <Text variant={"h5"} style={{ display: "flex", alignItems: "center", marginRight: "8px", flexShrink: 0 }} bold component={"h6"} selectable={false}>
                <Text component={"p"} variant={"body2"} style={{ marginRight: "2px" }} bold>
                  $
                </Text>
                {budgetData && budgetData.budget && budgetData.budget() ? <NumberFormat value={(budgetData.budget())} displayType={"text"} thousandSeparator={true} prefix={""} /> : 0}
              </Text>
            </div>
          </ListContainer>
        }
      >
        Update Task
      </DialogBottomButton>
    </FormWrapper>
  );
};
export default EditTask;