import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';

import getInitials from '../../utils/getInitials';

import SkeletoLoader from '../../components/skeletoLoader';
import EditUserDialog from './edit.user';
import { useNavigate, useParams } from 'react-router-dom';
import firebaseUtility from '../../utils/firebase.utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '15px',
  },
}));

// eslint-disable-next-line max-len
const Results = ({
  className,
  users,
  isLoading,
  pageIndex,
  pageSizeIndex,
  setPageSize,
  pageChanged,
  ...rest
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [page, setPage] = useState(pageIndex);
  const [loading] = useState(isLoading);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isloading,setIsLoading]=useState(users.length>0?false:true);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage || page);
    pageChanged(newPage || page);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="users"/>;

  const openEditUserDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(!openDialog);
    navigate(`/app/users/${user.uid}`);
  };
  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpenDialog(false);
      } else {
        const userInfo = await firebaseUtility.GetOneDocById(
          'users',
          params.id
        );
        setSelectedUser(userInfo);
        setOpenDialog(true);
      }
    })();
  }, [params,selectedUser]);
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Comunication Email</TableCell> 
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {users && users.length > 0 ?
                <TableBody>
                  {!loading
                   ? users.slice(0, limit).map((user) => (
                      <TableRow hover key={user.uid}>
                        <TableCell>
                          <Box alignItems="center" display="flex">
                           
                            <Avatar
                              style={{cursor:"pointer"}}
                              className={classes.avatar}
                              src={user.profilePicture}
                            
                            >
                              {getInitials(user.firstName)}
                            </Avatar>
                            <Typography color="textPrimary" variant="body1">
                              {user.firstName}
                              {' '}
                              {user.lastName}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.communicationEmail}</TableCell>

                        <TableCell>
                          <Button
                            variant="outlined"
                            className={classes.rounded}
                            onClick={() => openEditUserDialog(user)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                    : getLoadingSkeleton()}
                </TableBody>
                :
                <TableBody >
                  <TableCell colSpan={3}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                        {isloading?<CircularProgress color='#2c387e' size={30}/>:'No Results Found!'}
                  </div>
                  </TableCell>
                </TableBody>
              }
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={-1}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
      {openDialog ? (
        <EditUserDialog
          opened={openDialog}
          user={selectedUser || {}}
          badgesItems={selectedUser.badges}
          closeDialog={(user) => {
            setOpenDialog(false);
            if (user) {
              setSelectedUser(user);
              const indexToUpdate = users.find((i) => i.uid == user.uid);
              users[indexToUpdate] = JSON.parse(JSON.stringify(user));
            }
            navigate(`/app/users`);
          }}
        />
      ) : null}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func,
  pageChanged: PropTypes.func,
  pageSizeIndex: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default Results;
