import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import { ToastContainer } from 'react-toastify';
import theme from './theme';
import routes from './routes';
import firebaseUtility from './utils/firebase.utils';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    firebaseUtility.Auth().onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem('userID', user.uid);
        if (localStorage.getItem('isAuth') == null) {
          localStorage.setItem('isAuth', true);
          navigate('/app/users', { replace: true });
        }
      } else {
        localStorage.removeItem('isAuth');
        localStorage.removeItem('userID');
        navigate('/login', { replace: true });
      }
    });
  }, []);
  const routing = useRoutes(routes(localStorage.getItem('isAuth')));
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer closeOnClick hideProgressBar={false} />
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
