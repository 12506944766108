import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowUpRight } from "react-feather";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    primary: {
        opacity: "1",
        cursor: "pointer",
        padding: "8px 0px",
        color: theme.palette.primary.main,
        position: "relative",
        transition: theme.transitions.create(['opacity'], {
            easing: "cubic-bezier(.6,.19,.04,.92)",
            duration: "0.2s",
        }),
    },
    secondary: {
        opacity: "1",
        cursor: "pointer",
        padding: "8px 0px",
        color: theme.palette.primary.main,
        position: "relative",
        transition: theme.transitions.create(['text-decoration'], {
            easing: "cubic-bezier(.6,.19,.04,.92)",
            duration: "0.2s",
        }),
        "&:hover": {
            textDecoration: "underline"
        }
    },
    disabled: {
        pointerEvents: "none"
    }
}));

function InlineLink({ children, variant = "primary", onClick, to, style = {}, ariaControls, ariaHasPopup, disabled, spanStyle = {} }) {

    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const switchHover = () => {
        setHover(!hover);
    };

    const classNamesValue = classNames({
        [classes[variant]]: true,
        [classes.disabled]: disabled
    });

    const textStyle = { color: disabled ? "grey" : "inherit", ...spanStyle };

    return to ?
        (to.includes("http") ?
            <a href={to} rel="noopener noreferrer" target={"_blank"}
                className={classNamesValue}
                style={style} onMouseEnter={switchHover} onMouseLeave={switchHover}>
                <span style={textStyle}>{children}</span>
                <ArrowUpRight style={{ height: "18px", width: "18px" }} />
            </a>
            :
            <Link to={to}
                className={classNamesValue}
                style={style} onMouseEnter={switchHover} onMouseLeave={switchHover}>
                <span style={textStyle}>{children}</span>
            </Link>

        )
        :
        <span className={classNamesValue}
            style={style}
            onMouseEnter={switchHover} onMouseLeave={switchHover}
            onClick={onClick}
            aria-controls={ariaControls}
            aria-haspopup={ariaHasPopup}
        >
            <span style={textStyle}>{children}</span>
        </span>
}

InlineLink.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(["primary", "secondary"]),
    onClick: PropTypes.func,
    to: PropTypes.string,
    style: PropTypes.object,
    ariaControls: PropTypes.string,
    ariaHasPopup: PropTypes.string
};

export default InlineLink;