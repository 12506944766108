import React ,{useRef,useState} from 'react';
import FormInput from '../views/task/edit task/components/input-fields/form-input';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EmptyImage from '../assets/images/Empty-Image.png';

 const ImageInput = (props) => {
    const inputRef = useRef();
    const [image,setImage] = useState(props.url &&props.url!='' ? props.url:EmptyImage);
    const handleClick=()=>{
        inputRef.current.click();
    }
    const handleChange = (event)=>{
        if(inputRef.current.files[0]){
            const imageUrl = URL.createObjectURL(inputRef.current.files[0])
            setImage(imageUrl);
            if(props.onChange){
                props.onChange(inputRef.current.files[0]);
            }
        }else if(props.url){
            setImage(props.url);

        }else{
            setImage(EmptyImage);
        }
    }

  return (
    <Card sx={{ maxWidth: 345 }}>
        <CardHeader
        title={props.label ?? "image label"}
      />
      <CardMedia
        onClick={handleClick}
        component="img"
        height="194"
        image={image}
        alt={props.alt ?? "image"}
      />
      <CardContent>
      <FormInput
        type="file"
        onChange={handleChange}
       name={props.name ?? "imageInput"}
       style={{display:"none",}}
       inputRef={inputRef}
     />
      </CardContent>
    </Card>
  );
}
export default ImageInput;
