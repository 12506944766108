import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  statusIndicator: {
    borderRadius: '24px',
    padding: '1.5px 12px',
    paddingBottom: '3.5px',
    TypographyAlign: 'center'
  }
}));

const StatusIndicator = ({ task }) => {
  const classes = useStyles();

  const isExpired = () => {
    const date = task.dateToStart
      ? typeof task.dateToStart === 'string'
        ? new Date(task.dateToStart)
        : task.dateToStart instanceof Date
          ? task.dateToStart
          : new Date(task.dateToStart.toDate().toString())
      : null;

    return (
      !task.isCancelled &&
      !task.isAssigned &&
      !task.isComplete &&
      !task.isDispute &&
      date < new Date()
    );
  };

  return (
    task && (
      <div>
        {!task.isAssigned &&
          !task.isComplete &&
          !task.isCancelled &&
          !task.isDispute &&
          !isExpired() ? (
          <Typography
            style={{
              border: '2px solid rgb(50, 199, 161)',
              wordBreak: 'unset',
              color: 'rgb(50, 199, 161)'
            }}
            className={classes.statusIndicator}
            variant="caption"
            bold
          >
            Open
          </Typography>
        ) : null}
        {task.isAssigned &&
          !task.isComplete &&
          !task.isCancelled &&
          !task.isDispute ? (
          <Typography
            style={{
              border: '2px solid #bdbdbd',
              wordBreak: 'unset',
              color: 'rgb(160,160,160)'
            }}
            className={classes.statusIndicator}
            variant="caption"
            bold
          >
            Assigned
          </Typography>
        ) : null}
        {task.isComplete && !task.isCancelled ? (
          <Typography
            style={{
              border: '2px solid #bdbdbd',
              wordBreak: 'unset',
              color: 'rgb(160,160,160)'
            }}
            className={classes.statusIndicator}
            variant="caption"
            bold
          >
            Completed
          </Typography>
        ) : null}
        {
          !task.isComplete &&
            task.isCancelled ? (
            <Typography
              style={{
                border: '2px solid rgba(235, 80, 60, 0.5)',
                wordBreak: 'unset',
                color: 'rgba(235, 80, 60, 0.9)'
              }}
              className={classes.statusIndicator}
              variant="caption"
              bold
            >
              Cancelled
            </Typography>
          ) : null}
        {
          !task.isCancelled &&
            !task.isComplete &&
            task.isDispute ? (
            <Typography
              style={{
                border: '2px solid #bdbdbd',
                wordBreak: 'unset',
                color: 'rgb(160,160,160)'
              }}
              className={classes.statusIndicator}
              variant="caption"
              bold
            >
              Disputed
            </Typography>
          ) : null}
        {isExpired() ? (
          <Typography
            style={{
              border: '2px solid #bdbdbd',
              wordBreak: 'unset',
              color: 'rgb(160,160,160)'
            }}
            className={classes.statusIndicator}
            variant="caption"
            bold
          >
            Expired
          </Typography>
        ) : null}
      </div>
    )
  );
};

export default StatusIndicator;
