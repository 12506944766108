import React from 'react';
import Typography from "@material-ui/core/es/Typography";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";

const styles = makeStyles((theme) => ({
    base: {
        zIndex: 1250,
        color: 'rgb(10,10,10)',
        whiteSpace: "break-spaces",
        wordBreak: "break-word",
    },
    h1: {
        fontFamily: "'HKGroteskBlack', sans-serif !important",
        lineHeight: "normal !important",
        fontSize: "45px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "40px !important"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "36px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "24px !important"
        },
    },
    h2: {
        fontFamily: "'HKGroteskBlack', sans-serif !important",
        fontSize: "40px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "30px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "26px !important"
        },
    },
    h3: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "36px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "22px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "22px !important"
        },
    },
    h4: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "32px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "26px !important"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important"
        },
    },
    h5: {
        fontFamily: "'HKGroteskBlack', sans-serif !important",
        fontSize: "26px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "24px !important"
        },
    },
    h6: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "22px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "18px !important"
        },
    },
    body1: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "20px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "16px !important"
        },
    },
    body2: {
        letterSpacing: "-0.2px !important",
        fontFamily: "'HKGroteskBold', sans-serif !important",
        fontSize: "16px !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px !important"
        }
    },
    subtitle2: {
        fontFamily: "'HKGroteskBold', sans-serif !important",
    },
    subtitle1: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "11px !important",
        letterSpacing: "0 !important",
        lineHeight: "1.2 !important",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10px !important"
        },
    },
    overline: {
        fontFamily: "'HKGroteskExtraBold', sans-serif !important",
        fontSize: "10px !important",
        letterSpacing: "1.4px !important",
        [theme.breakpoints.down('lg')]: {
            fontSize: "9px !important"
        }
    },
    caption: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
        fontSize: "13px !important",
        letterSpacing: "0 !important",
        lineHeight: "1.2 !important",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px !important"
        },
    },
    xl: {
        fontSize: "40px !important",
        textTransform: "uppercase"
    },
    navigation: {
        alignItems: "center",
        height: "100%",
        display: "flex",
        // padding: "0 24px"
    },
    regular: {
        fontFamily: "'HKGroteskMedium', sans-serif !important",
    },
    medium: {
        fontFamily: "'HKGroteskBold', sans-serif !important",
    },
    bold: {
        fontFamily: "'HKGroteskExtraBold', sans-serif !important",
    },
    black: {
        fontFamily: "'HKGroteskBlack', sans-serif !important",
    },
}));



function Text (props) {

    const classes = styles();
    const { variant, noWrap, children, style, medium, bold, black, selectable=true, onClick, onMouseEnter, onMouseLeave, component, id, regular, className } = props;

    return (
        <Typography component={component}
                    id={id}
                    onClick={onClick}
                    variant={variant}
                    noWrap={noWrap}
                    className={
                        classNames(
                            { [classes.base] : true},
                            { [classes[variant]]: true },
                            { [classes.bold]: bold },
                            { [classes.medium]: medium },
                            { [classes.black]: black },
                            { [classes.regular]: regular },
                            { [className]: className!==null }
                        )
                    }
                    style={{ userSelect: selectable ? "unset" : "none", ...style }}
                    onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}
                    classes={{
                        root: classes[variant],
                        overline: classes.overline,
                        h1: classes.h1,
                        h2: classes.h2,
                        h3: classes.h3,
                        h4: classes.h4,
                        h5: classes.h5,
                        body1: classes.body1,
                        body2: classes.body2,
                        caption: classes.caption,
                    }}

        >
            {children}
        </Typography>
    );
}

export default Text ;