import React, { useEffect, useState } from 'react';
import { Button, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NoOffers from '../../../../assets/images/review_offer_home.png';
// import CircularProgressBar from "../../../components/CircularProgress/circular-progress-bar";
import OfferCard from '../../../../components/offerCard';
import firebaseUtility from '../../../../utils/firebase.utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const Fade = require('react-reveal/Fade');

const useStyles = makeStyles((theme) => ({
  offerCard: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    borderRadius: '6px',
    padding: '12px 16px',
    border: '1.5px solid rgb(240,240,240)',
    boxShadow: '0 0 30px -16px rgba(50,50,50,0.05)',
    marginBottom: '12px',
    transition: '0.3s',
    '&:hover': {
      background: theme.palette.background.default
    }
  },
  offerButton: {
    marginLeft: '12px',
    transition: '0.3s',
    borderRadius: '50px',
    cursor: 'pointer',
    padding: '6px 14px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'rgba(78, 62, 253,0.13)'
    }
  }
}));

const OffersTab = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [open, setOpen] = useState(false);
  const [taskId, setId] = useState(null);
  const [offerUid,setUid]=useState(null);

  useEffect(() => {
    setOffers(props.offers);
    setLoading(false);
  }, []);

  const promptCancel = (task_id,offer) => {
    setId(task_id);
    setUid(offer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const deleteOffer = async () => {
    try {
      await firebaseUtility.Firestore()
         .collection('tasks')
         .doc(taskId)
         .collection('offers')
        .doc(offerUid)
        .delete();

      const allOffers = offers.filter((offer) => {
        return offer.taskId !== taskId;
      });

      setOffers([...allOffers]);
      props.passItems(allOffers,'offers')

    } catch (e) {
      console.log(e);
    }
    setOpen(false);

  };



  return (
    <div style={{ marginTop: '24px' }}>
      {!loading
                && (
                <Fade bottom distance="20px" duration={400}>
                  <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    {
                            offers.map((offer) => {
                              return <OfferCard offer={offer} promptCancel={promptCancel} key={offer.id} insideTask={true}/>;
                            })
                        }
                  </div>
                </Fade>
                )}
      {loading
                && (
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '24px 0'
                }}
                >
                  {/* <CircularProgressBar /> */}
                </div>
                )}
      {(!loading && offers.length === 0)
                && (
                <Fade bottom distance="20px" duration={400}>
                  <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '24px 0', border: '1.5px solid rgb(240,240,240)', borderRadius: '6px'
                  }}
                  >
                    <img src={NoOffers} alt="No Questions for this task" width={100} />
                    <div style={{ height: '16px' }} />
                    <Typography style={{ color: 'black' }} medium variant="caption">
                      No Offers yet
                    </Typography>
                  </div>
                </Fade>
                )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Offer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this offer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteOffer} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default OffersTab;
