import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';
import { toast, ToastContainer } from 'react-toastify';
import SupportView from './SupportView';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const SupportPage = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-use-before-define
      await getUsers();
      toast.warning('Coming soon...');
    })();
  }, []);

  const getUsers = async (prev = false, size = pageSize) => {
    setLoading(true);
    let usersDocs;
    if (prev) {
      usersDocs = await firebaseUtility.GetPrevPageAllDocuments(
        'users',
        size,
        'email'
      );
    } else {
      usersDocs = await firebaseUtility.GetNextPageAllDocuments(
        'users',
        size,
        'email'
      );
    }
    setUsers(usersDocs);
    setFirstItem(usersDocs[0]);
    setLastItem(usersDocs[usersDocs.length - 1]);
    setLoading(false);
  };

  const searchUsersMethod = async email => {
    if (email === '') {
      await getUsers();
      return;
    }
    setLoading(true);
    const userDoc = await firebaseUtility.findDocumentBy(
      'users',
      'email',
      '==',
      email
    );
    setUsers(userDoc ? [userDoc] : []);
    setLoading(false);
  };

  const handlePageSizeChange = async size => {
    setLoading(true);
    if (pageSize != size) {
      setPageSize(size);
      await getUsers(false, size);
    }
    setLoading(false);
  };

  const handlePageChange = async pageIndex => {
    setLoading(true);
    if (pageIndex < page) {
      await getUsers(true);
    } else {
      await getUsers(false);
    }
    setPage(pageIndex);
    setLoading(false);
  };

  return (
    <Page className={classes.root} title="Support">
      <ToastContainer closeOnClick={true} hideProgressBar={false} />
      <Container maxWidth={false}>
        <SupportView
          pageIndex={page}
          users={users}
          isLoading={loading}
          pageSizeIndex={pageSize}
          setPageSize={size => {
            handlePageSizeChange(size);
          }}
          pageChanged={pageIndex => {
            handlePageChange(pageIndex);
          }}
        />
      </Container>
    </Page>
  );
};

export default SupportPage;
