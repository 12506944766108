import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  CircularProgress,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';
import SkeletoLoader from '../../components/skeletoLoader';
import EditUserDialog from './edit.user';
import { useNavigate, useParams } from 'react-router-dom';
import firebaseUtility from '../../utils/firebase.utils';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '15px',
  },
  statusIndicator: {
    borderRadius: '24px',
    padding: '1.5px 12px',
    paddingBottom: '3.5px',
    TypographyAlign: 'center',
    marginLeft: '30px'
  }
}));

// eslint-disable-next-line max-len
const Results = ({
  className,
  users,
  isLoading,
  pageIndex,
  pageSizeIndex,
  setPageSize,
  pageChanged,
  fetchUsers,
  count,

  ...rest
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [page, setPage] = useState(pageIndex);
  const [loading] = useState(isLoading);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isloading,setIsLoading]=useState(users.length>0?false:true);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    pageChanged(newPage);
  };


  const getLoadingSkeleton = () => <SkeletoLoader page="verifications" />;

  const openEditUserDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(!openDialog);
    navigate(`/app/verifications/${user.uid}`);
  };
  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpenDialog(false);
      } else {
        const userInfo = await firebaseUtility.GetOneDocById(
          'users',
          params.id
        );
        setSelectedUser(userInfo);
        setOpenDialog(true);
      }
    })();
  }, [params]);
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>Verification Request Date</TableCell>
                  <TableCell> Verification Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {users && users.length > 0 ?
                <TableBody>
                  {!loading
                    ? users.slice(0, limit).map((user) => (
                     user.uid && <TableRow hover key={user.uid}>
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Avatar
                              className={classes.avatar}
                              src={user.profilePicture}
                            >
                              {getInitials(user.firstName)}
                            </Avatar>
                            <Typography color="textPrimary" variant="body1">
                              {user.firstName}
                              {' '}
                              {user.lastName}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {
                           moment(
                            typeof  user?.verificationRequestDate=== 'string'
                              ? new Date( user.verificationRequestDate)
                              : new Date( user?.verificationRequestDate?.toDate().toString())
                          ).format('dddd, MMM DD')}
                        </TableCell>
                        <TableCell>

                          <div>
                            {user.verifiedStatus === "pending" && (
                              <Typography
                                style={{
                                  border: '2px solid rgb(255, 128, 0)',
                                  wordBreak: 'unset',
                                  color: 'rgb(255, 128, 0)',
                                }}
                                className={classes.statusIndicator}
                                variant="caption"
                                bold
                              >
                                Pending
                              </Typography>
                            ) 
                            }
                             {user.verifiedStatus === "rejected" && (
                              <Typography
                                style={{
                                  border: '2px solid rgba(235, 80, 60, 0.5)',
                                  wordBreak: 'unset',
                                  color: 'rgba(235, 80, 60, 0.9)',
                                }}
                                className={classes.statusIndicator}
                                variant="caption"
                                bold
                              >
                                Rejected
                              </Typography>
                            ) } 
                             {user.verifiedStatus === "verified" && (
                              <Typography
                                style={{
                                  border: '2px solid rgb(50, 199, 161)',
                                  wordBreak: 'unset',
                                  color: 'rgb(50, 199, 161)',
                                }}
                                className={classes.statusIndicator}
                                variant="caption"
                                bold
                              >
                                Verified
                              </Typography>
                            ) } 
                          </div>

                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            className={classes.rounded}
                            onClick={() => openEditUserDialog(user)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                    : getLoadingSkeleton()}
                </TableBody>
                :
                <TableBody >
                  <TableCell colSpan={3}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                        {isloading?<CircularProgress color='#2c387e' size={30}/>:'No Results Found!'}
                    </div>
                  </TableCell>
                </TableBody>
              }
            </Table>
          </Box>
        </PerfectScrollbar>
         <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={count}
          onChangePage={handlePageChange}
          page={pageIndex}
          rowsPerPage={6}
        />
      </Card>
      {openDialog ? (
        <EditUserDialog
          fetchUsers={fetchUsers}
          opened={openDialog}
          user={selectedUser || {}}
          badgesItems={selectedUser.badges}
          closeDialog={(user) => {
            setOpenDialog(false);
            if (user) {
              setSelectedUser(user);
              const indexToUpdate = users.find((i) => i.uid == user.uid);
              users[indexToUpdate] = JSON.parse(JSON.stringify(user));
            }
            navigate(`/app/verifications`);
          }}
        />
      ) : null}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func,
  pageChanged: PropTypes.func,
  pageSizeIndex: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default Results;
