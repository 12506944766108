import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DialogContentText from "@material-ui/core/DialogContentText";


import {
  DialogTitle,
  TextField,
  Typography,
  Box,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import firebaseUtility from '../../utils/firebase.utils';
import ProfileTab from '../../components/tabsComponents/profileTab';


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dialogRoot: { height: '90vh' },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '3em',
  },
  rootTabContainer: {
    transform: 'translateY(4px)',
    background: 'white',
    padding: '0 24px',
    borderRadius: '6px',
  },
  rootTab: {
    textTransform: 'none',
    padding: '0',
    minWidth: 'unset',
    justifyContent: 'flex-start !important',
    fontSize: theme.typography.pxToRem(15),
    marginRight: '20px',
  },
  indicatorTab: {
    display: 'flex',
    height: '4px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },

  },
}));

// eslint-disable-next-line react/prop-types,no-unused-vars
const EditUser = ({
  opened, closeDialog, user, badgesItems,fetchUsers, ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [verificationDialog, setVerificationDialog] = useState(false);
  const [filter, setFilter] = useState('');
  const [privateData, setPrivateData] = useState(null);
  const [rejectDialog, setRejectDialog] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState("");
  




  //reject user 
  const openRejectDialog = () => {
    setRejectDialog(true);
  };

  const closeRejectDialog = () => {
    setRejectDialog(false);
  };

  const handleRejectUser = async (uid) => {
    if(rejectReason==""){
      toast.error('Please add a reason to reject.');
    }else{
    const result = await firebaseUtility.setDocument('users', uid, {
      verifiedStatus: 'rejected',
      rejectReason: rejectReason
    }, true);
      await fetchUsers('pending')
     setRejectDialog(false);
     toast.success('User Rejected')
  }
  }

  const handleVerifyUser = async (uid) => {
    const result = await firebaseUtility.setDocument('users', uid, {
      verifiedStatus: 'verified'
    }, true);
    toast.success('User Verified');
    await fetchUsers('rejected');
    await fetchUsers('pending');

  }

  const filterUsers = (ev) => {
    const selectedFilter = ev.target.value;
    localStorage.setItem('Filter', selectedFilter);
    setFilter(selectedFilter);
  };



  const handleClose = () => {
    if (closeDialog) {
      closeDialog(user);
    }
  };

  useEffect(() => {
    getPrivateUser();
  }, []);

  const getPrivateUser = async () => {
    const userData = await firebaseUtility.GetOneDocById('users_stripe', user.uid);
    setPrivateData({ ...userData });
  };



  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  };
  const renderTabs = () => {
    const tabs = [
      'Profile'

    ];
    return (
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        classes={{
          root: classes.rootTabContainer,
          indicator: classes.indicatorTab,
        }}
        TabIndicatorProps={{ children: <div /> }}
        value={tab}
        aria-label="tabs"
      >
        {tabs.map((tabName, index) => {
          return (
            <Tab
              {...a11yProps(index)}
              disableRipple
              classes={{ root: classes.rootTab }}
              label={(
                <Typography variant="subtitle2">
                  {tabName}
                </Typography>
              )}
            />
          );
        })}
      </Tabs>
    );
  };

  return (
    <div>
      <Dialog
        open={opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        disableBackdropClick
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              width: '100%',
            }}
          >
            <Grid item md={12}>
              {user.profilePicture && user.profilePicture != '' ? (
                <Avatar
                  className={classes.large}
                  alt={user.firstName}
                  src={user.profilePicture}
                />
              ) : (
                <Avatar className={classes.large}>
                  {user.firstName && user.firstName.substr(0, 1)}
                  {user.lastName && user.lastName.substr(0, 1)}
                </Avatar>
              )}
            </Grid>
            <br />
            <Grid item md={12}>
              <Typography variant="h1" component="h1">
                {user.firstName}
                {' '}
                {user.lastName}
              </Typography>
            </Grid>
            <br />
            <Grid item md={12}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Box style={{ width: "200px" }}>
                   <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-label">
                      Verification Status
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="status-label"
                      id="status-label"
                      label=" Verification Status"
                      value={filter}
                      onChange={(ev) => {
                        filterUsers(ev);
                      }}
                    >
                      {user.verifiedStatus=="rejected" && 
                      <MenuItem value="verified" onClick={() => {handleVerifyUser(user.uid)}} >Verify User</MenuItem>
                    }
                       {user.verifiedStatus=="pending" && 
                       <>
                      <MenuItem value="verified" onClick={() => {handleVerifyUser(user.uid)}} >Verify User</MenuItem>
                      <MenuItem value="rejected" onClick={openRejectDialog}>Reject User</MenuItem>
                      </>
                       }
                        {user.verifiedStatus=="verified" && 
                       <>
                      <MenuItem value="rejected" onClick={openRejectDialog}>Reject User</MenuItem>
                      </>
                       }
                    </Select>
                  </FormControl> 
                </Box>
                <Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            {renderTabs()}

            {user && privateData && tab === 0 && (
              <ProfileTab user={user} privateData={privateData} />
            )}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={handleClose}
            color="primary"

          >
            {!loading ? 'Close' : <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
      {/* reject user dialog */}
      <Dialog
        open={rejectDialog}
        onClose={closeRejectDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reject User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Why You Want To Reject This User?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={6}
            rowsMax={10}
            fullWidth
            value={rejectReason}
            onChange={(ev) => {
              setRejectReason(ev.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRejectDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleRejectUser(user.uid)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};
EditUser.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired


};

export default EditUser;
