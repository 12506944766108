import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import Toolbar from './toolbar';
import Results from './results';
import configs from '../../configs';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-use-before-define
      await getUsers();
    })();
  }, []);

  const getUsers = async (prev = false, size = pageSize) => {
    setLoading(true);
    let usersDocs;
    if (prev) {
      usersDocs = await firebaseUtility.GetPrevPageAllDocuments(
        'users',
        size,
        'email'
      );
    } else {
      usersDocs = await firebaseUtility.GetNextPageAllDocuments(
        'users',
        size,
        'email'
      );
    }
    if (usersDocs && usersDocs.length > 0) {
      setUsers(usersDocs);
      setFirstItem(usersDocs[0]);
      setLastItem(usersDocs[usersDocs.length - 1]);
    } else {
      setUsers([]);
      setFirstItem(null);
      setLastItem(null);
    }
    setLoading(false);
  };

  const searchUsersMethod = async (text, searchType) => {
    
    if (text === '') {
      await getUsers();
      return;
    }
    setLoading(true);
    const userDoc = await firebaseUtility.findDocumentBy(
      searchType === 'accountId' ? 'users_stripe' : 'users',
      searchType,
      '==',
      text.replace(/\s/g, '')
    );
    if (userDoc) {
      if (searchType === 'accountId') {
        const doc = await firebaseUtility.GetOneDocById('users', userDoc[0].id);
        setUsers(doc ? [doc] : []);

      } else {
        setUsers(userDoc ? [...userDoc] : []);

      }
    } else {
      
      const searchText = text.charAt(0).toUpperCase() + text.slice(1);
      const user = await firebaseUtility.findDocumentBy(
        searchType === 'accountId' ? 'users_stripe' : 'users',
        searchType,
        '==',
        searchText.replace(/\s/g, '')
      );
      if (searchType === 'accountId' && user != null) {
        const doc = await firebaseUtility.GetOneDocById('users', user[0].id);
        setUsers(doc ? [doc] : []);
      }
      else if (searchType === 'accountId' && user == null) {
        setUsers([]);
        setLoading(false);
      }
      else {
        setUsers(user ? [...user] : []);
      }

    }

    setLoading(false);
  };

  const handlePageSizeChange = async (size) => {
    setLoading(true);
    if (pageSize != size) {
      setPageSize(size);
      await getUsers(false, size);
    }
    setLoading(false);
  };

  const handlePageChange = async (pageIndex) => {
    setLoading(true);
    if (pageIndex < page) {
      await getUsers(true);
    } else {
      await getUsers(false);
    }
    setPage(pageIndex);
    setLoading(false);
  };

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={false}>
        <Toolbar
          searchUsersMethod={searchUsersMethod}
          isLoading={loading}
        />
        <Box mt={3}>
          <Results
            pageIndex={page}
            users={users}
            isLoading={loading}
            pageSizeIndex={pageSize}
            setPageSize={(size) => {
              handlePageSizeChange(size);
            }}
            pageChanged={(pageIndex) => {
              handlePageChange(pageIndex);
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Users;
