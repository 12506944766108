import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ta from 'react-timeago/lib/language-strings/ta';
import firebaseUtility from '../../utils/firebase.utils';
import Messaging from '../private-messaging/messaging-index';


const Fade = require('react-reveal/Fade');

const useStyles = makeStyles(theme => ({
  container: {
    background: 'white',
    borderRadius: '8px',
    margin: '10px',
    border: '1.5px solid rgb(245,245,245)',
    boxSizing: 'border-box',
    boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      border: 'none',
      boxShadow: 'none',
      padding: 0
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 28px',
    border: '1.5px solid rgb(245,245,245)',
    background: 'white',
    borderRadius: '8px',
    boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
    margin: '10px',
    width: '80%'
  }
}));

const DisputeContainer = ({ openDialog }) => {
  const classes = useStyles();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tasker, setTasker] = useState('');
  const [poster, setPoster] = useState('');

  const params = useParams();
  const theme = useTheme();

  useEffect(() => {
    setTask(null);
    setLoading(true);
    const observer = firebaseUtility
      .Firestore()
      .collection('tasks')
      .doc(params.id ?? 'empty')
      .onSnapshot(document => {
        if (document.exists) {
          const data = document.data();
          data.id = document.id;
          setTask(data);
        }
        setLoading(false);
      });

    return () => {
      observer();
    };
  }, [params.id]);
  useEffect(() => {
    if (task?.disputeData?.userType === 'poster') {
      firebaseUtility
        .Firestore()
        .collection('users')
        .doc(task?.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setPoster(doc.data());
          }
        });
    } else {
      firebaseUtility
        .Firestore()
        .collection('users')
        .doc(task?.assignedWorker?.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setPoster(doc.data());
          }
        });
    }
  }, [task]);

  return (
    <div>
      {task && <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes.header}>
          <div>
            <Typography variant="caption">Reason</Typography>
            <Typography variant="body2">
              {typeof task?.disputeData?.reason === 'object'
                ? task?.disputeData?.reason.reasons
                : task?.disputeData?.reason}
            </Typography>
          </div>
         <div>
            <Typography variant="caption">Price</Typography>
            <Typography variant="body2">
              { task?.budget  }
            </Typography>
            
        </div>
        <div>
            <Typography variant="caption">Service Fees</Typography>
            <Typography variant="body2">
              { task?.assignedWorker.serviceFees  }
            </Typography>
            
        </div>
          <div>
            <Typography variant="caption">Dispute Date</Typography>
            <Typography variant="body2">
              {moment(
                typeof task?.disputeData?.disputeDate === 'string'
                  ? new Date(task?.disputeData?.disputeDate)
                  : new Date(task?.disputeData?.disputeDate.toDate().toString())
              ).format('dddd, MMM DD')}
            </Typography>
          </div>
 
          <div>
            <Typography variant="caption">Disputed by</Typography>
            <Typography variant="body2">
              {task?.disputeData?.userType === 'poster'
                ? `${poster.firstName} ${poster.lastName} (poster)`
                : `${tasker.firstName} ${tasker.lastName} (tasker)`}
            </Typography>
          </div>
        </div>
        <Button
          style={{ height: '40px', fontSize: '11px' }}
          variant="contained"
          color="primary"
          disabled={!task}
          onClick={() => openDialog(task)}
        >
          Submit resolution
        </Button>
      </div>}

      {task && <div className={classes.container}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Messaging allowSend />
          </Grid>
        </Grid>
      </div>}
    </div>
  );
};

export default DisputeContainer;
