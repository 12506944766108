import React, { useEffect, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import ReactTimeago from 'react-timeago';
import ReplyListItem from './replyListItem';
import firebaseUtility from '../../utils/firebase.utils';
import Avatar from '../avatar';
import ReadMore from '../readMore';
import AttachmentHandler from '../attachment-handler';
const Fade = require('react-reveal/Fade');

const ViewReplies = ({ comment }) => {
  const sentinel = useRef(null);
  const [comments, setComments] = useState([]);
  const [last, setLast] = useState(null);
  const [flag, setFlag] = useState(false);
  const [question, setQuestion] = useState(null);

  const commentRef = useRef([]);
  commentRef.current = comments;

  const lastRef = useRef();
  lastRef.current = last;

  const flagRef = useRef(false);
  flagRef.current = flag;

  useEffect(() => {
    setComments([]);
    const commentStream = firebaseUtility.Firestore().collection('tasks')
      .doc(comment.taskId)
      .collection('comments')
      .doc(comment.id)
      .collection('replies')
      .orderBy('createAt', 'desc')
      .limit(20)
      .onSnapshot((snapshot) => {
        updateMessages(snapshot);
      });

    firebaseUtility.Firestore().collection('tasks')
      .doc(comment.taskId)
      .collection('comments')
      .doc(comment.id)
      .get()
      .then((doc) => {
        setQuestion({ ...doc.data() });
      });

    return () => {
      setComments([]);
      commentStream();
    };
  }, []);

  const updateMessages = (snapshot) => {
    if (snapshot.docChanges().length == 0) {
      setComments([]);
    } else {
      let lastDocumentSnapshot = null;
      const commentsList = [...commentRef.current];
      snapshot.docChanges().forEach((d) => {
        switch (d.type) {
          case 'added':
            const data = d.doc.data();
            data.id = d.doc.id;
            data.createAt = data?.createAt ? (typeof data?.createAt === 'string' ? new Date(
              data.createAt
            ) : (data.createAt instanceof Date ? data.createAt : new Date(
              data.createAt.toDate().toString()
            ))) : null;
            if (commentsList.length > 0 && commentsList[commentsList.length - 1].createAt
              < (data.createAt)) {
              commentsList.push(data);
            } else {
              commentsList.splice(0, 0, data);
            }
            if (!lastRef.current) { lastDocumentSnapshot = d.doc; }
            break;
          case 'removed':
          case 'modified':
        }
      });
      setComments(commentsList);
      if (!lastRef.current && !flagRef.current) {
        setLast(lastDocumentSnapshot);
        setFlag(true);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) { observer.observe(sentinel.current); }

    return function cleanup() {
      observer.disconnect();
    };
  });

  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (lastRef.current) {
          firebaseUtility.Firestore().collection('tasks')
            .doc(comment.taskId)
            .collection('comments')
            .doc(comment.id)
            .collection('replies')
            .orderBy('createAt', 'desc')
            .startAfter(lastRef.current)
            .limit(20)
            .onSnapshot((snapshot) => {
              let lastDocumentSnapshot = null;
              const commentsList = [...commentRef.current];

              if (snapshot.docs.length != 0) {
                snapshot.docs.forEach((d) => {
                  const data = d.data();
                  data.id = d.id;
                  data.createAt = data?.createAt ? (typeof data?.createAt === 'string' ? new Date(
                    data.createAt
                  ) : (data.createAt instanceof Date ? data.createAt : new Date(
                    data.createAt.toDate().toString()
                  ))) : null;
                  commentsList.splice(0, 0, data);
                  lastDocumentSnapshot = d;
                });
              }
              setComments(commentsList);
              setLast(lastDocumentSnapshot);
            });
        }
      }
    });
  };

  return (
    <div style={{
      display: 'flex', flexDirection: 'column-reverse', height: '100%', overflowY: 'auto'
    }}
    >
      {
        comments.length > 0
        && (
          <div>
            <div ref={sentinel} />

            {question && (
            <div style={{

              display: 'flex',
              flexDirection: 'column',
              background: 'white',
              position: 'relative',
              boxShadow: '0 0 30px -16px rgba(50,50,50,0.05)',
              transition: '0.3s',
              padding: '10px 40px',
            }}
            >
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{
                  position: 'absolute', width: '1px', background: 'rgb(200,200,200)', height: 'calc(\'100%\' + \'24px\'})', top: '', left: '58px', zIndex: 2
                }}
                />
                <Avatar name={question.name} profilePicture={question.profilePicture} size={40} />
                <div style={{
                  display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginLeft: '12px'
                }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">{question.name}</Typography>
                    <div style={{
                      margin: '0 8px', width: '4px', height: '4px', borderRadius: '50%', background: 'grey'
                    }}
                    />
                    <Typography style={{ color: '#646464' }} variant="caption">
                      <ReactTimeago
                        date={question?.createAt}
                        live={false}
                        formatter={(value, unit) => {
                          return `${value.toString()} ${unit}${value > 1 ? 's' : ''}`;
                        }}
                      />
                    </Typography>
                  </div>
                  <Typography variant="body2" style={{ color: 'black', marginTop: '4px' }}>
                  {question?.type === "text" &&<ReadMore maxLength={80}>{question.text}</ReadMore>}
                      {question?.type === "image" && question?.images?.length > 0 && <div style={{
                            width: '200px',
                            height: "120px",
                            marginTop: "10px"

                        }}>
                            <AttachmentHandler images={[...question.images]} />
                        </div>}
                  </Typography>
                </div>
              </div>
            </div>
            )}
            {
              comments.map((reply, index) => {
                return (
                  <Fade bottom distance="12px" key={index} duration={300}>
                    <ReplyListItem
                      reply={reply}
                      previousUid={index >= (comments.length - 1) ? null : comments[index - 1]?.uid}
                      currentUid={index >= (comments.length - 1) ? null : comments[index]?.uid}
                      repliesLength={comments.length}
                      index={index}
                    />
                  </Fade>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};

export default ViewReplies;
