import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';
import RichTextEditor from './rich-text-editor'
import { BaseButton } from '../task/edit task/components/input-fields/primary-button';
import firebaseUtility from '../../utils/firebase.utils';
import DialogWrapper from '../task/edit task/components/dialogs/dialog-wrapper';
import FormInput from '../task/edit task/components/input-fields/form-input';
import useTheme from "@material-ui/core/styles/useTheme";
import FileUploader from './file';
import TagsInput from "./TagsInput";
import { useNavigate } from 'react-router-dom';
import { getDownloadURL } from "firebase/storage";
import ImageInput from '../../components/image-input';
import firebase from 'firebase';
import FormWrapper from '../task/edit task/components/input-fields/form-wrapper';
import Text from '../task/edit task/text';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '50%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    tags: {
        textAlign: 'center'
    }
}));
const Blogs = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
    const [formValues, setFormValues] = useState(undefined);
    const [tags, setTags] = useState([]);
    const [error, setError] = useState(false);
    const theme = useTheme();
    const [imagePath,setImagePath ]= useState('');
    const [avatarPath,setAvatarPath ]= useState('');
    const storage = firebase.storage();
    const navigation = useNavigate();

    const addBlog = async () => {
        if(formValues && formValues.title != undefined ){
        setLoading(true);
        const file = '' + formValues.file;
        const title = formValues.title ?? '';
        const image=''+formValues.image;
        const fileNameStart = 0;
        const fileNameEnd = imagePath?.name?.lastIndexOf('.');
        const fileName = file != '' ? imagePath?.name?.substring(fileNameStart, fileNameEnd):'';
        let fileUrl = '';
        let blogFile='';
        const imageStart = image.lastIndexOf('\\') + 1;
        const imageNameEnd = avatarPath?.name?.lastIndexOf('.');
        const imageName = avatarPath?.name?.substring(imageStart, imageNameEnd);
  
        if (avatarPath&& (imageName != '')) {
          await storage.ref(`/Blogs-Authors/${imageName}`).put(avatarPath);
          fileUrl =  await storage.ref("Blogs-Authors").child(imageName).getDownloadURL();
        }
        if (imagePath && (fileName != '')) {
          await storage.ref(`/Blogs_images/${fileName}`).put(imagePath);
          blogFile = await storage.ref("Blogs_images").child(fileName).getDownloadURL();
        }
        await firebaseUtility.Firestore().collection('blogs').add({
            title: title,
            subTitle: formValues.sub ?? '',
            author: formValues.auth ?? '',
            tags: tags,
            avatar: fileUrl,
            image:blogFile,
            updateAt: new Date(),
            content: value,
            slug: title.replace(/\s/g, '-'),
        })
        setLoading(false);
        toast.success("New Blog is added");
        navigation('/app/blogs-list', { replace: true })
    }else{
        setError(true);
        toast.error("You Still Have Empty Fields.");
    }
    }


    const handleSelecetedTags = (items) => {
        setTags(items);
    }
    return (
        <Page title="Blogs Editor">
            <FormWrapper  onValueChange={(values)=>{setFormValues(values)}}>
                <div>
                    <Box style={{ width: "650px" }}>
                    <FormInput
                        required
                            label={'Title'}
                            placeholder={"Title"}
                            name={'title'}
                            type={'text'}
                            
                        //onChange={(ev) => setTitle(ev.target.value)}
                        />
                    </Box>
                    {/* subtitle */}
                    <Box style={{ width: "650px" }}>
                        <FormInput
                         required
                            label={'Subtitle'}
                            placeholder={"Subtitle"}
                            name={'sub'}
                            type={'text'}
                        />
                    </Box>
                    {/* author */}
                    <Box style={{ width: "650px" }}>
                        <FormInput
                         required
                            label={'Author'}
                            placeholder={"Author"}
                            name={'auth'}
                            type={'text'}
                        />
                    </Box>
                         
                   <Box style={{ width: "650px" }}>
          <ImageInput onChange={setAvatarPath} name='file' label={'Author Image'} />
          </Box>
          <div style={{ height: "30px" }}></div>
          <Box style={{ width: "650px" }}>
          <ImageInput onChange={setImagePath} name='image' label={'Blog Image'}  />
          </Box>
                    <div style={{ height: '30px' }} />
                    {/* tags input */}
                    <div style={{ width: "650px", maxWidth: '100%' }}>
                        <TagsInput
                            selectedTags={(item) => handleSelecetedTags(item)}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="Add Tags"
                        />
                    </div>
                    <div style={{ height: "30px" }}></div>
                </div>
                <Box style={{ width: "650px" }}>
                    <RichTextEditor  required value={value} onChange={(newValue) => { setValue(newValue);}} placeholder={'Enter'} />
                    {error &&
                    <div style={{display:"flex",justifyContent:"center",alignContent:"center",marginTop:"20px"}}>
                    <Text variant={"body2"} style={{color:"red"}}>You Still Have Empty Fields.</Text>
                    </div>
                    }
                    <div style={{ height: "50px" }}></div>
                    <BaseButton
                        isLoading={loading}
                        type='button'
                        onClick={async()=>{await addBlog();}}
                    >
                        Done
                    </BaseButton>
                </Box>
            </FormWrapper>
        </Page>
    );
};

export default Blogs;