import React, { useCallback, useMemo } from "react";
import { createEditor, Editor, Node, Transforms } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { withHistory } from 'slate-history';
import { Theme } from "@material-ui/core";
import isHotkey from 'is-hotkey';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import InsertLink from '@material-ui/icons/InsertLink';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import makeStyles from "@material-ui/core/styles/makeStyles";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const useStyles = makeStyles((theme) => ({
    root: {
        border: "none",
        width: "38px",
        height: "38px",
        margin: "4px !important",
        borderRadius: "4px !important",
    },
    selected: {
        color: theme.palette.primary.main,
        backgroundColor: "rgba(9,135,118,0.15) !important",
        "& span": {
            color: "rgb(9,135,118)"
        }
    }
}));

const RichTextEditor = ({ value, onChange = () => { }, placeholder, readOnly = false }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const renderElement = useCallback(props => <Element {...props} children={props.children} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} children={props.children} />, [])

    const isBlockActive = (editor, format) => {
        // @ts-ignore
        const [match] = Editor.nodes(editor, {
            // @ts-ignore
            match: n => n.type === format,
        })

        return !!match
    }

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format)
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            // @ts-ignore
            match: n => LIST_TYPES.includes(n.type),
            split: true,
        })

        Transforms.setNodes(editor, {
            // @ts-ignore
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        })

        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    const Element = ({ attributes, children, element }) => {
        switch (element.type) {
            case 'bulleted-list':
                return <ul {...attributes}>{children}</ul>
            case 'list-item':
                return <li {...attributes}>{children}</li>
            case 'numbered-list':
                return <ol {...attributes}>{children}</ol>
            default:
                return <p {...attributes}>{children}</p>
        }
    }

    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        return <span {...attributes}>{children}</span>
    }

    const BlockButton = ({ format, icon }) => {

        const editor = useSlate();
        const classes = useStyles();

        return (
            <ToggleButton
                tabIndex={-1}
                classes={{
                    root: classes.root,
                    selected: classes.selected
                }}
                TouchRippleProps={{ style: { filter: "blur(10px)", opacity: "0.95" } }}
                selected={isBlockActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                {icon}
            </ToggleButton>
        )
    }

    const MarkButton = ({ format, icon }) => {

        const editor = useSlate()
        const classes = useStyles();

        return (
            <ToggleButton
                tabIndex={-1}
                classes={{
                    root: classes.root,
                    selected: classes.selected
                }}
                TouchRippleProps={{ style: { filter: "blur(10px)", opacity: "0.95" } }}
                selected={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                {icon}
            </ToggleButton>
        )
    }

    return (
        <div style={{ border: readOnly ? "none" : "2px solid rgb(240,240,240)", background: "white", padding: readOnly ? "0" : "12px", borderRadius: "6px" }}>
            <Slate editor={editor} value={value} onChange={onChange}>
                {!readOnly &&
                    <ToggleButtonGroup aria-label="text formatting" style={{ background: "rgb(250,250,250)", borderRadius: "6px", padding: "3px", width: "100%", boxSizing: "border-box" }}>
                        <MarkButton format="bold" icon={<FormatBoldIcon />} />
                        <MarkButton format="italic" icon={<FormatItalicIcon />} />
                        <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
                    </ToggleButtonGroup>
                }
                <Editable
                    style={{ minHeight: readOnly ? "200px" : "250px", maxHeight: readOnly ? "unset" : "382px", height: "300px", overflow: 'overlay', fontSize: readOnly ? "20px" : "18px", fontFamily: "'HKGroteskMedium', sans-serif", padding: readOnly ? "0" : "0 12px" }}
                    spellCheck
                    readOnly={readOnly}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={placeholder}
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                if (hotkey === 'mod+b' || hotkey === 'mod+i' || hotkey === 'mod+u') {
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }
                    }}
                />
            </Slate>
        </div>
    )

}

export default RichTextEditor;