import React, { useEffect, useState } from "react";
import Text from "../text";
import useTheme from "@material-ui/core/styles/useTheme";
import { useRoutes, useNavigate } from 'react-router-dom';
import MiniButton from "../components/input-fields/mini-button";
import PrimaryButton from "../components/input-fields/primary-button";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import { FiInfo, FiX, AiFillCheckCircle } from "react-icons/all";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { myTaskAttachments } from "../../../../utils/firebase.utils";
import firebase from "firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogBottomButton from "../components/dialogs/dialog-bottom-button";

const useStyles = makeStyles(() => ({
    previewDeleteIcon: {
        cursor: "pointer",
        borderRadius: "50%",
        background: "rgba(0, 0, 0,0.8)",
        position: "absolute",
        right: "8px",
        top: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20px",
        width: "20px",
        transition: "0.3s",
        "&:hover": {
            transform: "scale(1.1)",
        },
    },
    previewContainer: {
        position: "relative",
        height: "120px",
        boxShadow: "0 4px 11px 0.2px rgba(0,0,0,0.06)",
        display: "inline-block",
        margin: "8px",
        overflow: "hidden",
        borderRadius: "8px",
        width: '120px'
    },
    progressBar: {
        color: "white",
    },
    progressBackground: {
        color: "rgba(255,255,255,0.5)",
    },
}));

const TaskAttachmentsForm = ({ task, updateTask, onContinue, ...rest }) => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const userId = task.uid;
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [progress, setProgress] = useState([0, 0, 0, 0]);

    useEffect(() => {
        setAttachments(task.attachments);
        console.log('a',attachments)
    }, [attachments]);

    const handleRemove = (f) => (event) => {
        event.preventDefault();
        if (typeof f !== "string") f.remove();
        else {
            let a = [...attachments];
            a.splice(a.indexOf(f), 1);
            setAttachments([...a]);
        }
    };

    const handleChangeStatus = (file, status, allFiles = [IFileWithMeta]) => {
        console.log(status);
        if (status === "removed") {
            let f = attachments.find((a) => typeof a !== "string" && a.meta.id === file.meta.id);
            let a = [...attachments];
            a.splice(a.indexOf(f), 1);
            setAttachments([...a]);
        } else if (status === "done") {
            let a = [...attachments];
            allFiles.forEach((file) => {
                if (!a.find((item) => typeof item !== "string" && item.meta.id === file.meta.id)) {
                    a.push(file);
                }
            })
            setAttachments([...a.slice(0, 4)]);
        }
    };

    const goNext = () => {
        if (updateTask) {
            if (attachments.length === 0){
                task.attachments = [];
                updateTask(task);
                 onContinue();
            }
            else {
                setLoading(true);
                let uploaded = [];

                attachments.forEach((a, index) => {
                    if (typeof a === "string") uploaded.push(a);
                    else {
                        let uploadTask = myTaskAttachments(userId).child(a.file.name).put(a.file);
                        uploadTask.on(
                            firebase.storage.TaskEvent.STATE_CHANGED,
                            (snapshot) => {
                                setProgress((p) => {
                                    p[index] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    return p;
                                });

                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED:
                                        break;
                                    case firebase.storage.TaskState.RUNNING:
                                        break;
                                }
                            },
                            (error) => {
                                console.log(error);
                            },
                            () => {
                                uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
                                    uploaded.push(downloadURL);
                                    if (uploaded.length === attachments.length) {
                                        task.attachments = uploaded;
                                        updateTask(task);
                                        onContinue();
                                    }
                                });
                            }
                        );
                    }
                });
                if (attachments.length === uploaded.length) {
                    task.attachments = uploaded;
                    updateTask(task);
                    onContinue();
                }
            }
        }
    };

    return (
        <div style={{ paddingTop: "16px" }}>
            <Text variant={"body2"} style={{ color: theme.palette.primary.main, marginBottom: "4px" }} medium>
                Attachments (Optional)
            </Text>
            <Text variant={"body2"} style={{ color: "grey", marginBottom: "18px" }}>
                Adding photos help Taskers have a better understanding of what needs to be done.
            </Text>
            <Dropzone
                multiple={true}
                inputContent={
                    <p key={"dropzone"} style={{ textAlign: "center", padding: "0 16px", justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                        <Text component={"span"} variant={"body2"} bold style={{ marginRight: "8px", textAlign: "left", color: loading || attachments.length === 4 ? "rgb(140,140,140)" : "rgb(78, 62, 253)" }}>
                            Drag n' Drop your photos here
                        </Text>
                        <MiniButton component={"span"} disabled={loading || attachments.length === 4}>
                            Pick Photos
                        </MiniButton>
                    </p>
                }
                inputWithFilesContent={
                    <p key={"dropzone"} style={{ textAlign: "center", padding: "0 16px", justifyContent: "space-between", alignItems: "center", display: "flex" }}>
                        <Text component={"span"} variant={"body2"} bold style={{ marginRight: "8px", textAlign: "left", color: loading || attachments.length === 4 ? "rgb(140,140,140)" : "rgb(78, 62, 253)" }}>
                            Drag n' Drop your photos here
                        </Text>
                        <MiniButton component={"span"} disabled={loading || attachments.length === 4}>
                            Pick Photos
                        </MiniButton>
                    </p>
                }
                PreviewComponent={null}
                submitButtonDisabled={true}
                onChangeStatus={handleChangeStatus}
                disabled={loading || attachments.length === 4}
                accept="image/*"
                styles={{
                    input: { display: "none" },
                    dropzone: { minHeight: 32, borderRadius: "8px", border: "2px dashed rgb(220,220,220)", padding: "8px", background: "white", overflow: "hidden" },
                    dropzoneDisabled: { background: "white", border: "2px dashed rgb(230, 230, 230)" },
                    dropzoneActive: { background: "rgba(78, 62, 253,0.13)", border: "2px solid rgb(78, 62, 253)" },
                    dropzoneReject: { background: "rgb(235, 80, 60, 0.13)", border: "2px solid rgb(235, 80, 60)" },
                }}
            />
            <div style={{ display: "flex", marginTop: "14px" }}>
                <FiInfo style={{ color: "grey" }} size={14} />
                <Text variant={"caption"} style={{ color: "grey", marginLeft: "12px" }} medium>
                    You can upload upto 4 images.
                </Text>
            </div>
            <div style={{ width: "100%", boxSizing: "border-box", overflowX: "auto", padding: "18px 0px", whiteSpace: "nowrap" }}>
                {attachments.map((f, i) => {
                    if (typeof f !== "string")
                        return (
                            <div key={i} className={classes.previewContainer} style={{ backgroundImage: `url(${f.meta.previewUrl})`, backgroundSize: 'cover' }}>
                                {loading ? (
                                    <div style={{ width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", top: 0, position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                        {progress[i] === 100 ? (
                                            <AiFillCheckCircle size={32} color={"white"} />
                                        ) : (
                                            <div style={{ transform: "translate(-18px, -18px)" }}>
                                                <CircularProgress variant={"determinate"} style={{ position: "absolute" }} value={progress[i]} classes={{ colorPrimary: classes.progressBar }} size={40} thickness={12} />
                                                <CircularProgress variant={"determinate"} style={{ position: "absolute" }} value={100} classes={{ colorPrimary: classes.progressBackground }} size={40} thickness={12} />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={classes.previewDeleteIcon} onClick={handleRemove(f)}>
                                        <FiX style={{ height: "14px", width: "14px", color: "white" }} />
                                    </div>
                                )}
                            </div>
                        );
                    else
                        return (
                            <div key={i} className={classes.previewContainer} style={{ backgroundImage: `url(${f})`, backgroundSize: 'cover' }}>
                                {loading ? (
                                    <div style={{ width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", top: 0, position: "absolute", alignItems: "center", justifyContent: "center", display: "flex" }}>
                                        <AiFillCheckCircle size={32} color={"white"} />
                                    </div>
                                ) : (
                                    <div className={classes.previewDeleteIcon} onClick={handleRemove(f)}>
                                        <FiX style={{ height: "14px", width: "14px", color: "white" }} />
                                    </div>
                                )}
                            </div>
                        );
                })}
            </div>
            <DialogBottomButton type={"submit"} onClick={goNext}>
                {attachments.length > 0 ? "Upload & Continue" : "Continue"}
            </DialogBottomButton>
        </div>
    );
};

export default TaskAttachmentsForm;
