import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core';
import Page from '../../components/Page';
import RichTextEditor from './components/rich-text-editor.tsx';
import PrimaryButton from '../task/edit task/components/input-fields/primary-button';
import firebaseUtility from '../../utils/firebase.utils';
import FormInput from '../task/edit task/components/input-fields/form-input';
import FormWrapper from '../task/edit task/components/input-fields/form-wrapper';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '50%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const AddArticle = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = React.useState([false, false]);
  const [value, setValue] = useState([
    { type: 'paragraph', children: [{ text: '' }] }
  ]);
  const [section, setSection] = useState('');
  const [audience, setAudience] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [categorySlug, setCategorySlug] = useState('');
  const [sectionSlug, setSectionSlug] = useState('');
  const [sections, setSections] = useState([]);
  const [Categories, setCategories] = useState([]);

  const getListsArticle = async () => {
    setLoading(true);
    const catigories_Sections = await firebaseUtility.GetOneDocById(
      'analysis',
      'support_index'
    );
    let categoriesList = [];
    if (catigories_Sections && catigories_Sections?.poster?.length > 0) {
      for (
        let pindex = 0;
        pindex < catigories_Sections?.poster?.length;
        pindex++
      ) {
        const item = catigories_Sections?.poster[pindex];
        const elementCategory = item;
        if (elementCategory && !categoriesList.includes(elementCategory)) {
          categoriesList.push(elementCategory);
        }
      }
    }
    if (catigories_Sections && catigories_Sections?.tasker?.length > 0) {
      for (
        let tindex = 0;
        tindex < catigories_Sections?.tasker?.length;
        tindex++
      ) {
        const item = catigories_Sections?.tasker[tindex];
        const elementCategory = item;
        if (elementCategory && !categoriesList.includes(elementCategory)) {
          categoriesList.push(elementCategory);
        }
      }
    }
    setCategories(categoriesList);

    setLoading(false);
  };

  useEffect(() => {
    getListsArticle();
  }, []);
  const saveArticles = async values => {

    await firebaseUtility
      .Firestore()
      .collection('support_articles')
      .add({
        title: values.title ? values.title : '',
        slug: values.title ? values.title : '',
        segment: checked.toString(),
        segmentSlug: audience,
        category: category ? category : '',
        categorySlug: categorySlug ? categorySlug : '',
        section: section ? section : '',
        sectionSlug: sectionSlug ? sectionSlug : '',
        body: value ? value : '',
        updateAt: new Date()
      })
      .then(() => {
        values.title = '';
        values.slug = '';
        values.segment = '';
        values.segmentSlug = '';
        values.category = '';
        value = '';
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const handleForBoth = event => {
    setChecked([event.target.checked, event.target.checked]);
    setAudience(event.target.checked ? 'tasker,poster' : '');
  };

  const handleForPoster = event => {
    setChecked([event.target.checked, checked[1]]);
    if (checked == [true, true]) {
    }
    setAudience(event.target.checked ? 'poster' : '');
  };

  const handleForTasker = event => {
    setChecked([checked[0], event.target.checked]);
    setAudience(event.target.checked ? 'tasker' : '');
  };
  const handleSectionChange = event => {
    const {
      target: { value }
    } = event;
    setSection(
      typeof value === 'string' && value.toString()
    );
    setSectionSlug(value.replace(/\s/g, '-'));
  };

  const handleCategoryChange = event => {
    const {
      target: { value }
    } = event;

    setCategory(
      typeof value === 'string' && value.toString()
    );
    const categorySections = Categories.find(
      item => item.name === value.toString()
    );

    if (categorySections && categorySections.sections?.length) {
      let sectionsList = [];
      for (let index = 0; index < categorySections.sections?.length; index++) {
        const elementSectionData = categorySections.sections[index];
        if (sectionsList && !sectionsList.includes(elementSectionData.name)) {
          sectionsList.push(elementSectionData.name);
        }
      }
      setSections(sectionsList);
    }
    setCategorySlug(value.replace(/\s/g, '-'));
  };
  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        name="poster"
        label="Poster"
        control={
          <Checkbox
            name="posterCheck"
            checked={checked[0]}
            onChange={handleForPoster}
          />
        }
      />
      <FormControlLabel
        name="takser"
        label="Tasker"
        control={
          <Checkbox
            name="taskerCheck"
            checked={checked[1]}
            onChange={handleForTasker}
          />
        }
      />
    </Box>
  );

  return (
    <Page title="Add Article">
      <FormWrapper onSubmit={saveArticles} reset>
        <div>
          <Box style={{ width: '650px', marginTop: '50px' }}>
            <FormInput
              label={'Article Title'}
              placeholder={'Article Title'}
              name={'title'}
              type={'text'}
              defaultValue={title}
            />
          </Box>
          <Box>
            {/* category */}
            <FormControl>
              <FormHelperText>Category</FormHelperText>
              <Select
                name="category"
                style={{
                  width: '300px',
                  height: '45px',
                  backgroundColor: 'white'
                }}
                defaultValue={category}
                onChange={handleCategoryChange}
              >
                {Categories.map(category => {
                  return (
                    <MenuItem value={category.name}>{category.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {/* section */}

            <FormControl style={{ marginLeft: '40px' }}>
              <FormHelperText>Section</FormHelperText>
              <Select
                name="section"
                style={{
                  width: '300px',
                  height: '45px',
                  backgroundColor: 'white'
                }}
                disabled={category == '' ? true : false}
                defaultValue={section}
                onChange={handleSectionChange}
              >
                {sections.map(section => {
                  return <MenuItem value={section}>{section}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControlLabel
              label="Target Audience"
              control={
                <Checkbox
                  name="target"
                  checked={checked[0] && checked[1]}
                  indeterminate={checked[0] !== checked[1]}
                  onChange={handleForBoth}
                />
              }
            />
            {children}
          </Box>
          <div style={{ height: '30px' }}></div>
        </div>
        <Box style={{ width: '650px' }}>
          <RichTextEditor
            value={value}
            onChange={newValue => {
              setValue(newValue);
            }}
            placeholder={'Enter'}
          />
          <div style={{ height: '50px' }}></div>
          <PrimaryButton>Done</PrimaryButton>
        </Box>
      </FormWrapper>
    </Page>
  );
};

export default AddArticle;
