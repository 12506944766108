import { number } from "prop-types";
import Address from "./address";

export class BirthDate {
  day;
  month;
  year;

  get date() {
    if (this.day !== null && this.month !== null && this.year !== null) return new Date(`${this.month.toString()}-${this.day.toString()}-${this.year.toString()}`);
    else return null;
  }

  constructor(data) {
    Object.assign(this, data);
  }

  toJson() {
    return Object.assign({}, this);
  }
}

export class Geoloc {
  lat;
  lng;

  constructor(data) {
    this.lat = data?.lat ?? null;
    this.lng = data?.lng ?? null;
  }

  toJson() {
    return Object.assign({}, this);
  }
}

export class AssignedWorker {
  uid;
  name;
  profilePicture;
  reviewTotalAverage;
  taskPrice;
  bookingFees;
  totalPaid;
  serviceFees;
  totalTransferred;

  constructor(data) {
    this.uid = data?.uid ?? null;
    this.name = data?.name ?? null;
    this.profilePicture = data?.profilePicture ?? null;
    this.reviewTotalAverage = data?.reviewTotalAverage ? parseInt(data.reviewTotalAverage.toString()) : null;
    this.taskPrice = data?.taskPrice ?? null;
    this.bookingFees = data?.bookingFees ?? null;
    this.totalPaid = data?.totalPaid ?? null;
    this.serviceFees = data?.serviceFees ?? null;
    this.totalTransferred = data?.totalTransferred ?? null;
  }

  toJson() {
    return Object.assign({}, this);
  }
}

export class TaskOwner {
  name = String || null;
  profilePicture = String || null;

  constructor(data) {
    this.name = data?.name ?? "";
    this.profilePicture = data?.profilePicture ?? null;
  }

  toJson() {
    return Object.assign({}, this);
  }
}

export class Task {
  id;
  title;
  description;
  category;
  isMultipleTasker = false;
  taskerRequired = 1;
  isRemote = false;
  isAssigned = false;
  isComplete = false;
  isCancelled = false;
  isHourly = false;
  hours = 0;
  budgetByHour;
  workerHasReviewed = false;
  taskOwnerHasReviewed = false;
  requestPayment = false;
  city;
  _geoloc;
  dateToStart = Date;
  timings = [];
  requirements = [];
  attachments = [];
  offerUIDs = [];
  budget = null;
  offers = 0;
  comments = 0;
  lowestOffer;
  assignedWorker;
  taskOwner;
  uid;
  paymentIntent;
  address;
  createAt;
  invitedTaskers = [];

  constructor(data) {
    this.id = data?.id ?? null;
    this.title = data?.title ?? null;
    this.description = data?.description ?? null;
    this.category = data?.category ?? null;
    this.isMultipleTasker = data?.isMultipleTasker ?? false;
    this.taskerRequired = data?.taskerRequired ?? 1;
    this.isRemote = data?.isRemote ?? false;
    this.isAssigned = data?.isAssigned ?? false;
    this.isComplete = data?.isComplete ?? false;
    this.isCancelled = data?.isCancelled ?? false;
    this.workerHasReviewed = data?.workerHasReviewed ?? false;
    this.taskOwnerHasReviewed = data?.taskOwnerHasReviewed ?? false;
    this.requestPayment = data?.requestPayment ?? false;
    this.city = data?.city ?? null;
    this._geoloc = data?._geoloc != null ? new Geoloc(data._geoloc) : null;
    this.dateToStart = data?.dateToStart ? (typeof data?.dateToStart === "string" ? new Date(data.dateToStart) : (data.dateToStart instanceof Date ? data.dateToStart : new Date(data.dateToStart.toDate().toString()))) : null;
    this.timings = data?.timings != null ? data.timings : [];
    this.requirements = data?.requirements != null ? data.requirements : [];
    this.attachments = data?.attachments != null ? data.attachments : [];
    this.offerUIDs = data?.offerUIDs != null ? data.offerUIDs : [];
    this.budget = data?.budget ?? null;
    this.offers = data?.offers ?? 0;
    this.comments = data?.comments ?? 0;
    this.lowestOffer = data?.lowestOffer ?? "";
    this.assignedWorker = data?.assignedWorker != null ? new AssignedWorker(data.assignedWorker) : null;
    this.taskOwner = data?.taskOwner != null ? new TaskOwner(data.taskOwner) : null;
    this.uid = data?.uid ?? null;
    this.paymentIntent = data?.paymentIntent ?? null;
    this.createAt = data?.createAt ? (typeof data?.createAt === "" ? new Date(data.createAt) : (data.createAt instanceof Date ? data.createAt : new Date(data.createAt.toDate().toString()))) : null;
    this.address = data?.address != null ? new Address(data.address) : null;
    this.isHourly = data?.isHourly ?? false;
    this.hours = data?.hours ?? 0;
    this.budgetByHour = data?.budgetByHour ?? null;
    this.invitedTaskers = data?.invitedTaskers != null ? data.invitedTaskers : [];
  }

  get isExpired() {
    return !this.isCancelled && !this.isAssigned && !this.isComplete && ((this.dateToStart) < (new Date()));
  }

  get isOpen() {
    return !this.isCancelled && !this.isAssigned && !this.isComplete && !this.isExpired;
  }

  get isAssignedOnly() {
    return !this.isCancelled && this.isAssigned && !this.isComplete;
  }

  toJson() {
    return Object.assign({}, this);
  }

}
