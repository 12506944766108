import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import LogsRow from './LogsRow';
const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  rounded: {
    borderRadius: '15px'
  }
}));

// eslint-disable-next-line max-len
const LogsResults = ({
  className,
  isLoading,
  pageIndex,
  pageSizeIndex,
  setPageSize,
  pageChanged,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [page, setPage] = useState(pageIndex);
  const [loading] = useState(isLoading);

  const handleLimitChange = event => {
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage || page);
    pageChanged(newPage || page);
  };
  const createRowData = (errorCode, error, date, platform) => {
    return {
      errorCode,
      date,
      platform,
      error
    };
  };
  const rows = [
    createRowData(540, 'error logs test ', '2020/01/05', 'API'),
    createRowData(700, 'error logs test 2 ', '2020/08/05', 'Mobile'),
    createRowData(2000, 'error logs test 3 ', '2021/08/05', 'Web')
  ];
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Error Code</TableCell>
                    <TableCell align="center">PlatForm</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <LogsRow key={'' + index} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={-1}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
    </>
  );
};

LogsResults.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func,
  pageChanged: PropTypes.func,
  pageSizeIndex: PropTypes.number,
  pageIndex: PropTypes.number
};

export default LogsResults;
