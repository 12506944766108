import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReactTimeago from 'react-timeago';
import ReadMore from '../readMore';
import Avatar from '../avatar';

const ReplyListItem = ({
  reply, previousUid, currentUid, repliesLength, index
}) => {
  return (
    <div>
      <div style={{

        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        position: 'relative',
        boxShadow: '0 0 30px -16px rgba(50,50,50,0.05)',
        transition: '0.3s',
        padding: '10px 40px',
        marginBottom: index === repliesLength - 1 ? '40px' : '0'
      }}
      >

        <div style={{ display: 'flex', alignItems: 'flex-start' }}>

          <div style={{
            position: 'absolute', width: '1px', background: 'rgb(200,200,200)', height: index === repliesLength ? `calc('100%' + ${previousUid === reply.uid ? '4px' : '24px'})` : '100%', top: index === repliesLength - 1 ? '-29px' : '-24px', left: '58px', zIndex: 2
          }}
          />

          { previousUid === reply.uid
            ? (
              <div style={{
                width: '10px', height: '10px', marginTop: '10px', marginLeft: '13.5px', marginRight: '3px', borderRadius: '50%', background: 'rgb(200,200,200)'
              }}
              />
            )
            : <Avatar name={reply.name} profilePicture={reply.profilePicture} size={previousUid !== reply.uid ? 25 : 14} style={{ marginLeft: '6px' }} />}
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginLeft: '12px'
          }}
          >
            {
              previousUid === reply.uid
                ? <div />
                : (
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography variant="body2">{reply.name}</Typography>
                    <div style={{
                      margin: '0 8px', width: '4px', height: '4px', borderRadius: '50%', background: 'grey'
                    }}
                    />
                    <Typography style={{ color: '#646464' }} variant="caption">
                      <ReactTimeago
                        date={reply?.createAt}
                        live={false}
                        formatter={(value, unit) => {
                          return `${value.toString()} ${unit}${value > 1 ? 's' : ''}`;
                        }}
                      />
                    </Typography>
                  </div>
                )
            }
            <Typography variant="body2" style={{ color: 'black', marginTop: '4px' }}>
              <ReadMore maxLength={80}>{reply.text}</ReadMore></Typography>
            
          </div>
        </div>
      </div>

    </div>
  );
};

export default ReplyListItem;
