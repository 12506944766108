import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NoQuestions from '../../../../assets/images/comment_empty.png';
import QuestionCard from '../../../../components/questionCard';
import Grid from '@material-ui/core/Grid';
import firebaseUtility from '../../../../utils/firebase.utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// import CircularProgressBar from "../../../components/CircularProgress/circular-progress-bar";
const Fade = require('react-reveal/Fade');

const QuestionsTab = (props) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [commentId, setId] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setQuestions(props.questions);
    setLoading(false);
  }, []);

  const deleteItem = async (event) => {
    event.preventDefault();
    try {
      const comment = questions.filter((comm) => {
        return comm.id === commentId;
      });
      if (comment[0]) {
        if (comment[0].replies !== null && comment[0].replies === 0) {
          await firebaseUtility.Firestore()
            .collection('tasks').doc(comment[0].taskId)
            .collection('comments')
            .doc(comment[0].id)
            .delete();
        } else {
          const batch = firebaseUtility.Firestore().batch();
          const Ref = await
            firebaseUtility.Firestore()
              .collection('tasks')
              .doc(comment[0].taskId)
              .collection('comments')
              .doc(comment[0].id)
              .collection('replies')
              .get();

          Ref.docs.forEach((reply) => {
            batch.delete(reply.ref);
          });

          await batch.commit();

          await firebaseUtility.Firestore()
            .collection('tasks').doc(comment[0].taskId)
            .collection('comments')
            .doc(comment[0].id)
            .delete();
        }
        const allComments = questions.filter((commen) => {
          return commen.id !== commentId;
        });
        setQuestions([...allComments]);
        props.passItems(allComments,'comments')
      }
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const promptDelete = (id) => {
    setId(id);
    setOpen(true);
  };

  return (
    <div style={{ marginTop: '24px' }}>
      {!loading
                && (
                <Fade bottom distance="20px" duration={400}>
                  <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    {questions.map((question) => {
                      return <QuestionCard question={question} promptDelete={promptDelete} key={question.id} insideTask={true}/>;
                    })}
                  </div>
                </Fade>
                )}
      {loading
                && (
                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '24px 0'
                }}
                >
                  {/* <CircularProgressBar /> */}
                </div>
                )}
      {(!loading && questions.length === 0)
                && (
                <Fade bottom distance="20px" duration={400}>
                  <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '24px 0', border: '1.5px solid rgb(240,240,240)', borderRadius: '6px'
                  }}
                  >
                    <img src={NoQuestions} alt="No Questions for this task" width={100} />
                    <div style={{ height: '16px' }} />
                    <Typography variant="caption" medium>No Questions yet</Typography>
                  </div>
                </Fade>
                )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteItem} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default QuestionsTab;
