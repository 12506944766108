import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CloseTaskDialog = ({opened, closeDialog, setText, handleCancel, text, ...rest}) => {
  const handleClose = () => {
    if (closeDialog) {
      closeDialog();
    }
  };

  return (
    <div>
      <Dialog open={opened} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancel Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write the reason of canceling the task in the text field below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reason"
            type="text"
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel Task
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CloseTaskDialog;
