import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {}
}));

// eslint-disable-next-line react/prop-types
const Toolbar = ({
  className,
  searchTasksMethod,
  isLoading,
  filterData,
  ...rest
}) => {
  const classes = useStyles();
  const [text, setText] = useState('');

  const filterTasks = ev => {
    const filter = ev.target.value;
    return filterData(filter);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item container md={7} spacing={1}>
                <Grid item md={6}>
                  <Box>
                    <TextField
                      fullWidth
                      type="date"
                      label="From Date"
                      placeholder="From Date"
                      defaultValue="2017-05-24"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={!!isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        setText(e.target.value);
                        if (e.target.value === '') {
                          searchTasksMethod('');
                        }
                      }}
                      onKeyPress={async e => {
                        const code = e.keyCode ? e.keyCode : e.which;
                        // on press enter
                        if (code == 13) {
                          e.preventDefault();
                          // search
                          await searchTasksMethod(text);
                        }
                      }}
                      placeholder="Search users by email"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <TextField
                      fullWidth
                      type="date"
                      label="To Date"
                      placeholder="To Date"
                      defaultValue="2017-05-24"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={!!isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      onChange={e => {
                        setText(e.target.value);
                        if (e.target.value === '') {
                          searchTasksMethod('');
                        }
                      }}
                      onKeyPress={async e => {
                        const code = e.keyCode ? e.keyCode : e.which;
                        // on press enter
                        if (code == 13) {
                          e.preventDefault();
                          // search
                          await searchTasksMethod(text);
                        }
                      }}
                      placeholder="Search users by email"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{
                        color: '#c3c3c3'
                      }}
                    >
                      Please press Enter to search
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Box maxWidth={'400px'}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Platform
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="Status filter"
                      onChange={ev => {}}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={2}>Api's</MenuItem>
                      <MenuItem value={3}>Web</MenuItem>
                      <MenuItem value={4}>Mobile</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{
                      color: '#c3c3c3'
                    }}
                  >
                    Select platform to filter the data
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  searchTasksMethod: PropTypes.func,
  isLoading: PropTypes.bool.isRequired
};

export default Toolbar;
