import React, { useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, InputAdornment, Button } from '@material-ui/core';
import FormInput from '../task/edit task/components/input-fields/form-input';
import firebaseUtility from '../../utils/firebase.utils';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import firebase from 'firebase';
import { baseUrl } from '../../utils/constant.urls';
import { useNavigate } from 'react-router';
import FormWrapper from '../task/edit task/components/input-fields/form-wrapper';

const useStyles = makeStyles(theme =>
  createStyles({
    tableHeader: {
      backgroundColor: theme.palette.primary.main
    },
    tableHeaderText: {
      color: theme.palette.primary.contrastText
    },
    Typography: {
      marginTop: '30px'
    },
    table: {
      Width: 400
    },
    Button: {
      height: '35px'
    },
    rounded: {
      borderRadius: '15px'
    }
  })
);

const PopularSearch = () => {
  const classes = useStyles();
  const [admin, setAdmin] = useState('');
  const [uid, setUid] = useState('');
  const [ad, setAd] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [popular_search, setPopular_search] = useState([]);

  const addRequirements = async values => {
    let r = [...popular_search];
    r.push(values.reqText);
    await setPopular_search(r);
    console.log(r, 'r');
    search.popular_search = r;
    console.log(search.popular_search, 'search.popular_search');

    await firebaseUtility
      .Firestore()
      .collection('popular_search')
      .doc('popular_search')
      .set(
        {
          popular_search: r
        },
        { merge: true }
      );
  };

  const removeRequirements = index => {
    let r = [...popular_search];

    r.splice(index, 1);

    setPopular_search(r);

    search.popular_search = r;
    firebaseUtility
      .Firestore()
      .collection('popular_search')
      .doc('popular_search')
      .set(
        {
          popular_search: popular_search
        },
        { merge: true }
      );
  };

  useEffect(() => {
    firebaseUtility
      .Firestore()
      .collection('popular_search')
      .doc('popular_search')
      .get()
      .then(doc => {
        if (doc.exists) {
          setSearch(doc.data());
        }
      });
  }, []);

  useEffect(() => {
    setPopular_search(search && search.popular_search);
  }, [search]);

  return (
    <div>
      <br />

      <FormWrapper
        disableLoading
        reset
        onSubmit={addRequirements}
        style={{ marginTop: '16px', position: 'relative' }}
      >
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <FormInput
              placeholder={'Popular searched'}
              autoCapitalize={'sentences'}
              name={'reqText'}
              type={'text'}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    type={'submit'}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    style={{
                      padding: '8px',
                      marginRight: '12px',
                      height: '32px',
                      width: '32px'
                    }}
                  >
                    <Button
                      className={classes.Button}
                      disabled={isSubmitting}
                      size="small"
                      variant="contained"
                      type={'submit'}
                      color="primary"
                    >
                      Add
                    </Button>
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </FormWrapper>
      <Typography variant="h6" component="h3" className={classes.Typography}>
        Viewing all Popular Searches
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableHeaderText} align="left">
                Admin Name
              </TableCell>

              <TableCell className={classes.tableHeaderText} align="left">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search &&
              search.popular_search.map((r, i) => (
                <TableRow>
                  <TableCell align="left"> {r}</TableCell>

                  <TableCell align="left">
                    <IconButton
                      onClick={() => {
                        removeRequirements(i);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default PopularSearch;
