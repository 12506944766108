import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  Checkbox,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import firebase from 'firebase/app';
import { useParams } from 'react-router';
import routes from '../../routes';
import firebaseUtility from '../../utils/firebase.utils';
import exportConfigs from '../../configs';
import BadgesTab from '../../components/tabsComponents/badgesTab';
import ProfileTab from '../../components/tabsComponents/profileTab';
import ChargeUserTab from '../../components/tabsComponents/charge-user';
import TaskTabs from '../../components/tabsComponents/tasksTab';
import OffersTab from '../../components/tabsComponents/offersTab';
import CommentsTab from '../../components/tabsComponents/commentsTab';
import RepliesTab from '../../components/tabsComponents/repliesTab';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dialogRoot: { height: '90vh' },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '3em',
  },
  rootTabContainer: {
    transform: 'translateY(4px)',
    background: 'white',
    padding: '0 24px',
    borderRadius: '6px',
  },
  rootTab: {
    textTransform: 'none',
    padding: '0',
    minWidth: 'unset',
    justifyContent: 'flex-start !important',
    fontSize: theme.typography.pxToRem(15),
    marginRight: '20px',
  },
  indicatorTab: {
    display: 'flex',
    height: '4px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },

  },
}));

// eslint-disable-next-line react/prop-types,no-unused-vars
const EditUser = ({
  opened, closeDialog, user, badgesItems, ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [disableDialog, setDisableDialog] = useState(false);
  const [privateData, setPrivateData] = useState(null);
  const params = useParams();
  const [checkBoxes, setCheckBox] = React.useState({
    deleteTasks: false,
    deleteOffers: false,
  });

  const handleChange = (event) => {
    setCheckBox({ ...checkBoxes, [event.target.name]: event.target.checked });
  };
  const handleClose = () => {
    if (closeDialog) {
      closeDialog(user);
    }
  };

  useEffect(() => {
    getPrivateUser();
  }, [params["id"]]);

  const getPrivateUser = async () => {
    const userData = await firebaseUtility.GetOneDocById('users', params["id"]);
    setPrivateData(userData);
    console.log('p:',userData.about)
  };

  const deleteUserTasks = async () => {
    const batch = firebaseUtility.Firestore().batch();

    const userTasks = await firebaseUtility.Firestore().collection('tasks')
      .where('uid', '==', user.uid)
      .where('isCancelled', '==', false)
      .where('isAssigned', '==', false)
      .where('isComplete', '==', false)
      .where('isDispute', '==', false)

      .get();

    if (!userTasks.empty) {
      userTasks.docs.forEach((task) => {
        batch.set(task.ref, { isCancelled: true }, { merge: true });
      });
      await batch.commit();
    }
  };

  const deleteUserOffers = async (tasksIds) => {
    const batch = firebaseUtility.Firestore().batch();
    for (let i = 0; i < tasksIds.length; i++) {
      const offerRef = await firebaseUtility.Firestore()
        .collection('tasks').doc(tasksIds[i])
        .collection('offers')
        .doc(user.uid);
      batch.delete(offerRef);
    }
    await batch.commit();
  };

  const fetchTasksForOffers = async () => {
    if (user.uid) {
      const tasksIds = [];
      const d = new Date();
      const today = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());
      const tasksRef = await firebaseUtility.Firestore().collection('tasks')
        .orderBy('dateToStart')
        .where('offerUIDs', 'array-contains', user.uid)
        .where('isAssigned', '==', false)
        .where('isCancelled', '==', false)
        .where('isComplete', '==', false)
        .where('dateToStart', '>', today)
        .get();

      if (!tasksRef.empty) {
        tasksRef.docs.forEach((task) => {
          tasksIds.push(task.data().id);
        });
        await deleteUserOffers(tasksIds);
      }
    }
  };

  const disableUser = () => {
    setLoading(true);
    const body = {
      uid: user.uid,
      disabled: user.disabled ? 1 : 0,
    };
    firebaseUtility
      .Auth()
      .currentUser.getIdToken(true)
      .then(async (token) => {
        const configs = exportConfigs();
        await axios.post(
          `${configs.apiUrl}/disableUser`,
          {
            ...body,
          },
          {
            headers: {
              authorization: token,
            },
          },
        );

        user.disabled = !user.disabled;
        await firebaseUtility.setDocument(
          'users',
          user.uid,
          {
            disabled: user.disabled,
          },
          true,
        );
        if (checkBoxes.deleteTasks) {
          await deleteUserTasks();
        }
        if (checkBoxes.deleteOffers) {
          await fetchTasksForOffers();
        }
        toast.success(
          user.disabled
            ? 'User disabled successfully'
            : 'User enabled successfully',
        );

        setCheckBox({ deleteTasks: false, deleteOffers: false });
        setDisableDialog(false);
        setLoading(false);
      })
      .catch((err) => {
        setCheckBox({ deleteTasks: false, deleteOffers: false });
        setDisableDialog(false);
        setLoading(false);
        toast.error('Could not modify the user, please try later');
      });
  };
  const handleChangeIndicator = (event, newValue) => {
    setTab(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  };
  const renderTabs = () => {
    const tabs = [
      'Profile',
      'Badges',
      'Tasks',
      'Open Bids & Offers',
      'Comments',
      'Replies',
      "Charge User"
    ];
    return (
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        classes={{
          root: classes.rootTabContainer,
          indicator: classes.indicatorTab,
        }}
        TabIndicatorProps={{ children: <div /> }}
        value={tab}
        onChange={handleChangeIndicator}
        aria-label="tabs"
      >
        {tabs.map((tabName, index) => {
          return (
            <Tab
            key={index}
              {...a11yProps(index)}
              disableRipple
              classes={{ root: classes.rootTab }}
              label={(
                <Typography variant="subtitle2">
                  {tabName}
                </Typography>
              )}
            />
          );
        })}
      </Tabs>
    );
  };

  return (
    <div>
      <Dialog
        open={opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        disableBackdropClick
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              width: '100%',
            }}
          >
            <Grid item md={12}>
              {user.profilePicture && user.profilePicture != '' ? (
                <Avatar
                  className={classes.large}
                  alt={user.firstName}
                  src={user.profilePicture}
                />
              ) : (
                <Avatar className={classes.large}>
                  {user.firstName && user.firstName.substr(0, 1)}
                  {user.lastName && user.lastName.substr(0, 1)}
                </Avatar>
              )}
            </Grid>
            <br />
            <Grid item md={12}>
              <Typography variant="h1" component="h1">
                {user.firstName}
                {' '}
                {user.lastName}
              </Typography>
            </Grid>
            <br />
            <Grid item md={12}>
              {!user.disabled
                ? (
                  <Button
                    variant="outlined"
                    disabled={loading}
                    onClick={() => setDisableDialog(true)}
                  >
                    Disable user
                  </Button>
                )
                : (
                  <Button
                    variant="outlined"
                    disabled={loading}
                    onClick={disableUser}
                  >
                    Enable user
                  </Button>
                )}
            </Grid>

          </Grid>
          <Grid item md={12}>
            {renderTabs()}

            { tab === 0 && (
              <ProfileTab />
            )}
            {user && tab === 1 && (
              <BadgesTab user={user} setLoading={setLoading} />
            )}
            {user && tab === 2 && <TaskTabs user={user} />}
            {user && tab === 3 && <OffersTab user={user} />}
            {user && tab === 4 && <CommentsTab user={user} />}
            {user && tab === 5 && <RepliesTab user={user} />}
            {user && privateData && tab === 6 && (
              <ChargeUserTab closeDialog={handleClose} user={user} privateData={privateData} />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={handleClose}
            color="primary"
          >
            {!loading ? 'Close' : <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={disableDialog} onClose={() => setDisableDialog(false)} maxWidth="sm">
        <DialogTitle>Disable User</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checkBoxes.deleteTasks}
                  onChange={handleChange}
                  name="deleteTasks"
                  color="primary"
                />
              )}
              label="Delete Tasks for this user"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checkBoxes.deleteOffers}
                  onChange={handleChange}
                  name="deleteOffers"
                  color="primary"
                />
              )}
              label="Delete Offers for this user"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={() => setDisableDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            autoFocus
            onClick={disableUser}
            color="primary"
          >
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
EditUser.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default EditUser;
