import React from "react";
import Text from '../../views/task/edit task/text';
import { FaPlayCircle, FaPauseCircle, } from "react-icons/all";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

const Bar = ({ duration, curTime, width, isMine, isPlaying, togglePlay, style }) => {
    const curPercentage = (curTime / duration) * 100;
    const formatDuration = (duration) => {
        return moment
            .duration(duration, "seconds")
            .format("mm:ss", { trim: false });
    }
    return (
        <div style={{ background: `linear-gradient(to right, ${isMine ? "rgb(64 52 186)" : "rgb(206 206 206)"} ${curPercentage}%, ${isMine ? "rgb(64 52 186)" : "rgba(224,224,224,0.6)"} 0)`, cursor: "pointer", height: "100%", ...style }}>
            <div className="bar" style={{ width: `fit-content`, display: "flex", flexDirection: "row", justifyContent: "center", }}>
                {!isPlaying ? <FaPlayCircle style={{ marginRight: "0px" }} color={isMine ? "white" : "#8b8989"} onClick={() => togglePlay(true)} size={20} /> : <FaPauseCircle style={{ marginRight: "0px" }} color={isMine ? "white" : "#8b8989"} onClick={() => togglePlay(false)} size={20} />}
                <div style={{ width: `${duration * 3}px`, border: `0.5px ${isMine ? "white" : "#8b8989"} solid`, backgroundColor: isMine ? "white" : "#8b8989", height: "0px", marginTop: "auto", marginBottom: "auto" }} />
                <Text variant="caption" style={{ width: "fit-content", color: isMine ? "black" : "white", marginLeft: "0px", borderRadius: "10px", padding: "3px", backgroundColor: isMine ? "white" : "#8b8989" }}>{formatDuration(duration - curTime)}</Text>

            </div>
        </div>
    );
}
export default Bar;