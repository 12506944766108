import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import firebaseUtility from '../../utils/firebase.utils';
import SkeletoLoader from '../skeletoLoader';
import OfferCard from '../offerCard';

const OffersTab = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [moreResult, setMoreResult] = useState(true);
  const [taskId, setId] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchTasksForOffers = async () => {
    setLoading(true);
    if (user.uid) {
      const tasksIds = [];
      const d = new Date();
      const today = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());
      const tasksRef = await firebaseUtility.Firestore().collection('tasks')
        .orderBy('dateToStart')
        .where('offerUIDs', 'array-contains', user.uid)
        .where('isAssigned', '==', false)
        .where('isCancelled', '==', false)
        .where('isComplete', '==', false)
        .where('dateToStart', '>', today)
        .startAfter(lastItem)
        .limit(10)
        .get();

      if (tasksRef.empty) {
        setMoreResult(false);
      } else {
        setLastItem(tasksRef.docs[tasksRef.docs.length - 1]);
        tasksRef.docs.forEach((task) => {
          tasksIds.push(task.data().id);
        });

        const result = await fetchOffers(tasksIds);
        const newOffers = result.map((offer) => {
          return {
            offerId: offer.id,
            ...offer.data(),
          };
        });

        setOffers([...offers, ...newOffers]);
      }
    }
    setLoading(false);
  };

  const fetchOffers = (tasksIds) => {
    const reads = [];
    tasksIds.forEach((id) => {
      const promise = firebaseUtility.Firestore()
        .collection('tasks').doc(id)
        .collection('offers')
        .doc(user.uid)
        .get();
      reads.push(promise);
    });
    return Promise.all(reads);
  };

  useEffect(() => {
    fetchTasksForOffers();
  }, []);

  const promptCancel = (taskId,offer) => {
    setId(taskId);
    setLoading(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const deleteOffer = async () => {
    setLoading(true);
    try {
      await firebaseUtility.Firestore()
        .collection('tasks').doc(taskId)
        .collection('offers')
        .doc(user.uid)
        .delete();

      const allOffers = offers.filter((offer) => {
        return offer.taskId !== taskId;
      });

      setOffers([...allOffers]);
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
    setLoading(false);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="tasks" />;
  return (
    <div>
      <Box mt={3}>
        <Grid container spacing={3}>
          {loading
            ? getLoadingSkeleton()
            : offers.map((offer) => (
              <Grid item key={`${offer.offerId}`} lg={6} md={6} xs={12}>
                <OfferCard
                  promptCancel={promptCancel}
                  offer={offer}
                />
              </Grid>
            ))}
        </Grid>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          {moreResult
            ? (
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => fetchTasksForOffers()}
              >
                {loading ? 'Loading ...' : 'Load more'}
              </Button>
            )
            : <Typography variant="body2"> No More Results!</Typography>}
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Offer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this offer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteOffer} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OffersTab;
