import React, { MouseEventHandler, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import Text from "../text";
import useTheme from "@material-ui/core/styles/useTheme";
import { PopperPlacementType } from "@material-ui/core/Popper/Popper";


const useStyles = makeStyles((theme) => ({
    item: {
        padding: "10px 14px",
        background: "white",
        borderRadius: "7px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "#f5f6f7"
        }
    },
    errorItem: {
        padding: "10px 14px",
        background: "white",
        borderRadius: "7px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "rgb(255,248,248)"
        }
    }
}));

const MenuListItem = ({ children, icon, onClick, error, style, large, active, disableAutomaticClose = false, onClose }) => {

    const classes = useStyles();
    const theme = useTheme();


    return (
        <div className={error ? classes.errorItem : classes.item}
            onClick={() => {
                if (onClick)
                    onClick(null);
                if (!disableAutomaticClose && onClose)
                    onClose(null);
            }}
            style={{ padding: large ? "14px 16px" : "", background: active ? "rgba(247, 248, 251,1)" : null, boxShadow: active ? "inset rgba(0, 0, 0, 0.05) 0px 0px 2px 1px" : null, ...style }}>
            {
                icon &&
                <div style={{ marginRight: "18px", color: !active ? (error ? "rgb(198,53,89)" : "rgb(101, 119, 134)") : theme.palette.primary.main, transform: "translateY(1px)" }}>
                    {icon}
                </div>
            }
            {
                typeof children === "string" ?
                    <Text variant={"body2"} style={{ color: !active ? (error ? "rgb(198,53,89)" : "rgb(20,20,20)") : theme.palette.primary.main }}>
                        {children}
                    </Text>
                    :
                    children
            }
        </div>
    )
}

export default MenuListItem;