import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  Checkbox,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useNavigate } from 'react-router-dom';
import ReportTab from './reportTab';


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dialogRoot: { height: '90vh' },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '3em',
  },
  rootTabContainer: {
    transform: 'translateY(4px)',
    background: 'white',
    padding: '0 24px',
    borderRadius: '6px',
  },
  rootTab: {
    textTransform: 'none',
    padding: '0',
    minWidth: 'unset',
    justifyContent: 'flex-start !important',
    fontSize: theme.typography.pxToRem(15),
    marginRight: '20px',
  },
  indicatorTab: {
    display: 'flex',
    height: '4px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },

  },
}));

// eslint-disable-next-line react/prop-types,no-unused-vars
const ContactDetails = ({
  opened, closeDialog, contact, ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();


 
  const handleClose = () => {
    if (closeDialog) {
      closeDialog(contact);
    }
  };

  const handleChangeIndicator = (event, newValue) => {
    setTab(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  };
  const renderTabs = () => {
    const tabs = [
      'Report Details',
    ];
    return (
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        classes={{
          root: classes.rootTabContainer,
          indicator: classes.indicatorTab,
        }}
        TabIndicatorProps={{ children: <div /> }}
        value={tab}
        onChange={handleChangeIndicator}
        aria-label="tabs"
      >
        {tabs.map((tabName, index) => {
          return (
            <Tab
              {...a11yProps(index)}
              disableRipple
              classes={{ root: classes.rootTab }}
              label={(
                <Typography variant="subtitle2">
                  {tabName}
                </Typography>
              )}
            />
          );
        })}
      </Tabs>
    );
  };

  return (
    <div>
      <Dialog
        open={opened}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        disableBackdropClick
        maxWidth="md"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              width: '100%',
            }}
          >
            <div style={{height:"40px"}}/>
            <br />
            <Grid item md={12}>
            <Typography variant="h3" component="h3">
              From: {contact.name}
              </Typography>
            </Grid>
            <br />
            <Grid item md={12}>
            <a href={`mailto:${contact.email}`}>
                  <Button
                    variant="outlined"
                   >
                      Contact The Reporter
                  </Button>
                  </a>
            </Grid>


          </Grid>
          <Grid item md={12}>
            {renderTabs()}

            { tab === 0&& (
              <ReportTab contact={contact}  />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={handleClose}
            color="primary"
          >
            {!loading ? 'Close' : <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
ContactDetails.propTypes = {
  opened: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired
};

export default ContactDetails;
