import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import Text from "../../text";
import {Link} from "react-router-dom";
import ButtonBase, {ButtonBaseProps} from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTheme from "@material-ui/core/styles/useTheme";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    primary: {
        flexShrink: 0,
        position: "relative",
        display: "inline-flex",
        background: `rgba(78, 62, 253, 1.0)`,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius:"100px",
        padding: "6px 16px",
        border: "none",
        boxShadow: "none",
        color: "white",
        transition: "0.3s"
    },
    hover: {
        filter: "brightness(1.35)"
    },
    focus: {
        filter: "brightness(0.95)",
    },
    iconSize: {
        width: "16px",
        height: "16px"
    },
    loading: {
        color: "white"
    },
    disabled: {
        background: `grey`,
    }
}));


function BaseButton (
    {
        onClick,
        children,
        type="button",
        disabled,
        isLoading,
        style,
        key,
        icon,
        component
    } ) {

    const [pressed, setPressed] = useState(false);
    const [hover, setHover] = useState(false);

    const classes = useStyles();
    const theme = useTheme();

    return <ButtonBase
        component={component}
        key={key}
        disableRipple
        disableTouchRipple
        type={type}
        style={style}
        disabled={disabled  || isLoading}
        className={classNames(
            {[classes.primary] : true},
            {[classes.hover] : hover && !pressed},
            {[classes.focus] : pressed || isLoading},
            {[classes.disabled] : disabled},
        )}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        onMouseUp={() => setPressed(false)}
        onMouseDown={() => setPressed(true)}
        onClick={onClick ?? null}
        onMouseLeave={() => setPressed(false)}
        onTouchStart={() => setPressed(true)}
        onTouchEnd={() => setPressed(false)}
        onTouchCancel={() => setPressed(false)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
    >
        <span style={{ opacity:  (isLoading) ? "0.0" : "1.0", transform: `translateY(${(isLoading) ? "8px" : "0"})`, display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}>
            {
                icon &&
                <span style={{ marginRight: "12px", marginTop: "2px" }}> {icon}</span>
            }
            <Text component={"span"} selectable={false} style={{ color: theme.palette.background.paper, opacity:  (disabled) ? "0.5" : "1.0", wordBreak: "unset" }} bold variant={"caption"}>{children}</Text>
        </span>

        {
            component!=="span" &&
                <div style={{ position: "absolute", width: "12px", height: "12px", opacity: isLoading ? 1 : 0,
                    transform: `translateY(${(isLoading) ? "0px" : "-8px"})`,  transition: "0.4s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}>
                    <CircularProgress classes={{ colorPrimary: classes.loading }} size={12} thickness={6}/>
                </div>
        }
    </ButtonBase>
}

function MiniButton (props) {

    const base = <BaseButton {...props}/>;

    return ( props.to ?
            (props.to.includes("http") ?
                    <a href={props.to} rel="noopener noreferrer" target={"_blank"}>
                        {base}
                    </a>
                    :
                    <Link to={props.to}>
                        {base}
                    </Link>
            )
            :
            base
    )
}

export default MiniButton;