import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import Toolbar from './toolbar';
import Results from './results';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';
 
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Verifications = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(6);
  const [disableSearch, setDisable] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [text, setText] = useState('');
  const [hasMore, setMoreResult] = useState(true)


  const fetchUsers = async (status) => {
    setLoading(true);
    let ref = firebaseUtility.Firestore().collection('users');
    if (status === 'pending' || status === 'rejected'||status==='verified') {
      setFilterStatus(status);
      ref = ref.where('verifiedStatus', '==', status);
    }
     const result = await ref.limit(6).get();

    const mappedItems = result.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });

    if (result.docs.length < 6) setMoreResult(false);
    setUsers([...mappedItems]);
    setLastItem(result.docs[result.docs.length - 1]);
    setLoading(false);
  };


  const fetchMoreUsers = async (status) => {
    setLoading(true);
    let ref = firebaseUtility.Firestore().collection('users');
    if (status === 'pending' || status === 'rejected'||status==='verified') {
      setFilterStatus(status);
      ref = ref.where('verifiedStatus', '==', status);
      
    }
    
    const result = await ref
      .startAfter(lastItem)
      .limit(6)
      .get();
      
    const mappedItems = result.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });

    if (result.docs.length < 6) setMoreResult(false);
    setUsers([users,...mappedItems]);
    setLastItem(result.docs[result.docs.length - 1]);
    setLoading(false);
  };

  const filterData = (status) => {
    setUsers([]);
    setLastItem(null);
     setPage(0);
    setFilter(status);
    setMoreResult(true);
      fetchUsers(status);
  };


  const filterUsers = (status) => {
    setUsers([]);
    setLastItem(null);
    setPage(page);
    setFilter(status);
    setMoreResult(true);
      fetchUsers(status);
  };



  const handleChangePage = async newPage => {
    setPage(newPage);
    if (newPage > page) {
        await fetchMoreUsers(filter);
      }
     else{
      filterData(filter)
     } 
  };

  
  

 
  return (
    <Page className={classes.root} title="Verifications">
      <ToastContainer closeOnClick hideProgressBar={false} />
      <Container maxWidth={false}>
        <Toolbar
          isLoading={loading}
          // fetchUsers={fetchUsers}
          filterData={filterData}
          fetchMoreUsers={fetchMoreUsers}
        />
        <Box mt={3}>
          <Results
            pageIndex={page}
            users={users}
            isLoading={loading}
            pageChanged={handleChangePage}
            count={hasMore ? -1 : users.length}
            pageSizeIndex={6}
            fetchUsers={fetchUsers}
          />
        </Box>
      </Container>
    </Page>
  );
};
 
export default Verifications;
