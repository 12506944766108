import React, { useEffect, useState } from 'react';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import { Check} from "react-feather";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Text from './edit task/text';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop';
import CloseTaskDialog from './closeTaskDialog';
import QuestionsOffers from './QuestionsOffers/index';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';
import StatusIndicator from '../../components/statusIndicator';
import EditTask from './edit task/edit-task-dialog';
import DialogWrapper from "./edit task/components/dialogs/dialog-wrapper";
import AttachmentHandler from './edit task/components/attachment-handler';
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    card: {
      padding: '1.5rem',
      overflow: 'visible'
      // maxWidth: '700px'
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    taskTitleAndOwnerWrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      height: '5rem',
      justifyContent: 'space-between',
      maxWidth: '600px',
      minWidth: '300px'
    },
    taskOwner: {
      display: 'flex',
      width: '10rem',
      justifyContent: 'space-between',
      marginTop: '1.4rem'
    },
    taskBudgetStatus: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '6rem'
    },
    dateLocationWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    contentDivider: {
      marginTop: '1rem',
      marginBottom: '1rem'
    },

    taskOwnerContent: {
      // display:
    },
    button: {
      margin: theme.spacing(1)
    },
    actions: {
      display: 'flex',
      flexDirection: 'column',
      width: '90%',
      justifyContent: 'center'
    },
    actionsCard: {
      padding: theme.spacing(3),
      margin: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1
    }
  })
);

const showTimingString = timesArr => {
  let timingStr = '';
  for (let i = 0; i < timesArr.length; ++i) {
    if(timesArr.length == 4){ timingStr = 'Anytime'; }

    else if(timesArr.length == 1) timingStr=timesArr
    
    else if (i === timesArr.length - 1) {
      timingStr = timingStr.slice(0, -2);
      timingStr += ` or ${timesArr[i]}`;
    } else timingStr += `${timesArr[i]}, `;
  }
  return timingStr;
};

const TaskView = () => {
  const { id, page, msg_id } = useParams();
  const [task, setTask] = useState();
  const [questions, setQuestions] = useState([]);
  const [offers, setOffers] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState(true);

  const [reasonText, setReasonText] = useState('');
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const isExpired = () => {
    const date = task.dateToStart
      ? typeof task.dateToStart === 'string'
        ? new Date(task.dateToStart)
        : task.dateToStart instanceof Date
          ? task.dateToStart
          : new Date(task.dateToStart.toDate().toString())
      : null;

    return (
      !task.isCancelled &&
      !task.isAssigned &&
      !task.isComplete &&
      date < new Date()
    );
  };

  useEffect(() => {
    (async () => {
      const doc = await firebaseUtility.GetOneDocById('tasks', id);
      const questionsRef = firebaseUtility
        .CollectionRef('tasks')
        .doc(id)
        .collection('comments')
        .orderBy('createAt');
      const questionsDocs = await firebaseUtility.GetAllDocumentsByRef(
        questionsRef
      );
      const questionsWithReplies = [];
      for (let i = 0; i < questionsDocs.length; i++) {
        const _comment = questionsDocs[i];
        if (_comment.replies > 0) {
          const repliesRef = firebaseUtility
            .CollectionRef('tasks')
            .doc(id)
            .collection('comments')
            .doc(_comment.id)
            .collection('replies')
            .orderBy('createAt');
          const replies = await firebaseUtility.GetAllDocumentsByRef(
            repliesRef
          );
          _comment.repliesList = replies;
        }
        questionsWithReplies.push(_comment);
        if (i + 1 == questionsDocs.length) {
          setQuestions(questionsWithReplies);
        }
      }
      const offersDocs = firebaseUtility
        .CollectionRef('tasks')
        .doc(id)
        .collection('offers')
        .orderBy('createAt');
      const offersInfo = await firebaseUtility.GetAllDocumentsByRef(offersDocs);
      setOffers(offersInfo);

      const { isAssigned, isCancelled } = doc;

      let taskStatus;

      if (isAssigned) taskStatus = 'Assigned';
      else if (isCancelled) taskStatus = 'Cancelled';
      else taskStatus = 'Completed';
      const date = new Date(doc.createAtUnix * 1000);
      const startDate = new Date(doc.dateToStart.seconds * 1000);
     
      setTask({
        ...doc,
        status: taskStatus,
        dateToStart:startDate,
        createdAt: date.toLocaleDateString()
      });
    })();
    setTaskLoading(false);
  }, [task]);

  const cancelTask = async () => {
    setLoading(true);
    try {
      await firebaseUtility.setDocument(
        'tasks',
        id,
        { isCancelled: true, reason: reasonText },
        true
      );
      // toast.success('task closed successfully');
      setOpen(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setOpen(false);
    }
  };

  const promptCancel = () => {
    // setLoading(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenTaskDialog(false);
  };

  const handleClickOpen = () => {
    setOpenTaskDialog(true);
  };


  const updateItems = (items, type) => {
    if (type === 'comments') setQuestions([...items]);
    else if (type === 'offers') setOffers([...items]);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={taskLoading}>
        <CircularProgress />
      </Backdrop>
      <Page className={classes.root} title="Tasks">
        <ToastContainer closeOnClick hideProgressBar={false} />

        <Container maxWidth={false}>
          {task && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item md={12}>
                <IconButton onClick={() => navigate('/app/tasks')}>
                  <ArrowBackIcon color="primary" />
                </IconButton>
              </Grid>
              <Grid item md={8}>
                <Card className={classes.card}>
                  <div className={classes.cardHeader}>
                    <div className={classes.taskTitleAndOwnerWrapper}>
                      <Typography
                        variant="h4"
                        component="h2"
                        style={{
                          wordBreak: 'break-word',
                          width: '100%',
                          whiteSpace: 'nowrap',                       
                          position: 'absolute'
                        }}
                      >
                        {task.title}
                      </Typography>
                      <br />
                      <div className={classes.taskOwner}>
                        <Avatar
                          src={
                            task.taskOwner ? task.taskOwner.profilePicture : ''
                          }
                        >
                          {task.taskOwner && !task.taskOwner.profilePicture
                            ? task.taskOwner.name.slice(0, 1)
                            : null}
                        </Avatar>
                        <div className={classes.taskOwnerContent}>
                          <Typography variant="body1" component="p">
                            {task.taskOwner ? task.taskOwner.name : ''}
                          </Typography>
                          <Typography variant="caption" component="p">
                            {task.createdAt}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.taskBudgetStatus}>
                      <Typography variant="body1" component="p">
                        Task Budget
                      </Typography>
                      <Typography variant="body1" component="p">
                        {`$${task.budget}`}
                      </Typography>
                      <StatusIndicator task={task} />
                    </div>
                  </div>
                  {task?.attachments?.length > 0 ? (
                            <div style={{ margin: "12px 0",width:"400px" }}>
                                <AttachmentHandler maxHeight="250px" images={task?.attachments} />
                            </div>
                        ) : null}
                  {params["tab"] === "edit" && <DialogWrapper
                    open={params["tab"] === "edit" ? true : false}
                    onClose={() => { navigate(`/app/tasks/${task.id}`); }}
                    root={true}
                  >
                    <EditTask onTaskChange={setTask} task={task} onClose={() => { navigate(`/app/tasks/${task.id}`); }} path={'tasks'} />

                  </DialogWrapper>}
                  <CardContent>
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      startIcon={loading ? null : <BlockIcon />}
                      onClick={() => promptCancel()}
                    >
                      {!loading ? 'Disable Task' : <CircularProgress />}
                    </Button>
                    {!task.isAssigned &&
                      !task.isComplete &&
                      !task.isCancelled &&
                      !isExpired() ? (
                      <>
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          startIcon={loading ? null : <BlockIcon />}
                          onClick={() => promptCancel()}
                        >
                          {!loading ? 'Cancel Task' : <CircularProgress />}
                        </Button>
                      </>
                    ) : null}
                    <Link
                      variant="contained"
                      color="default"
                      className={classes.button}
                      to={`/app/tasks/${task.id}/edit`}>
                      <EditIcon /> Edit Task
                    </Link>
                    <Divider className={classes.contentDivider} />
                    <div className={classes.dateLocationWrapper}>
                      <div className={classes.dateWrapper}>
                        <Typography variant="body2" component="p">
                          Due Date
                        </Typography>
                        <Typography variant="body1" component="p">
                          {moment(task.dateToStart).format(
                            'MMM DD, YYYY'
                          )}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {showTimingString(task.timings)}
                        </Typography>
                      </div>
                      <div className={classes.locationWrapper}>
                        <Typography variant="body1" component="p">
                          Location
                        </Typography>
                        <Typography variant="body1" component="p">
                          {task.isRemote ? 'Remote' : task.address.city}
                        </Typography>
                      </div>
                    </div>
                    <Divider className={classes.contentDivider} />
                    <Typography variant="body1" component="p">
                      Description
                    </Typography>
                    <Typography
                      variant="body1"
                      component="p"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {task.description}
                    </Typography>
                    <Divider className={classes.contentDivider} />
                    <Typography variant="body1" component="p">
                      Requirements
                    </Typography>
                    {task.requirements.map((req, index) => (
                                <div style={{ height: "auto", padding: "4px 10px" }} key={index}>
                                    <Check size={18} style={{ color: "#4caf50", verticalAlign: "middle", marginRight: "8px" }} />
                                    <Text variant={"body2"} regular component={"span"} style={{ textTransform: "capitalize" }}>
                                        {req}
                                    </Text>
                                </div>
                            ))}
                  <Divider className={classes.contentDivider} />

                    {questions.length > 0 || offers.length > 0 ? (
                      <QuestionsOffers
                        questions={questions}
                        offers={offers}
                        updateItems={updateItems}
                      />
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
        <CloseTaskDialog
          opened={open}
          closeDialog={() => {
            setOpen(!open);
            setLoading(false);
          }}
          text={reasonText}
          setText={setReasonText}
          handleCancel={cancelTask}
        />
      </Page>
    </>
  );
};

export default TaskView;
