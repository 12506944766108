import React from 'react';
import moment from 'moment';
import { Calendar, Globe, MapPin, Trash2 } from 'react-feather';
import { FaRegHandPointUp, FaRegComments } from 'react-icons/fa';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemIcon } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import StatusIndicator from './statusIndicator';
import MenuWrapper from './menuWrapper';
import firebaseUtility from '../utils/firebase.utils';

const useStyles = makeStyles(() => ({
  taskCard: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    borderRadius: '6px',
    cursor: 'pointer',
    boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
    transition: '0.3s',
    border: '1.5px solid rgb(245,245,245)',
    '&:hover': {
      boxShadow: '0 10px 20px -14px rgba(50,50,50,0.3)'
    }
  }
}));

const TaskCard = ({ task, promptCancel, fromDispute = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleOpenTask = taskData => {
    if (fromDispute) {
      navigate(
        `/app/disputes/${taskData.id}/messaging/${taskData.id}_${taskData.assignedWorker.uid}`
      );
    } else navigate(`/app/tasks/${taskData.id}`);
  };

  return (
    <div
      className={classNames({
        [classes.taskCard]: true
      })}
    >
      <div
        style={{ display: 'flex', padding: '14px 16px', paddingBottom: '0px' }}
      >
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          onClick={() => handleOpenTask(task)}
        >
          <Typography
            variant="overline"
            style={{ color: 'grey', lineHeight: 1 }}
          >
            {task.category ?? 'Others'}
          </Typography>
          <div style={{ height: '4px' }} />

          <Typography
            variant="body2"
            style={{
              lineHeight: 1,
              overflowWrap: 'anyWhere'
            }}
            bold
          >
            {task.title}
          </Typography>

          <div style={{ height: '8px' }} />
          {task.isRemote ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Globe color="#707070" size={10} />
              <div style={{ width: '12px' }} />
              <Typography variant="caption" style={{ color: 'grey' }} medium>
                Remote
              </Typography>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MapPin color="#707070" size={10} />
              <div style={{ width: '12px' }} />
              <Typography variant="caption" style={{ color: 'grey' }} medium>
                {task.city}
              </Typography>
            </div>
          )}
          <div style={{ height: '4px' }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Calendar color="#707070" size={10} />
            <div style={{ width: '14px' }} />
            <Typography variant="caption" style={{ color: 'grey' }} medium>
              {moment(
                typeof task?.createAt === 'string'
                  ? new Date(task.createAt)
                  : new Date(task.createAt.toDate().toString())
              ).format('dddd, MMM DD')}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            marginLeft: '24px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" bold style={{ lineHeight: '1' }}>
              ${task.budget}
            </Typography>
            { !task.isDispute?
            <MenuWrapper
              items={
                <div id="menu">
                  <MenuItem
                    onClick={() => promptCancel(task.id)}
                    disabled={
                      !(
                        !task.isAssigned &&
                        !task.isComplete &&
                        !task.isCancelled &&
                        !task.isExpired
                      )
                    }
                  >
                    <ListItemIcon>
                      <Trash2 style={{ color: 'inherit' }} size={16} />
                    </ListItemIcon>
                    Cancel Task
                  </MenuItem>
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  padding: '18px 12px',
                  right: '4px',
                  top: '4px'
                }}
              >
                <div
                  style={{
                    margin: '0 2px',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    background: 'rgb(200,200,200)'
                  }}
                />
                <div
                  style={{
                    margin: '0 2px',
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    background: 'rgb(200,200,200)'
                  }}
                />
              </div>
            </MenuWrapper> : null}
          </div>
          <StatusIndicator task={task} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '12px 16px',
          paddingTop: '0px'
        }}
      >
        <FaRegComments
          color="#707070"
          style={{ width: '12px', height: '12px' }}
        />
        <div style={{ width: '8px' }} />
        <Typography variant="caption" style={{ color: 'grey' }} medium>
          {task.comments.toString()}
        </Typography>
        <div style={{ width: '16px' }} />
        <FaRegHandPointUp
          color="#707070"
          style={{ width: '12px', height: '12px' }}
        />
        <div style={{ width: '8px' }} />
        <Typography variant="caption" style={{ color: 'grey' }} medium>
          {task.offers.toString()}
        </Typography>
      </div>
    </div>
  );
};

export default TaskCard;
