import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon, Select, MenuItem, InputBase,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';

const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderLeft: '2px solid #ced4da',
    fontSize: 14,
    padding: '18px 26px 18px 12px',
    '&:focus': {
      backgroundColor: 'white'
    }
  },
}))(InputBase);

// eslint-disable-next-line react/prop-types
const Toolbar = ({
  className, searchUsersMethod, isLoading, ...rest
}) => {
  const [text, setText] = useState('');
  const [searchType, setSearchType] = useState('reporterUid');

  const renderMenu = () => {
    return (
      <FormControl>
        <Select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          input={<CustomInput />}
        >
          <MenuItem value="reporterUid">Reporter UID</MenuItem>
          <MenuItem value="reportedUser">Reported UID</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <div className={clsx(className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                disabled={!!isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {renderMenu()}
                    </InputAdornment>
                  )
                }}
                onChange={(e) => {
                  setText(e.target.value);
                  if (e.target.value === '') {
                    searchUsersMethod('');
                  }
                }}
                onKeyPress={async (e) => {
                  const code = e.keyCode ? e.keyCode : e.which;
                  // on press enter
                  if (code == 13) {
                    e.preventDefault();
                    // search

                    await searchUsersMethod(text, searchType);
                  }
                }}
                placeholder="Search reports..."
                variant="outlined"
              />
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                component="p"
                style={{
                  color: '#c3c3c3'
                }}
              >
                Please press Enter to search
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  searchUsersMethod: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

export default Toolbar;
