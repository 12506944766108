import React, {useCallback, useEffect, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Grid, InputAdornment, Button} from '@material-ui/core';
import FormInput from '../task/edit task/components/input-fields/form-input';
import firebaseUtility from '../../utils/firebase.utils';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import firebase from 'firebase';
import { baseUrl } from '../../utils/constant.urls';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) =>
    createStyles({
      tableHeader: {
        backgroundColor: theme.palette.primary.main,
      },
      tableHeaderText: {
        color: theme.palette.primary.contrastText,
      },
      Typography: {
        marginTop: '30px',
      },
      table: {
        Width: 400,
      },
      Button: {
        width: '200px',
        height: '55px',
        marginTop:"35px"
      },
      rounded: {
        borderRadius: '15px',
      }
    }),
);

const Admin = () => {
  const classes = useStyles();
  const [admin, setAdmin] = useState('');
  const [uid, setUid] = useState('');
  const [ad, setAd] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const navigate = useNavigate();
  let CUser = firebase.auth().currentUser;
  useEffect(() => {
    (async () => {

      const adminData = await fetchAdmin();
      setAdmin(adminData);
    })();
  }, []);

  const openEditUserDialog = (user) => {
    navigate(`/app/users/${user}`);
  };

  const fetchAdmin = useCallback(async () => {
    const adminRef = await firebaseUtility.Firestore()
        .collection('users')
        .where('isAdmin', '==', true)
        .get();
    return adminRef.docs.map(doc => doc.data());
  }, []);


  const handleAddAdmin = () => {
    setIsSubmitting(true);
    if (!uid) {
      setIsSubmitting(false);
      toast.error('Please fill in all the required fields');
    }

    if(uid){
    axios.post(`${baseUrl}/setAsAdmin`, {
      userId: uid,
    })
        .then((response)=>{
          toast.success(response.data.message);
        })
        .then(async () => {
          const adminData = await fetchAdmin();
          setUid('');
          setAdmin(adminData);
          setIsSubmitting(false);
        })
        .catch(function() {
          toast.error('Something went wrong');
          setIsSubmitting(false);
          setUid('');

        });
      }
  };
  const handleClickOpenDelete = (A) => {
    setOpenDelete(true);
    setAd(A);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDeleteAdmin = (id) => {
    axios.post(`${baseUrl}/setAsAdmin`, {
      userId: id,
    })
        .then(async () => {
          const adminData = await fetchAdmin();
          setAdmin(adminData);
          setOpenDelete(false);
        })
        .catch(function(error) {
          console.log(error);
        });
  };

  return (
      <div>
        <br/>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <FormInput
                fullWidth
                variant="filled"
                name="uid"
                label="UID"
                value={uid}
                onChange={(e) => setUid(e.target.value)}
                type="text"
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        <IconButton

                        >
                        </IconButton>
                      </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
                className={classes.Button}
                disabled={isSubmitting}
                size="small"
                variant="contained"
                onClick={handleAddAdmin}
                color="primary"
            >
              Add Admin
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h3" className={classes.Typography}>
          Viewing all Admins
        </Typography>
        <br/>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell className={classes.tableHeaderText} align="center">
                  No.
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Admin Name 
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Admin Email 
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Admin UID
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Admin Profile
                </TableCell>
                <TableCell className={classes.tableHeaderText} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admin && admin.map((A, index) => (

                  <TableRow key={A.uid}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="left"> {A.firstName}{" "}{A.lastName}</TableCell>
                    <TableCell align="left"> {A.email}</TableCell>
                    <TableCell align="left"> {A.uid}</TableCell>
                    <TableCell>
                     <Button
                            variant="outlined"
                            className={classes.rounded}
                            onClick={() => openEditUserDialog(A.uid)}
                          > 
                            View
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      {A.userId !== CUser?.uid ?
                          <IconButton onClick={() => {
                            handleClickOpenDelete(A.uid);
                          }}
                          >
                            <DeleteIcon/>
                            <Dialog
                                open={openDelete}
                                onClose={handleCloseDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                              <DialogContent>
                                <DialogContentText
                                    id="alert-dialog-description">
                                  Are you sure you want to delete this admin
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={()=>handleDeleteAdmin(A.uid)}
                                        color="primary">
                                  yes
                                </Button>
                                <Button onClick={handleCloseDelete}
                                        color="primary" autoFocus>
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </IconButton>
                          :
                          null
                      }

                    </TableCell>
                  </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </div>

  );
};
export default Admin;