import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ListItemIcon } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Trash2, ArrowRightCircle, MessageCircle } from 'react-feather';
import ReadMore from './readMore';
import Avatar from './avatar';
import MenuWrapper from './menuWrapper';
import OfferChat from './offerChat/offerChat';
import StarRating from '../views/task/edit task/components/reviews-ratings/star-rating';

const useStyles = makeStyles(theme => ({
  offerCard: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    borderRadius: '6px',
    padding: '14px 16px',
    paddingBottom: '0',
    border: '1.5px solid rgb(240,240,240)',
    boxShadow: '0 0 30px -16px rgba(50,50,50,0.05)',
    marginBottom: '12px',
    transition: '0.3s',
    '&:hover': {
      background: theme.palette.background.default
    }
  },
  replyButton: {
    marginLeft: '12px',
    transition: '0.3s',
    borderRadius: '50px',
    cursor: 'pointer',
    padding: '6px 14px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'rgba(78, 62, 253,0.13)'
    }
  }
}));


const OfferCard = ({ offer, promptCancel, insideTask = false }) => {
  const navigate = useNavigate();
  const [openChat, setOpenChat] = useState(false);
  const { msg_id } = useParams();
  const location = useLocation();

  const getDate = seconds => {
    const epoch = new Date(1970, 0, 1);
    epoch.setSeconds(seconds);
    const updatedDate = new Date(epoch);
    return `${updatedDate.getMonth() +
      1}/${updatedDate.getDate()}/${updatedDate.getFullYear()}`;
  };

  const handleOpenTask = id => {
    navigate(`/app/tasks/${id}`);
  };

  const handleOpenChat = id => {
    setOpenChat(true);
  };

  useEffect(() => {
    const loc = location.pathname.split('/');
    if (msg_id && loc[2] === 'tasks') {
      setOpenChat(true);
    }
  }, [msg_id]);

  const classes = useStyles();
  return (
    <div className={classes.offerCard}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Avatar
          name={offer.name}
          profilePicture={offer.profilePicture}
          size={36}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: '12px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography variant="body1">{offer.name}</Typography>
            <div
              style={{
                margin: '0 8px',
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                background: 'grey'
              }}
            />
            <Typography style={{ color: '#646464' }} variant="caption">
              {offer.createAt ? getDate(offer.createAt.seconds) : ''}
            </Typography>
            <div style={{ marginLeft: 'auto' }} />
            <div
              style={{ display: 'flex', alignItems: 'center'}}
            >
              <Typography variant="body1">${offer.budget}</Typography>
               
              <MenuWrapper
                style={{ padding: '0', marginLeft: '10px'}}

                items={
                  <div id="menu">
                    <MenuItem
                      onClick={() => promptCancel(offer.taskId, offer.uid)}
                    >
                      <ListItemIcon>
                        <Trash2 style={{ color: 'inherit' }} size={16} />
                      </ListItemIcon>
                      Delete Offer
                    </MenuItem>
                    {!insideTask && (
                      <MenuItem onClick={() => handleOpenTask(offer.taskId)}>
                        <ListItemIcon>
                          <ArrowRightCircle
                            style={{ color: 'inherit' }}
                            size={16}
                          />
                        </ListItemIcon>
                        View Task
                      </MenuItem>
                    )}
                    <Link
                      to={`/app/tasks/${offer.taskId}/messaging/${offer.taskId}_${offer.uid}`}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenChat(true);
                        }}

                      >
                        <ListItemIcon>
                          <MessageCircle
                            style={{ color: 'inherit'}}
                            size={16}
                          />
                        </ListItemIcon>
                        Private Messaging
                      </MenuItem>
                    </Link>
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    padding: '18px 12px',
                    right: '4px',
                    top: '4px'
                  }}
                >
                  <div
                    style={{
                      margin: '0 2px',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      background: 'rgb(200,200,200)'
                    }}
                  />
                  <div
                    style={{
                      margin: '0 2px',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      background: 'rgb(200,200,200)'
                    }}
                  />
                </div>
              </MenuWrapper>
            </div>
          </div>
          <StarRating totalAverage={offer.totalAverage} totalCount={offer.totalCount} />
          <Typography
            variant="body2"
            style={{ color: 'black', marginTop: '10px', marginBottom: '8px' }}
          >
            <ReadMore maxLength={120}>{offer.text}</ReadMore>
          </Typography>
        </div>
      </div>
      {openChat && (
        <OfferChat
          opened={openChat}
          closeDialog={() => setOpenChat(false)}
          taskId={offer.taskId}
        />
      )}
    </div>
  );
};

export default OfferCard;
