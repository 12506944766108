import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    loading: {
       color: theme.palette.primary.main
    }
}));

function CircularProgressBar() {
    const classes = useStyles();

    return (
        <CircularProgress classes={{ colorPrimary: classes.loading }} size={18} thickness={8} />
    )
}

export default CircularProgressBar;