
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import SkeletoLoader from '../../components/skeletoLoader';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '15px',
  },
}));

const BlogResults = ({
  className,
  blogs,
  isLoading,
  pageIndex,
  pageSizeIndex,
  setPageSize,
  pageChanged,
  ...rest
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [page, setPage] = useState(pageIndex);
  const [loading] = useState(isLoading);
  
  const handleLimitChange = (event) => {      
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };  

  const handlePageChange = (event, newPage) => {
    setPage(newPage || page);
    pageChanged(newPage || page);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="Blogs"/>;

  
  
  const handleOpenArticle = (id) => {
    navigate(`/app/blogs-list/edit/${id}`);
  };


  const addBlog = () => {
    navigate(`/app/blogs-editor`);
  };
  
  return (
    <>
      <Button
          style={{ height: '40px', fontSize: '11px',marginBottom:'15px' }}
          variant="contained"
          color="primary"
      
          onClick={() => addBlog()}
        >
          ADD A NEW BLOG
        </Button>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                   <TableCell></TableCell>
                  <TableCell></TableCell> 
                </TableRow>
              </TableHead>
              {blogs && blogs?.length > 0 ?
                <TableBody>
                  {!loading
                   ? blogs?.slice(0, limit).map((a) => (
                    <TableRow hover key={a.id} onClick={() => handleOpenArticle(a.id)}>
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Typography color="textPrimary" variant="body1">
                              {a.title} 
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                        
                      </TableRow>
                    ))
                    : getLoadingSkeleton()}
                </TableBody>
                :
                <TableBody >
                  <TableCell colSpan={3}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Typography variant={'body1'}> No Results Found! </Typography>
                  </div>
                  </TableCell>
                </TableBody>
              }
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={-1}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
    </>
  );
};

BlogResults.propTypes = {
  className: PropTypes.string,
  blogs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPageSize: PropTypes.func,
  pageChanged: PropTypes.func,
  pageSizeIndex: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default BlogResults;
