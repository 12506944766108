import React, {
  useEffect,
  useState
} from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';

import Toolbar from './toolbar';
import Results from './results';
import configs from '../../configs';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Reports = () => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [moreResult, setMoreResult] = useState(true);


  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-use-before-define
      await getContacts();
    })();
  }, []);

  const getContacts = async (prev = false, size = pageSize) => {
    setLoading(true);
    let reportsDocs;
    if (prev) {
      reportsDocs = await firebaseUtility.GetPrevPageAllDocuments(
        'contact-us',
        size,
        firstItem,
        'created',
        'desc'
      );
    } else {
      reportsDocs = await firebaseUtility.GetNextPageAllDocuments(
        'contact-us',
        size,
        'created',
        'desc'
      );
    }

    setContacts(reportsDocs);
    setFirstItem(reportsDocs[0]);
    setLastItem(reportsDocs[reportsDocs.length - 1]);
    setLoading(false);
  };


  const searchUsersMethod = async (text, searchType) => {
    if (text === '') {
      await getContacts();
      return;
    }
    setLoading(true);
    const searchKey = searchType == 'name' ? 'name' : 'email';
    firebaseUtility.Firestore().collection('contact-us').where(searchKey, '==', text).get()
      .then(result => {
        setContacts(result.docs.map(i => {
          return {
            ...i.data(),
            id: i.id
          }
        }))
      }).catch(err => {
        console.log(err);
      })
    setLoading(false);
  };


  const handlePageSizeChange = async (size) => {
    setLoading(true);
    if (pageSize != size) {
      setPageSize(size);
      await getContacts(false, size);
    }
    setLoading(false);
  };

  const handlePageChange = async (pageIndex) => {
    setLoading(true);
    if (pageIndex < page) {
      await getContacts(true);
    } else {
      await getContacts(false);
    }
    setPage(pageIndex);
    setLoading(false);
  };

  return ( 
  <Page className = {
      classes.root
    }
    title = "Contact Us" >
    <Container maxWidth = {
      false
    } >
    <Toolbar searchUsersMethod = {
      searchUsersMethod
    }
    isLoading = {
      loading
    }
    /> 
    <Box mt = {
      3
    }>
    <Results pageIndex = {
      page
    }
    contacts = {
      contacts
    }
    isLoading = {
      loading
    }
    pageSizeIndex = {
      pageSize
    }
    setPageSize = {
      (size) => {
        handlePageSizeChange(size);
      }
    }
    pageChanged = {
      (pageIndex) => {
        handlePageChange(pageIndex);
      }
    }
    /> 
    </Box> 
    </Container> 
    </Page>
  );
};

export default Reports;
