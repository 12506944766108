import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SkeletoLoader from '../skeletoLoader';
import TaskCard from '../taskCard';
import firebaseUtility from '../../utils/firebase.utils';
import CloseTaskDialog from '../../views/task/closeTaskDialog';

const TasksTab = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [moreResult, setMoreResult] = useState(true);
  const [filter, setFilter] = useState('');
  const [open, setOpen] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const [taskId, setId] = useState(null);
  const[disable,setDisable]=useState(false)

  const fetchTasks = async () => {
    setLoading(true);
    if (lastItem) {
      const newTasks = await firebaseUtility.Firestore().collection('tasks')
        .orderBy('createAt', 'desc')
        .where('uid', '==', user.uid)
        .startAfter(lastItem)
        .limit(10)
        .get();

      const mappedItems = newTasks.docs.map((d) => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      if (newTasks.docs.length < 10) setMoreResult(false);


      setTasks([...tasks, ...mappedItems]);
      setLastItem(newTasks.docs[newTasks.docs.length - 1]);
      setLoading(false);
    } else {
      const newTasks = await firebaseUtility.Firestore()
        .collection('tasks')
        .orderBy('createAt', 'desc')
        .where('uid', '==', user.uid)
        .limit(10)
        .get();

      const mappedItems = newTasks.docs.map((d) => {
        return {
          id: d.id,
          ...d.data()
        };
      });
      if (newTasks.docs.length < 10) setMoreResult(false);

      setTasks([...tasks, ...mappedItems]);
      setLastItem(newTasks.docs[newTasks.docs.length - 1]);
      setLoading(false);
    }
  };

  useEffect(() => {
     fetchTasks();
  },[]);

  const isExpired = (task) => {
    const date = task.dateToStart ? (typeof task.dateToStart === 'string'
      ? new Date(task.dateToStart)
      : (task.dateToStart instanceof Date ? task.dateToStart : new Date(
        task.dateToStart.toDate().toString()
      ))) : null;

    return !task.isCancelled && !task.isAssigned && !task.isComplete
      && (date < (new Date()));
  };

  const renderFilteredTasks = (task) => {
    let condition = false;
    if (filter === '') {
      condition = true;
    } else if (filter === 'open') {
      condition = !isExpired(task) && !task.isAssigned && !task.isComplete && !task.isCancelled ;
    } else if (filter === 'assigned') {
      condition = task.isAssigned && !task.isComplete && !task.isCancelled;
    } else if (filter === 'completed') {
      condition = task.isComplete && !task.isCancelled;
    } else if (filter === 'cancelled') {
      condition = task.isCancelled;
    } else if(filter ==='expired'){
      condition=isExpired(task);      
    }else if (filter === 'disputed') {
      condition = task.isDispute;
    }

    return (
      condition
      && (
        <Grid item key={`${task.id}`} lg={4} md={6} xs={12}>
          <TaskCard
            promptCancel={promptCancel}
            task={task}
          />
        </Grid>
      )
    );
  };

  const cancelTask = async () => {
    setLoading(true);
    try {
      await firebaseUtility.setDocument(
        'tasks',
        taskId,
        { isCancelled: true, reason: reasonText },
        true,
      );
      // toast.success('task closed successfully');
      const allTasks = tasks.filter((task) => {
        return task.id !== taskId;
      });
      const cancelledTask = tasks.filter((task) => {
        return task.id == taskId;
      });
      cancelledTask[0].isCancelled = true;
      setTasks([...allTasks, ...cancelledTask]);
      setOpen(false);
      setLoading(false);
    } catch (err) {
      console.error('Could not close the task');
      setLoading(false);
      setOpen(false);
    }
  };

  const promptCancel = (taskId) => {
    setId(taskId);
    setLoading(true);
    setOpen(true);
  };
  const getLoadingSkeleton = () => <SkeletoLoader page="tasks" />;
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl variant="outlined" style={{ width: '200px' }}>
          <InputLabel id="status-label">
            Status
          </InputLabel>
          <Select
            fullWidth
            labelId="status-label"
            id="status-label"
            label="Status"
            value={filter}
            onChange={(ev) => {
              setFilter(ev.target.value);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="assigned">Assigned</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
            <MenuItem value="disputed">Disputed</MenuItem>



          </Select>
        </FormControl>
      </div>

      <Box mt={3}>
        <Grid container spacing={3}>
          {loading
            ? getLoadingSkeleton()
            : tasks.map((task) => (
              renderFilteredTasks(task)
            ))}
        </Grid>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          {moreResult
            ? (
              <Button
                disabled={loading}
                variant="outlined" 
                onClick={() => fetchTasks()}
              >
                {loading ? 'Loading ...' : 'Load More'}
              </Button>
            )
            : <Typography variant="body2"> No More Results!</Typography>}
            
        </Box>
      </Box>
      <CloseTaskDialog
        opened={open}
        closeDialog={() => {
          setOpen(!open);
          setLoading(false);
        }}
        text={reasonText}
        setText={setReasonText}
        handleCancel={cancelTask}
      />

    </div>
  );
};

export default TasksTab;
