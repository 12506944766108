import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import typography from '../../theme/typography';
import AttachmentHandler from '../attachment-handler';
import firebaseUtility from '../../utils/firebase.utils';
import { useParams } from 'react-router';
const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 20px',
  },
  divider: {
    height: '30px',
  }, paper: {
    padding: "14px 24px", width: "300px", background: "white", borderRadius: "10px", height: "fit-content", marginBottom: "20px"
  }, badge: {
    background: "white", borderRadius: '50%', width: '20px', height: '20px', border: '2px solid rgb(211,211,211,0.5)', padding: '2px', marginRight: '15px'
  }, flex: {
    display: 'flex'
}
}));

const ProfileTab = () => {
  const classes = useStyles();
  const params = useParams();
  const [privateData, setPrivateData] = React.useState(null);
  const [user,setUser]=React.useState(null);
  // console.log("user:",user)
 
  React.useEffect(() => {
    getUser();
    getPrivateUser();
  }, [params["id"]]);

  const getUser = async () => {
    const userData = await firebaseUtility.GetOneDocById('users', params["id"]);
    setUser(userData);
    console.log('p:',userData.skills)
  };

  
  const getPrivateUser = async () => {
    const data = await firebaseUtility.GetOneDocById('users_stripe', params["id"]);
    setPrivateData(data);
    console.log('p:',privateData)
  };

  return (
  user && <div key={user.uid} className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        <Grid item md={5} xd={12}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              {user.firstName ?
                <Typography variant="body1">
                  Name
               <Typography variant="body2">
                    {user.firstName}
                    {' '}
                    {user.lastName}
                  </Typography>
                </Typography>
                : ''
              }
            </Grid>
            <Grid item md={12} xs={12}>
              {user.email ?
                <Typography variant="body1">
                  Email
               <Typography variant="body2">
                    {user.email}
                  </Typography>
                </Typography>
                : ''
              }
            </Grid>
            <Grid item md={12} xs={12}>
              {user.verifiedStatus == "rejected" ?
                <Typography variant="body1">
                  Rjected Reason
                <Typography variant="body2">
                    {
                      user.rejectReason
                    }
                  </Typography>
                </Typography>
                : ''
              }
            </Grid>
            <Grid item md={12} xs={12}>
              {(user.skills.toString()) ?
                <Typography variant="body1">
                  Education And Skills
               <Typography variant="body2">
                    {user.skills}
                  </Typography> 
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
              {user.service_area ?
                <Typography variant="body1">
                  Service Area
                  <Typography variant="body2">
                    {user.service_area}
                  </Typography>
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
              {user.communicationEmail ?
                <Typography variant="body1">
                 Communication Email
                  <Typography variant="body2">
                    {user.communicationEmail}
                  </Typography>
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
              {user.tagLine ?
                <Typography variant="body1">
                 Tagline
                  <Typography variant="body2">
                    {user.tagLine}
                  </Typography>
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
              {user.about ?
                <Typography variant="body1">
                 About me
                  <Typography variant="body2">
                    {user.about}
                  </Typography>
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
            {user.portfolio.length > 0 && <div className={classes.paper} style={{ height: "200px" }}>
                   <Typography variant="body1">Portfolio</Typography>
                        <AttachmentHandler images={user.portfolio} />
                    </div> 
                        }

            </Grid>

            <Grid item md={12} xs={12}>
            {user.resume && <div className={classes.paper} style={{ height: "200px" }}>
                   <Typography variant="body1">Resume</Typography>
                   <Typography variant='body2'>
                        <a href={user.resume}>Click here to check {user.firstName} resume.</a>
                        </Typography>
                    </div> }
            </Grid>
          </Grid>
        </Grid>
        {privateData &&<Grid item md={5} xd={12}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid item md={12} xs={12}>
              {privateData.birthDate ?
                <Typography variant="caption">
                  Date Of Birth
                <Typography variant="body1">
                    {
                      moment(
                        new Date(
                          `${privateData.birthDate.month.toString()}-${privateData.birthDate.day.toString()}-${privateData.birthDate.year.toString()}`
                        )
                      )
                        .format('MMM DD, YYYY')
                    }
                  </Typography>
                </Typography>
                : ''
              }

            </Grid>
            <Grid item md={12} xs={12}>
              {privateData.phoneNumber ?
                <Typography variant="caption">
                  Phone Number
                <Typography variant="body1">
                    {
                      privateData.phoneNumber
                    }
                  </Typography>
                </Typography>
                : ''
              }
            </Grid>
          </Grid>
        </Grid>}

      </Grid>
    </div>
  );
};

export default ProfileTab;
