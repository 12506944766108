import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(({
  mainParagraph: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  logoTitle: {
    color: '#ffffff'
  },
  logo: {
    width: 50,
    height: 50
  }
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <p className={classes.mainParagraph}>
      <img
        alt="Logo"
        src="/static/images/sydework.3391d046.png"
        className={classes.logo}
        {...props}
      />
      &nbsp;
      &nbsp;
      <Typography className={classes.logoTitle} variant="h3" component="p">Sydetasker</Typography>
    </p>
  );
};

export default Logo;
