import React from 'react';
import { Container } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams } from 'react-router';
import Typography from '@material-ui/core/Typography';
import MessageViewer from './message-viewer';

const useStyles = makeStyles(() => ({
  drawer: {
    padding: '16px 24px',
    width: '360px',
    height: 'calc(100vh - 134px)',
    boxSizing: 'border-box',
    borderRight: '2px solid rgb(240,240,240)'
  },
  content: {
    flex: 1,
    height: 'calc(100vh - 134px)',
    boxSizing: 'border-box',
    width:'100%'
  }
}));

const Messaging = ({ allowSend = false }) => {
  const classes = useStyles();
  const params = useParams();
  return (
    <div style={{
      display: 'flex',
      background: 'white',
      borderRadius: '6px',
      boxShadow: '0 1px 2px rgb(0,0,0,0.1)',
      overflow: 'hidden',
      marginTop:'0px',
    }}
    >

{/*      {allowSend &&
      <div className={classes.drawer}>
        <Typography variant='h6' bold>Messages</Typography>
        <div style={{ height: '24px' }} />
      </div>
      }*/}
      <div className={classes.content}>
        {
          params.msg_id
            ? <MessageViewer allowSend={allowSend} />
            : (
              <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
              >
                <Typography variant='body1' bold>
                  You don’t have a message selected
                </Typography>
                <Typography
                  variant='body2'
                  regular
                  style={{
                    color: 'grey', maxWidth: '350px', textAlign: 'center', marginTop: '8px'
                  }}
                >
                  All messages are private conversations between Taskers & Posters on Sydetasker.
                </Typography>
              </div>
            )
        }
      </div>
    </div>
  );
};

export default Messaging;
