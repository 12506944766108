
import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import BlogResults from './results';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Blogs = () => {
  const classes = useStyles();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [firstItem, setFirstItem] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-use-before-define
      await getBlogs();
    })();
  }, []);

  const getBlogs = async (prev = false, size = pageSize) => {
    setLoading(true);
    let blogsDocs;
    if (prev) {
      blogsDocs = await firebaseUtility.GetPrevPageAllDocuments(
        'blogs',
        size,
        'title'
      );
    } else {
      blogsDocs = await firebaseUtility.GetNextPageAllDocuments(
        'blogs',
        size,
        'title'
      );
    }
    //if (blogsDocs && blogsDocs.length > 0) {
      setBlogs(blogsDocs);
      setFirstItem(blogsDocs[0] ?? null);
      setLastItem(blogsDocs[blogsDocs.length - 1] ?? null);
   // }
    setLoading(false);
  };

  const handlePageSizeChange = async (size) => {
    setLoading(true);
    if (pageSize != size) {
      setPageSize(size);
      await getBlogs(false, size);
    }
    setLoading(false);
  };

  const handlePageChange = async (pageIndex) => {
    setLoading(true);
    if (pageIndex < page) {
      await getBlogs(true);
    } else {
      await getBlogs(false);
    }
    setPage(pageIndex);
    setLoading(false);
  };

  return (
    <Page className={classes.root} title="Blogs">
      <Container maxWidth={false}>
        <Box mt={3}>
          <BlogResults
            pageIndex={page}
            blogs={blogs}
            isLoading={loading}
            pageSizeIndex={pageSize}
            setPageSize={(size) => {
              handlePageSizeChange(size);
            }}
            pageChanged={(pageIndex) => {
              handlePageChange(pageIndex);
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Blogs;
