import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SkeletoLoader from '../skeletoLoader';
import QuestionCard from '../questionCard';
import firebaseUtility from '../../utils/firebase.utils';

const RepliesTab = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [moreResult, setMoreResult] = useState(true);
  const [replyId, setId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchReplies();
  }, []);

  const fetchReplies = async () => {
    setLoading(true);

    const repliesRef = await firebaseUtility.Firestore()
      .collectionGroup('replies')
      .orderBy('createAt')
      .where('uid', '==', user.uid)
      .startAfter(lastItem)
      .limit(10)
      .get();

    if (!repliesRef.empty) {
      if (repliesRef.docs.length < 10) setMoreResult(false);
      const repliesList = [];
      repliesRef.docs.forEach((doc) => {
        repliesList.push({ ...doc.data() });
      });
      setReplies([...replies, ...repliesList]);
      setLastItem(repliesRef.docs[repliesRef.docs.length - 1]);
    } else {
      setMoreResult(false);
    }
    setLoading(false);
  };

  const deleteItem = async (event) => {
    event.preventDefault();
    try {
      const reply = replies.filter((rep) => {
        return rep.id === replyId;
      });
      if (reply[0]) {
        await firebaseUtility.Firestore()
          .collection('tasks')
          .doc(reply[0].taskId)
          .collection('comments')
          .doc(reply[0].commentId)
          .collection('replies')
          .doc(reply[0].id)
          .delete();

        const allReplies = replies.filter((rep) => {
          return rep.id !== replyId;
        });
        setReplies([...allReplies]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpen(false);
  };

  const promptDelete = (commentId) => {
    setId(commentId);
    setLoading(true);
    setOpen(true);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="tasks" />;
  return (
    <div>
      <Box mt={3}>
        <Grid container spacing={3}>
          {loading
            ? getLoadingSkeleton()
            : replies.map((reply) => (
              <Grid item key={`${reply.id}`} lg={6} md={6} xs={12}>
                <QuestionCard
                  promptDelete={promptDelete}
                  question={reply}
                  type={"reply"}
                />
              </Grid>
            ))}
        </Grid>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          {moreResult
            ? (
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => fetchReplies()}
              >
                {loading ? 'Loading ...' : 'Load more'}
              </Button>
            )
            : <Typography variant="body2"> No More Results!</Typography>}
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Reply</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Reply?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteItem} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RepliesTab;
