import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import SkeletoLoader from '../../components/skeletoLoader';
import getCategoryImage from './getCategoryImage';
import StatusIndicator from '../../components/statusIndicator';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  rounded: {
    borderRadius: '15px',
  },
  taskRow: { cursor: 'pointer', padding: ' 20px 0' }
}));

// eslint-disable-next-line max-len
const TasksTable = ({
  className,
  tasks,
  isLoading,
  pageIndex,
  pageChanged,
  count,
  setPageSize,
  pageSizeIndex,


  ...rest
}) => {
  const classes = useStyles();
  const [loading] = useState(isLoading);
  const navigate = useNavigate();
  const [limit, setLimit] = useState(pageSizeIndex);
  const [isloading,setIsLoading]=useState(tasks.length>0 && !isLoading?false:true);


  const handlePageChange = (event, newPage) => {
    pageChanged(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPageSize(event.target.value);
  };


  const getLoadingSkeleton = () => <SkeletoLoader page="users" />;

  const handleOpenTask = (id) => {
    navigate(`/app/tasks/${id}`);
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Category</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Date created</TableCell>
                  <TableCell>Date To Start</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              {tasks && tasks.length > 0
                ? (
                  <TableBody>
                    {!loading
                     // ? tasks.slice(0, 100).map((task) => (//{/* 100 */}
                     ? tasks.slice(pageIndex * limit, pageIndex * limit+ limit).map((task) => (

                        <TableRow hover key={task.id} onClick={() => handleOpenTask(task.id)} className={classes.taskRow}>
                          <TableCell>
                            <Box display="flex" justifyContent="center">
                              <Avatar
                                alt="task"
                                src={getCategoryImage(task.category)}
                                variant="square"
                              />
                            </Box>
                          </TableCell>
                          <TableCell>
                            {task.title && task.title.length > 25
                              ? `${task.title.substr(0, 25)}...`
                              : task.title}
                          </TableCell>
                          <TableCell>
                            {moment((typeof task?.createAt === 'string'
                              ? new Date(task.createAt)
                              : new Date(task.createAt.toDate().toString())))
                              .format('dddd, MMM DD')}
                          </TableCell>
                          <TableCell>
                            {moment((typeof task?.dateToStart === 'string'
                              ? new Date(task.dateToStart)
                              : new Date(task.dateToStart.toDate().toString())))
                              .format('dddd, MMM DD')}
                          </TableCell>
                          <TableCell>
                            <StatusIndicator task={task} />
                          </TableCell>
                        </TableRow>
                      ))
                      : getLoadingSkeleton()}
                  </TableBody>
                )
                : 
                  <TableBody>
                    <TableCell colSpan={5}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      >
                        {tasks.length<=0 && isLoading? <CircularProgress color='#2c387e' size={30}/>:"No Results Found !"}
                      
                  </div>
                    </TableCell>
                  </TableBody>
                }
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          onChangeRowsPerPage={handleLimitChange}

          component="div"
          rowsPerPageOptions={[100,50,25]}
          count={count}
          onChangePage={handlePageChange}
          page={pageIndex}
          rowsPerPage={limit} //100
          //rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Card>
    </>
  );
};

TasksTable.propTypes = {
  className: PropTypes.string,
  tasks: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageChanged: PropTypes.func,
  pageIndex: PropTypes.number,
  setPageSize: PropTypes.func,
  pageSizeIndex: PropTypes.number,

};

export default TasksTable;
