import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseUtility from '../../utils/firebase.utils';


const Toolbar = ({
  className,
  isLoading,
  fetchMoreUsers,
  filterData,
  ...rest
  
}) => {
  const [text, setText] = useState('');
  const [disableSearch, setDisable] = useState(false);
  const [filter, setFilter] = useState('pending');
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [lastItem, setLastItem] = useState(null);



 
  // const filterUsers = (ev) => {
  //   const selectedFilter = ev.target.value;
  //   localStorage.setItem('Filter', selectedFilter);
  //   setFilter(selectedFilter);

  //   return fetchUsers(selectedFilter);
  // };

  const filterUsers = ev => {
    const selectedFilter = ev.target.value;
    localStorage.setItem('Filter', selectedFilter);
    setFilter(selectedFilter);

    return filterData(selectedFilter);
  };
  useEffect(() => {
    setFilter('pending');
    filterData('pending');
  }, []);
  
   
 
  return (
    <div className={clsx(className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              
              <Grid item md={4}>
                <Box maxWidth="400px">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-label">
                    Verification  Status
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="status-label"
                      id="status-label"
                      label=" Verification Status"
                      value={filter}
                      onChange={(ev) => {
                        filterUsers(ev);
                      }}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                      <MenuItem value="verified">Verified</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{
                      color: '#c3c3c3'
                    }}
                  >
                    Select task status to filter the data
                  </Typography>
                </Box>
              </Grid>
              
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  searchTasksMethod: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  filterData: PropTypes.func
};

export default Toolbar;
