import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

export default props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.withOutIcons ? (
        <Chip
          variant={props.variant}
          size={props.size}
          color={props.variant == 'default' ? 'default' : 'primary'}
          label={props.label}
        />
      ) : (
        <Chip
          variant={props.variant}
          size={props.size}
          avatar={<Avatar  src={props.icon} />}
          color={props.variant == 'default' ? 'default' : 'primary'}
          deleteIcon={props.variant == 'default' ? <AddIcon /> : <DeleteIcon />}
          label={props.label}
          onDelete={props.handleClick}
        />
      )}
    </div>
  );
};
