import * as CleaningImage from '../../assets/categories/cleaning.png';
import AssemblyImage from '../../assets/categories/assembly.png';
import Handyman from '../../assets/categories/handyman.png';
import DeliveryImage from '../../assets/categories/delivery.png';
import GardeningImage from '../../assets/categories/gardening.png';
import AdminImage from '../../assets/categories/admin.png';
import MoversImage from '../../assets/categories/removalists.png';
import ComputerImage from '../../assets/categories/computer.png';
import CameraImage from '../../assets/categories/photography.png';
import OthersImage from '../../assets/categories/custom.png';
import PainterImage from '../../assets/categories/painting.png';
import LiftingImage from '../../assets/categories/lifting.png';

export default (category = '') => {
  switch (category.toLowerCase()) {
    case 'cleaning':
      return CleaningImage;
    case 'assembly':
      return AssemblyImage;
    case 'handyman':
      return Handyman;
    case 'delivery':
      return DeliveryImage;
    case 'gardening':
      return GardeningImage;
    case 'admin':
      return AdminImage;
    case 'hauling':
      return MoversImage;
    case 'computer it':
    case 'computer-it':
    case 'computerit':
      return ComputerImage;
    case 'photography':
      return CameraImage;
    case 'others':
      return OthersImage;
    case 'painting':
      return PainterImage;
    case 'Lifting':
      return LiftingImage;
    default:
      return OthersImage;
  }
};
