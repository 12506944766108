import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import Messaging from '../private-messaging/messaging-index';
import ViewReplies from './viewReplies';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },

  },
  contentRoot: {
    padding: '0'
  }
}));

// eslint-disable-next-line react/prop-types,no-unused-vars
const RepliesDialog = ({ opened = true, closeDialog, comment }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(opened);

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
          closeDialog();
        }}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.dialogRoot,
          },
        }}
      >
        <DialogContent
          classes={{ root: classes.contentRoot }}
        >
          <ViewReplies comment={comment}/>
        </DialogContent>
        {/*        <DialogActions>
          <Button
            disabled={loading}
            autoFocus
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            {!loading ? 'Close' : <CircularProgress />}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default RepliesDialog;
