import React, { useEffect, useState } from 'react';
import algoliaSearch from 'algoliasearch';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from './toolbar';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';
import configs from '../../configs';
import TasksTable from './tasksTable';

const searchClient = algoliaSearch(
  configs().algolia.appId,
  configs().algolia.searchKey
);
const searchIndex = searchClient.initIndex(configs().algolia.tasksIndex);

const useStyles = makeStyles(theme => ({

  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const TasksView = () => {
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fromAlgolia, setFromAlgolia] = useState(true);
  const [firebaseFilter, setFilter] = useState('open');
  const [filterRemote, setIsRemote] = useState(false);
  const [hasMore, setMoreResult] = useState(true);

  const [pageSize, setPageSize] = useState(100);

  const fetchTasksFB = async (status, isRemote) => {
    const d = new Date();
    const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());

    setLoading(true);
    let ref = await firebaseUtility.Firestore().collection('tasks');
    if (status === 'completed') {
      ref = ref.orderBy('createAt', 'desc').where('isComplete', '==', true);
    } else if (status === 'cancelled') {
      ref = ref.orderBy('createAt', 'desc').where('isCancelled', '==', true);
    } else if (status === 'disputed') {
      ref = ref.orderBy('createAt', 'desc').where('isDispute', '==', true).where('isComplete', '==', false).where('isCancelled', '==', false);
     
    } else if (status === 'expired') {
      ref = ref
        .orderBy('dateToStart', 'desc')
        .where('dateToStart', '<', today)
        .where('isCancelled', '==', false)
        .where('isAssigned', '==', false)
        .where('isComplete', '==', false);     
    }
    if (isRemote === 'yes') {
      ref = ref.where('isRemote', '==', true);
    } else if (isRemote === 'no') {
      ref = ref.where('isRemote', '==', false);
    }
    const result = await ref.limit(100).get();
    const mappedItems = result.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });

    if (result.docs.length < 100) setMoreResult(false);
    setTasks([...mappedItems]);
    setLastItem(result.docs[result.docs.length - 1]);
    setLoading(false);
  };

  const algoliaSearchTasks = (
    text,
    status = 'open',
    isRemote,
    pageIndex = 0
  ) => {
    let facet = [];
    let filtering = '';

    if (status === 'open') {
      filtering = `dateToStartUnix > ${(new Date().getTime() / 1000).toFixed(
        0
      )}`;
      facet = [
        `isAssigned:${false}`,
        `isComplete:${false}`
      ];
    } else if (status === 'assigned') {
      facet = [
        `isAssigned:${true}`,
        `isComplete:${false}`
        
      ];
    }
    if (isRemote === 'yes') {
      facet.push(`isRemote:${true}`);
    } else if (isRemote === 'no') {
      facet.push(`isRemote:${false}`);
    }

    searchIndex
      .search(text || '', {
        hitsPerPage: 100,
        page: pageIndex,
        facetFilters: facet,
        filters: filtering
      })
      .then(results => {
        if (results.hits.length < 100) setMoreResult(false);
        if (
          status !== firebaseFilter ||
          (isRemote === 'yes') !== filterRemote
        ) {
          setTasks([...results.hits]);
        } else {
          setTasks([...tasks, ...results.hits]);
        }

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchMoreTasksFB = async (status, isRemote) => {
    const d = new Date();
    const today = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate());

    setLoading(true);
    let ref = await firebaseUtility.Firestore().collection('tasks');
    if (status === 'completed') {
      ref = ref.orderBy('createAt', 'desc').where('isComplete', '==', true);
    }
     else if (status === 'cancelled') {
      ref = ref.orderBy('createAt', 'desc').where('isCancelled', '==', true);
    }
    else if (status === 'expired') {
      ref = ref.orderBy('dateToStart', 'desc').where('isCancelled', '==', false)
        .where('isComplete', '==', false)
        .where('isAssigned','==',false)
    }
    else if (status === 'disputed') {
      ref = ref.orderBy('createAt', 'desc').where('isDispute', '==', true)
      .where('isAssigned','==',true)
      .where('isCancelled','==',true);
    }
    if (isRemote === 'yes') {
      ref = ref.where('isRemote', '==', true);
    } else if (isRemote === 'no') {
      ref = ref.where('isRemote', '==', false);
    }


    const result = await ref
      .startAfter(lastItem)
      .limit(100)
      .get();

    const mappedItems = result.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      };
    });

    if (result.docs.length < 100) setMoreResult(false);
    setTasks([...tasks, ...mappedItems]);
    setLastItem(result.docs[result.docs.length - 1]);
    setLoading(false);
  };

  const fetchTasks = async (text, status) => {
    setLoading(true);
    setTasks([]);
    algoliaSearchTasks(text, status);
  };

  const filterData = (status, remote) => {
    setTasks([]);
    setLastItem(null);
    setPage(0);
    setFilter(status);
    setMoreResult(true);
    setIsRemote(remote === true);
    if (status === 'open' || status === 'assigned') {
      setFromAlgolia(true);
      algoliaSearchTasks(' ', status, remote);
    } else {
      setFromAlgolia(false);
      fetchTasksFB(status, remote, null);
    }
  };


////////////////********************************* */
const handlePageSizeChange = async (size) => {
  setLoading(true);
  if (pageSize != size) {
    setPageSize(size);
    //await getUsers(false, size);
  }
  setLoading(false);
};
  const handleChangePage = async newPage => {
    setPage(newPage);
    if (newPage > page) {
      if (fromAlgolia) {
        await algoliaSearchTasks(
          '',
          firebaseFilter,
          filterRemote ? 'yes' : 'no',
          newPage
        );
      } else {
        await fetchMoreTasksFB(firebaseFilter, filterRemote ? 'yes' : 'no');
      }
    }
  };
  return (
    <Page className={classes.root} title="Tasks">
      <Container maxWidth={false}>
        <Toolbar
          filterData={filterData}
          fetchMoreTasksFB={fetchMoreTasksFB}
          searchTasksMethod={(txt, status) =>
            fetchTasks(txt === '' ? null : txt, status)
          }
          isLoading={loading}
        />
        <Box mt={3}>
          <TasksTable
            pageIndex={page}
            isLoading={loading}
            pageSizeIndex={pageSize}
            pageChanged={handleChangePage}
            tasks={tasks}
            count={hasMore ? -1 : tasks.length}
            
            setPageSize={(size) => {
              handlePageSizeChange(size);
            }}
            
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TasksView;
