import React, { useEffect, useState } from "react";
import Text from "../../text";
import FormInput, { BaseInputProps } from "../../components/input-fields/form-input";
import useTheme from "@material-ui/core/styles/useTheme";
import { FaMinusCircle, FaPlusCircle } from "react-icons/all";
import { Tabs, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NumberFormat from "react-number-format";
import Tab from "@material-ui/core/Tab";

class BudgetData {
  budgetType = "total" || "hourly";
  totalBudget = "";
  hourlyBudget = "";
  hours;
  noOfTaskers;

  constructor(data) {
    this.budgetType = data?.budgetType ?? "total";
    this.totalBudget = data?.totalBudget ?? "0";
    this.hourlyBudget = data?.hourlyBudget ?? "0";
    this.hours = data?.hours ?? 1;
    this.noOfTaskers = data?.noOfTaskers ?? 1;
  }

  get isValid() {
    let valid = true;
    if (this.budgetType == "total") {
      if (parseInt(!this.totalBudget ? "0" : this.totalBudget.replaceAll(",", "")) < 10) valid = false;
    } else {
      if (parseInt(!this.hourlyBudget ? "0" : this.hourlyBudget.replaceAll(",", "")) < 15) {
        valid = false;
      } else if (this.hours == 0) {
        valid = false;
      }
    }

    return valid;
  }

  get errors() {
    const budget = this.budgetType == "total" ? parseInt(!this.totalBudget ? "0" : this.totalBudget.replaceAll(",", "")) : parseInt(!this.hourlyBudget ? "0" : this.hourlyBudget.replaceAll(",", ""));
    if (this.budgetType == "total" && budget < 10) {
      return "The min. value for the budget is $10";
    } else if (this.budgetType == "total" && budget > 9999) {
      return "The max. value for the budget is $9,999";
    } else if (this.budgetType == "hourly") {
      if (budget * this.hours > 9999 || budget * this.hours * this.noOfTaskers > 9999) {
        return "The max. value for the budget is $9,999";
      } else if (budget < 15) {
        return "The min. hourly rate is $15";
      } else if (this.hours == 0) {
        return "The min. required hours is one";
      } else if (parseInt(!this.budget() ? "0" : this.budget()) > 9999) {
        return "The max. value for the budget is $9,999";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  get isMultipleTaskers() {
    return this.noOfTaskers > 1;
  }

  budget(future = false) {
    if (this.budgetType === "total") {
      return this.totalBudget.trim();
    } else {
      let t = this.noOfTaskers ?? 1;
      return ((future ? t + 1 : t) * (parseInt(this.hourlyBudget) * (this.hours ?? 1))).toString();
    }
  }

  get serviceFees() {
    let b = (parseInt(this.budget ? this.budget().replaceAll(",", "") : "0") * 0.15);
    if (b)
      return <NumberFormat value={b.toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={""} />;
    else
      return "0.00"
  }

  get receivedAmount() {
    let b = parseInt(this.budget ? this.budget().replaceAll(",", "") : "0");
    if (b) {
      return <NumberFormat value={(b - b * 0.15).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={""} />;
    } else
      return "0.00"
  }

  toJson() {
    return Object.assign({}, this);
  }
}



const useStyles = makeStyles((theme) => ({
  rootTabContainer: {
    transform: "translateY(4px)",
    background: "rgb(230,230,230)",
    borderRadius: "20px",
    width: "auto",
    height: "28px",
    minHeight: "unset"
  },
  rootTab: {
    textTransform: "none",
    fontSize: theme.typography.pxToRem(15),
    minHeight: "unset",
    height: "28px",
    minWidth: "unset",
    width: "120px"
  },
  indicatorTab: {
    display: "flex",
    height: "100%",
    borderRadius: "16px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    "& > div": {
      width: "100%",
      backgroundColor: theme.palette.primary.main
    }
  },
  hover: {
    background: "transparent",
    padding: "10px",
    margin: "0 2px",
    width: "18px",
    height: "18px",
    cursor: "pointer",
    borderRadius: "50%",
    "&:hover": {
      background: "rgb(235,235,235)"
    }
  }
}));

const BudgetInput = ({ onBudgetChange, onChange, initialBudgetData, initialTypeToView = "total", disableSwitchingBetweenTabs = false, allowEditingNoOfTaskers = true, placeholder }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [budgetData, setBudgetData] = useState(initialBudgetData ? initialBudgetData : new BudgetData());
  const [tab, setTab] = useState(initialTypeToView === "total" ? 0 : 1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setBudgetData((b) => {
      let t = new BudgetData(b.toJson());
      t.budgetType = newValue === 0 ? "total" : "hourly";
      return t;
    });
  };

  useEffect(() => {
    if (initialBudgetData) setBudgetData(initialBudgetData);
  }, []);

  useEffect(() => {
    onBudgetChange(budgetData);
    onChange(null);
  }, [budgetData]);

  return (
    <>
      {!disableSwitchingBetweenTabs && (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "12px" }}>
          <Tabs variant="scrollable" scrollButtons="off" classes={{ root: classes.rootTabContainer, indicator: classes.indicatorTab }} value={tab} onChange={handleChange} aria-label="budget-types">
            <Tab
              disableRipple
              classes={{ root: classes.rootTab }}
              label={
                <Text variant={"caption"} style={{ color: tab === 0 ? "white" : "grey" }} bold>
                  Total
                </Text>
              }
            />
            <Tab
              disableRipple
              classes={{ root: classes.rootTab }}
              label={
                <Text variant={"caption"} style={{ color: tab === 1 ? "white" : "grey" }} bold>
                  Hourly rate
                </Text>
              }
            />
          </Tabs>
        </div>
      )}
      {tab === 0 && (
        <FormInput
          autoComplete={"off"}
          rootStyle={{ width: "100%" }}
          defaultValue={initialBudgetData?.totalBudget !== "0" ? "" + initialBudgetData?.totalBudget : null}
          name={"budget"}
          onChange={(el) => {
            let val = el?.target?.value ?? "";
            setBudgetData((b) => {
              let t = new BudgetData(b.toJson());
              t.totalBudget = val;
              return t;
            });
          }}
          placeholder={disableSwitchingBetweenTabs ? placeholder ? placeholder : "Your Offer" : "Budget"}
          type={"number"}
          required
          startAdornment={
            <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
              $
            </Text>
          }
        />
      )}
      {tab === 1 && (
        <>
          <div style={{ display: "flex" }}>
            <FormInput
              onChange={(el) => {
                let val = el?.target?.value ?? "";
                setBudgetData((b) => {
                  let t = new BudgetData(b.toJson());
                  t.hourlyBudget = val;
                  return t;
                });
              }}
              autoComplete={"off"}
              rootStyle={{ width: "100%", flex: 1 }}
              defaultValue={initialBudgetData?.hourlyBudget !== "0" ? initialBudgetData?.hourlyBudget : null}
              name={"budget"}
              placeholder={disableSwitchingBetweenTabs ? placeholder ? placeholder : "Offer" : "Budget"}
              type={"number"}
              required
              startAdornment={
                <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
                  $
                </Text>
              }
              endAdornment={
                <Text variant={"caption"} medium style={{ opacity: "0.3", wordBreak: "unset", whiteSpace: "unset", flexShrink: 0, marginRight: "12px" }}>
                  / hr
                </Text>
              }
            />
            <div style={{ flexShrink: 0, padding: "8px", transform: "translateY(-2px)", display: "flex", alignItems: "center" }}>
              <Text variant={"h6"} medium>
                ×
              </Text>
            </div>
            <FormInput
              autoComplete={"off"}
              rootStyle={{ width: "100%", flex: 1 }}
              onChange={(el) => {
                let s = el?.target?.value ?? "";
                let val = s ? parseInt(s) : 1;
                setBudgetData((b) => {
                  let t = new BudgetData(b.toJson());
                  t.hours = val;
                  return t;
                });
              }}
              defaultValue={initialBudgetData?.hours?.toString() ?? "1"}
              name={"budget"}
              placeholder={"Hours"}
              type={"number"}
              required
              endAdornment={
                <Text variant={"caption"} medium style={{ opacity: "0.3", wordBreak: "unset", whiteSpace: "unset", flexShrink: 0, marginRight: "12px" }}>
                  hrs.
                </Text>
              }
            />
          </div>
          {allowEditingNoOfTaskers && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Text variant={"body2"} medium style={{ marginTop: "8px" }} selectable={false}>
                How many taskers do you need?
              </Text>
              <div style={{ display: "flex" }}>
                <div className={classes.hover}
                  style={{ marginRight: "20px", top: "10px" }}
                  onClick={() => {
                    if (budgetData.noOfTaskers > 1)
                      setBudgetData((b) => {
                        let t = new BudgetData(b.toJson());
                        t.noOfTaskers--;
                        return t;
                      });
                  }}
                >
                  <FaMinusCircle
                    style={{
                      color: budgetData.noOfTaskers > 1 ? theme.palette.primary.main : "rgb(200,200,200)",
                    }}
                    size={18}
                  />
                </div>
                <Text variant={"body2"} medium style={{ display: "flex", alignItems: "center", margin: "0 6px" }} selectable={false}>
                  {budgetData.noOfTaskers}
                </Text>
                <div className={classes.hover}
                  onClick={() => {
                    if (parseInt(budgetData.budget(true)) <= 9999)
                      setBudgetData((b) => {
                        let t = new BudgetData(b.toJson());
                        t.noOfTaskers++;
                        return t;
                      });
                  }}
                >
                  <FaPlusCircle
                    style={{
                      color: parseInt(budgetData.budget(true)) <= 9999 ? theme.palette.primary.main : "rgb(200,200,200)"
                    }}
                    size={18}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { BudgetData };
export default BudgetInput;
