import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import firebaseUtility from '../../utils/firebase.utils';

import MessageListItem from './message-list-item';
import Avatar from '../avatar';

const Fade = require('react-reveal/Fade');

const MessageViewerList = ({ allowSend, admin }) => {
  const params = useParams();
  const sentinel = useRef(null);
  const [messages, setMessages] = useState([]);
  const [last, setLast] = useState(null);
  const [flag, setFlag] = useState(false);
  const [poster, setPoster] = useState(null);
  const [tasker, setTasker] = useState(null);

  const messagesRef = useRef([]);
  messagesRef.current = messages;

  const lastRef = useRef();
  lastRef.current = last;

  const flagRef = useRef(false);
  flagRef.current = flag;

  useEffect(() => {
    setMessages([]);
    const taskId = params.msg_id.split('_')[0];
    const offerId = params.msg_id.split('_')[1];

    const messageStream = firebaseUtility
      .Firestore()
      .collection('tasks')
      .doc(taskId)
      .collection('offers')
      .doc(offerId)
      .collection('messaging')
      .doc(params.msg_id)
      .collection('messages')
      .orderBy('createAt', 'desc')
      .onSnapshot(snapshot => {
        updateMessages(snapshot);
      });

    getUsersData();

    return () => {
      setMessages([]);
      messageStream();
    };
  }, [params.msg_id]);

  const updateMessages = snapshot => {
    if (snapshot.docChanges().length == 0) {
      setMessages([]);
    } else {
      let lastDocumentSnapshot = null;
      const messagesList = [...messagesRef.current];
      snapshot.docChanges().forEach(d => {
        switch (d.type) {
          case 'added':
            const data = d.doc.data();
            data.id = d.doc.id;
            data.createAt = data?.createAt
              ? typeof data?.createAt === 'string'
                ? new Date(data.createAt)
                : data.createAt instanceof Date
                ? data.createAt
                : new Date(data.createAt.toDate().toString())
              : null;
            if (
              messagesList.length > 0 &&
              messagesList[messagesList.length - 1].createAt < data.createAt
            ) {
              messagesList.push(data);
            } else {
              messagesList.splice(0, 0, data);
            }
            if (!lastRef.current) {
              lastDocumentSnapshot = d.doc;
            }
            break;
          case 'removed':
          case 'modified':
        }
      });
      setMessages(messagesList);

      if (!lastRef.current && !flagRef.current) {
        setLast(lastDocumentSnapshot);
        setFlag(true);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) {
      observer.observe(sentinel.current);
    }

    return function cleanup() {
      observer.disconnect();
    };
  });

  const onSentinelIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (lastRef.current) {
          const taskId = params.msg_id.split('_')[0];
          const offerId = params.msg_id.split('_')[1];
          firebaseUtility
            .Firestore()
            .collection('tasks')
            .doc(taskId)
            .collection('offers')
            .doc(offerId)
            .collection('messaging')
            .doc(params.msg_id)
            .collection('messages')
            .orderBy('createAt', 'desc')
            .startAfter(lastRef.current)
            .limit(20)
            .onSnapshot(snapshot => {
              let lastDocumentSnapshot = null;
              const messagesList = [...messagesRef.current];

              if (snapshot.docs.length != 0) {
                snapshot.docs.forEach(d => {
                  const data = d.data();
                  data.id = d.id;
                  data.createAt = data?.createAt
                    ? typeof data?.createAt === 'string'
                      ? new Date(data.createAt)
                      : data.createAt instanceof Date
                      ? data.createAt
                      : new Date(data.createAt.toDate().toString())
                    : null;
                  messagesList.splice(0, 0, data);
                  lastDocumentSnapshot = d;
                });
              }
              setMessages(messagesList);
              setLast(lastDocumentSnapshot);
            });
        }
      }
    });
  };

  const getUsersData = () => {
    const taskId = params?.msg_id.split('_')[0];
    const offerId = params?.msg_id.split('_')[1];

    firebaseUtility
      .Firestore()
      .collection('tasks')
      .doc(taskId)
      .collection('offers')
      .doc(offerId)
      .get()
      .then(async res => {
        const taskerUid = res.data().uid;

        const taskerData = await firebaseUtility
          .Firestore()
          .collection('users')
          .doc(taskerUid)
          .get();
        setTasker({ ...taskerData.data() });

        const task = await firebaseUtility
          .Firestore()
          .collection('tasks')
          .doc(taskId)
          .get();
        const posterUid = task.data().uid;
        const posterData = await firebaseUtility
          .Firestore()
          .collection('users')
          .doc(posterUid)
          .get();
        setPoster({ ...posterData.data() });
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      {messages.length > 0 && (
        <div>
          <div ref={sentinel} />
          <div style={{ height: '150px' }} />
          {messages.map((message, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: message.isSupport ? 'flex-end' : 'flex-start',
                  wordBreak: 'break-word'
                }}
              >
                {(index === 0 ||
                  (index !== 0 &&
                    message.createAt.getTime() -
                      messages[index - 1].createAt.getTime() >
                      3600000)) && (
                  <div
                    style={{
                      margin: '8px 0',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: 'grey' }}
                      regular
                    >
                      {moment(message.createAt).format('MMM DD, hh:mm A')}
                    </Typography>
                  </div>
                )}
                {message.uid !== messages[index - 1]?.uid ? (
                  message.isSupport ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '30px'
                      }}
                    >
                      <Avatar
                        name={`${admin?.firstName} ${admin?.lastName}`}
                        profilePicture={admin?.profilePicture}
                        size={22}
                      />
                      <Typography
                        variant="body2"
                        style={{ marginLeft: '12px' }}
                      >
                        {admin?.firstName && admin?.lastName ?`${admin?.firstName} ${admin?.lastName}`: 'Loading...'} &#8226;
                      </Typography>
                      <Typography variant="caption">Admin</Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '30px'
                      }}
                    >
                      <Avatar
                        name={
                          message.uid === tasker?.uid
                            ? `${tasker?.firstName} ${tasker?.lastName}`
                            : `${poster?.firstName} ${poster?.lastName}`
                        }
                        profilePicture={
                          message.uid === tasker?.uid
                            ? tasker?.profilePicture
                            : poster?.profilePicture
                        }
                        size={22}
                      />
                      <Typography
                        variant="body2"
                        style={{ marginLeft: '12px' }}
                      >
                        {message.uid === tasker?.uid
                          ? `${tasker?.firstName} ${tasker?.lastName}`
                          : `${poster?.firstName} ${poster?.lastName}`}{'Loading...'}
                        &#8226;
                      </Typography>
                      <Typography variant="caption">
                        {message.uid === tasker?.uid ? 'Tasker' : 'Poster'}
                      </Typography>
                    </div>
                  )
                ) : null}
                <Fade bottom distance="12px" key={index} duration={300}>
                  <MessageListItem
                    message={message}
                    nextUid={
                      index >= messages.length - 1
                        ? null
                        : messages[index + 1]?.uid
                    }
                    taskerUid={tasker?.uid}
                  />
                </Fade>
              </div>
            );
          })}
          <div style={{ height: allowSend ? '100px' : '50px' }} />
        </div>
      )}
    </div>
  );
};

export default MessageViewerList;
