export default class Address {
    id = "";
    placeId = "";
    addressLineOne = "";
    addressLineTwo= "";
    city= "";
    state= "";
    country= "";
    zipCode= "";
    lat= null;
    lng= null;


    constructor(data) {
        this.id = data?.id ?? null;
        this.placeId = data?.placeId ?? null;
        this.addressLineOne = data?.addressLineOne ?? null;
        this.addressLineTwo = data?.addressLineTwo ?? null;
        this.city = data?.city ?? null;
        this.state = data?.state ?? null;
        this.country = data?.country ?? null;
        this.zipCode = data?.zipCode ?? null;
        this.lat = data?.lat ?? null;
        this.lng = data?.lng ?? null;
    }

    toJson() {
        return Object.assign({}, this);
    }

    get primaryLine() {
        return this.addressLineOne;
    }

    get secondaryLine() {
        return this.city ? this.city + ", " : "  " + this.state;
    }

    get secondaryLineWithZipCode() {
        return (this.city ? this.city + ", " : "  " + this.state + " " + this.zipCode).trim();
    }
}
