import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem3: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const SkeletoLoader = ({ page }) => {
  const classes = useStyles();
  const getUsersTableSkeleton = () => {
    const items = [1, 2, 3, 4, 5];
    return (
      <>
        {items.map((item, index) => (
          <TableRow hover key={`${index}`}>
            <TableCell>
              <Box alignItems="center" display="flex">
                <Skeleton
                  animation="wave"
                  variant="circle"
                  width={40}
                  height={40}
                />
              </Box>
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const getTasksCardsSkeleton = () => {
    const items = [1, 2, 3, 4, 5];
    return (
      <>
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item key={item + '' + index} lg={4} md={6} xs={12}>
              <Card className={clsx(classes.root, classes.productCard)}>
                <CardContent>
                  <Box display="flex" justifyContent="center" mb={3}>
                    <Skeleton variant="circle" width={70} height={70} />
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    <Skeleton variant="text" />
                  </Typography>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                  >
                    <Skeleton variant="text" />
                  </Typography>
                </CardContent>
                <Box flexGrow={1} />
                <Divider />
                <Box p={2}>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid className={classes.statsItem3} item md={12}>
                      <AccessTimeIcon
                        className={classes.statsIcon}
                        color="action"
                      />
                      <Typography
                        color="textSecondary"
                        display="inline"
                        variant="body2"
                      >
                        <Skeleton variant="text" width={200} />
                      </Typography>
                    </Grid>
                    <Grid className={classes.statsItem3} item md={12}>
                      <LocationOnIcon
                        className={classes.statsIcon}
                        color="action"
                      />
                      <Typography
                        color="textSecondary"
                        display="inline"
                        variant="body2"
                      >
                        <Skeleton variant="text" width={200} />
                      </Typography>
                    </Grid>
                    <Grid className={classes.statsItem3} item md={12}>
                      <Button variant="outlined" color="primary" fullWidth>
                        <Skeleton variant="text" width={200} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  // eslint-disable-next-line consistent-return
  const getSkeleton = () => {
    if (page == 'users') {
      return getUsersTableSkeleton();
    } else if (page == 'tasks') {
      return getTasksCardsSkeleton();
    }
    return getUsersTableSkeleton();
  };

  return <>{getSkeleton()}</>;
};

export default SkeletoLoader;
