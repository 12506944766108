import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SkeletoLoader from '../skeletoLoader';
import QuestionCard from '../questionCard';
import firebaseUtility from '../../utils/firebase.utils';

const CommentsTab = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [moreResult, setMoreResult] = useState(true);
  const [commentId, setId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchComment();
  }, []);

  const fetchComment = async () => {
    setLoading(true);

    const commentsRef = await firebaseUtility.Firestore()
      .collectionGroup('comments')
      .orderBy('createAt')
      .where('uid', '==', user.uid)
      .startAfter(lastItem)
      .limit(10)
      .get();

    if (!commentsRef.empty) {
      if (commentsRef.docs.length < 10) setMoreResult(false);
      const commentsList = [];
      commentsRef.docs.forEach((doc) => {
        commentsList.push({ ...doc.data() });
      });
      setComments([...comments, ...commentsList]);
      setLastItem(commentsRef.docs[commentsRef.docs.length - 1]);
    } else {
      setMoreResult(false);
    }
    setLoading(false);
  };

  const deleteItem = async (event) => {
    event.preventDefault();
    try {
      const comment = comments.filter((comm) => {
        return comm.id === commentId;
      });
      if (comment[0]) {
        if (comment[0].replies !== null && comment[0].replies === 0) {
          await firebaseUtility.Firestore()
            .collection('tasks').doc(comment[0].taskId)
            .collection('comments')
            .doc(comment[0].id)
            .delete();
        } else {
          const batch = firebaseUtility.Firestore().batch();
          const Ref = await
          firebaseUtility.Firestore()
            .collection('tasks')
            .doc(comment[0].taskId)
            .collection('comments')
            .doc(comment[0].id)
            .collection('replies')
            .get();

          Ref.docs.forEach((reply) => {
            batch.delete(reply.ref);
          });

          await batch.commit();

          await firebaseUtility.Firestore()
            .collection('tasks').doc(comment[0].taskId)
            .collection('comments')
            .doc(comment[0].id)
            .delete();
        }
        const allComments = comments.filter((commen) => {
          return commen.id !== commentId;
        });
        setComments([...allComments]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpen(false);
  };

  const promptDelete = (id) => {
    setId(id);
    setLoading(true);
    setOpen(true);
  };

  const getLoadingSkeleton = () => <SkeletoLoader page="tasks" />;
  return (
    <div>
      <Box mt={3}>
        <Grid container spacing={3}>
          {loading
            ? getLoadingSkeleton()
            : comments.map((comment) => (
              <Grid item key={`${comment.id}`} lg={6} md={6} xs={12}>
                <QuestionCard
                  promptDelete={promptDelete}
                  question={comment}
                />
              </Grid>
            ))}
        </Grid>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          {moreResult
            ? (
              <Button
                disabled={loading}
                variant="outlined"
                onClick={() => fetchComment()}
              >
                {loading ? 'Loading ...' : 'Load more'}
              </Button>
            )
            : <Typography variant="body2"> No More Results!</Typography>}
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={deleteItem} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommentsTab;
