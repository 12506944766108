import React, { useEffect, useState } from 'react';
import ReactTimeago from 'react-timeago';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ArrowRightCircle, Trash2 } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Button, ListItemIcon } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MenuListItem from './menuListItem';
import MenuWrapper from './menuWrapper';
import ReadMore from './readMore';
import Avatar from './avatar';
import firebaseUtility from '../utils/firebase.utils';
import InlineLink from './inlineLink';
import RepliesDialog from './viewReplies/RepliesDialog';
import zIndex from '@material-ui/core/styles/zIndex';
import AttachmentHandler from './attachment-handler';



const useStyles = makeStyles(() => ({
  questionCard: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    position: 'relative',
    borderRadius: '6px',
    border: '1.5px solid rgb(240,240,240)',
    boxShadow: '0 0 30px -16px rgba(50,50,50,0.05)',
    marginBottom: '6px',
    transition: '0.3s',
    padding: '14px 16px'
  }
}));

const QuestionCard = ({
  question,
  type = 'question',
  promptDelete,
  insideTask = false
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDialog, setOpen] = useState(false);
  const [openAttacmentsDialog, setOpenAttacmentsDialog] = useState(false);

  React.useEffect(() => {
  console.log("qustions : ", question);
  }, [])

  const handleOpenTask = id => {
    navigate(`/app/tasks/${id}`);
  };
  const questionOptions = () => {
    return (
      <div id="menu">
        <MenuItem onClick={() => promptDelete(question.id)}>
          <ListItemIcon>
            <Trash2 style={{ color: 'inherit' }} size={16} />
          </ListItemIcon>
          {type === 'question' ? 'Delete Comment' : 'Delete Reply'}{' '}
        </MenuItem>
        {!insideTask && (
          <MenuItem onClick={() => handleOpenTask(question.taskId)}>
            <ListItemIcon>
              <ArrowRightCircle style={{ color: 'inherit' }} size={16} />
            </ListItemIcon>
            View Task
          </MenuItem>
        )}
        {type !== 'question' && (
          <MenuItem onClick={() => handleOpenTask(question.taskId)}>
            <ListItemIcon>
              <ArrowRightCircle style={{ color: 'inherit' }} size={16} />
            </ListItemIcon>
            View Comment
          </MenuItem>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={classes.questionCard} style={{ marginTop: '4px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Avatar
            name={question.name}
            profilePicture={question.profilePicture}
            size={36}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: '12px'
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <Typography variant="body2">{question.name}</Typography>
              <div
                style={{
                  margin: '0 8px',
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  background: 'grey'
                }}
              />
              <Typography style={{ color: '#646464' }} variant="caption">
                <ReactTimeago
                  date={
                    question?.createAt
                      ? typeof question?.createAt === 'string'
                        ? new Date(question.createAt)
                        : new Date(question.createAt.toDate().toString())
                      : null
                  }
                  live={false}
                  formatter={(value, unit) => {
                    return `${value.toString()} ${unit}${value > 1 ? 's' : ''}`;
                  }}
                />
              </Typography>
              <div style={{ marginLeft: 'auto' }} />
              <MenuWrapper items={questionOptions()}>
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    padding: '18px 12px',
                    right: '4px',
                    top: '4px'
                  }}
                >
                  <div
                    style={{
                      margin: '0 2px',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      background: 'rgb(200,200,200)'
                    }}
                  />
                  <div
                    style={{
                      margin: '0 2px',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      background: 'rgb(200,200,200)'
                    }}
                  />
                </div>
              </MenuWrapper>
            </div>
            <Typography
              variant="body2"
              style={{ color: 'black', marginTop: '4px', wordBreak: 'break-word' }}
            >
                     {question?.type === "image" && question?.images?.length > 0?
                          <div style={{
                            width: '200px',
                            height: "120px",
                            marginTop: "10px"

                        }}>
                            <AttachmentHandler images={[...question.images]} />
                        </div> :
                        <ReadMore maxLength={80}>{question.text}</ReadMore> 
                        }
            </Typography>
            {type === 'question' && question && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                {question.replies && question.replies > 0 ? (
                  <Typography
                    style={{ color: 'grey' }}
                    medium
                    variant="caption"
                  >
                    <InlineLink
                      onClick={() => setOpen(true)}
                      variant="secondary"
                      style={{
                        color: 'grey',
                        padding: '12px 8px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      spanStyle={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span
                        style={{
                          width: '24px',
                          height: '1px',
                          background: 'grey',
                          marginRight: '12px'
                        }}
                      />
                      View {question.replies}{' '}
                      {question.replies > 1 ? 'replies' : 'reply'}
                    </InlineLink>
                  </Typography>
                ) : (
                  <Typography
                    style={{ color: 'grey', padding: '12px 8px' }}
                    medium
                    variant="caption"
                  >
                    No Replies
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {openDialog && (
        <RepliesDialog
          opened={openDialog}
          closeDialog={() => setOpen(false)}
          comment={question}
        />
      )}
    </div>
  );
};

export default QuestionCard;
