import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Toolbar from './toolbar';
import LogsResults from './logsResults';
import Page from '../../components/Page';
import firebaseUtility from '../../utils/firebase.utils';
import { toast, ToastContainer } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Logs = () => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  useEffect(() => {
    toast.warning('Coming soon...');
  });
  return (
    <Page className={classes.root} title="Logs">
      <ToastContainer closeOnClick={true} hideProgressBar={false} />
      <Container maxWidth={false}>
        <Toolbar searchTasksMethod={() => {}} isLoading={loading} />
        <Box mt={3}>
          <LogsResults
            pageIndex={page}
            isLoading={loading}
            pageSizeIndex={pageSize}
            setPageSize={size => {}}
            pageChanged={pageIndex => {}}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Logs;
