import React, { useState } from "react";
import InlineLink from "./inlineLink";
import { Typography, useTheme } from "@material-ui/core";

const ReadMore = ({ children,maxLength }) => {
    const [readMore, setReadMore] = useState(false);
    const theme = useTheme();
    return (
        <span>
            {
                (children?.toString().length < maxLength || (children?.toString().length - maxLength < 10)) ?
                    children
                    :
                    <span>
                        {
                            readMore ?
                                children
                                :
                                <span>
                                    {children?.toString().substr(0, maxLength)}
                                    <Typography variant={"body2"} component={"span"} style={{ position: "relative" }}>
                                        <div style={{ background: "linear-gradient(-45deg, white 20%, transparent)", position: "absolute", left: 0, top: 0, transform: "translateX(-100%)", height: "20px", width: "100px" }} />
                                        <InlineLink variant={"secondary"} onClick={() => { setReadMore((prevState => !prevState)) }} style={{ color: theme.palette.primary.main }}>more</InlineLink>
                                    </Typography>
                                </span>
                        }
                    </span>
            }
        </span>
    )
}

export default ReadMore;
