import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import QuestionsTab from './Question/questionTab';
import OffersTab from './Offers/offersTab';

const useStyles = makeStyles((theme) => ({
  rootTabContainer: {
    transform: 'translateY(4px)',
    background: theme.palette.background.default,
    padding: '0 24px',
    borderRadius: '6px'
  },
  rootTab: {
    textTransform: 'none',
    padding: '0',
    minWidth: 'unset',
    justifyContent: 'flex-start !important',
    fontSize: theme.typography.pxToRem(15),
    marginRight: '48px',
  },
  indicatorTab: {
    display: 'flex',
    height: '4px',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }
}));

const TaskTabs = ({ questions, offers = [] ,updateItems}) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const { msg_id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const passItems=(items,type)=>{
    updateItems(items,type)
  }

  useEffect(() => {
    if (msg_id) {
      setValue(1);
    }
  }, [msg_id]);

  return (
    <>
      <Paper square>

        <Tabs
          value={value}
          variant="scrollable"
          classes={{ root: classes.rootTabContainer, indicator: classes.indicatorTab }}
          scrollButtons="off"
          TabIndicatorProps={{ children: <div /> }}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            disableRipple
            classes={{ root: classes.rootTab }}
            label={<Typography variant="body2" bold>Questions</Typography>}
          />
          <Tab
            disableRipple
            classes={{ root: classes.rootTab }}
            label={<Typography variant="body2" bold>Offers</Typography>}
          />
        </Tabs>
      </Paper>
      {value === 0 && (
        <QuestionsTab questions={questions} passItems={passItems} />
      )}
      {value === 1 && (
        <OffersTab offers={offers} passItems={passItems}/>
      )}
    </>
  );
};

export default TaskTabs;
