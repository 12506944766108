import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import typography from '../../theme/typography';
import firebaseUtility from '../../utils/firebase.utils';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 20px',
  },
  divider: {
    height: '30px',
  },
}));

// eslint-disable-next-line react/prop-types
const ReportTab = ({ report }) => {
  const classes = useStyles();
  const navigate = useNavigate();


  const userProfile=(user)=>{
    navigate(`/app/users/${user}`);

  }
 

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        <Grid item md={5} xd={12}>
          <Grid
            container
            direction="column"
            justify="space-between"
            spacing={3}
          >
            <Grid item md={12} xs={12}>
                <Typography variant="caption">
                 Reported User
               <Typography variant="body1">
                    {report.reportedUser.name}
                  </Typography>
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Typography variant="caption">
                  Report Title
               <Typography variant="body1">
                    {report.title}
                  </Typography>
                </Typography>

            </Grid>
            <Grid item md={12} xs={12}>
            <Typography variant="caption">
                  Report Description
                <Typography variant="body1">
                    {report.description}
                  </Typography>
                </Typography>
            </Grid>
           
          </Grid>
        </Grid>
        

      </Grid>
    </div>
  );
};

export default ReportTab;
