import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import MessageViewerBottom from './message-viewer-bottom';
import Avatar from '../avatar';
import MessageViewerList from './message-viewer-list';
import firebaseUtility from '../../utils/firebase.utils';

const MessageViewer = ({ allowSend }) => {
  const params = useParams();

  const [poster, setPoster] = useState(null);
  const [tasker, setTasker] = useState(null);
  const [admin, setAdmin] = useState(null);
  const userData = firebaseUtility.Auth().currentUser;

  useEffect(() => {
    if (userData) {
      firebaseUtility.Firestore().collection('users')
        .doc(userData.uid).get()
        .then((doc) => {
          if (doc.exists) {
            setAdmin({ ...doc.data() });
          }
        });
    }
  }, [userData]);

  useEffect(() => {
    const taskId = params?.msg_id.split('_')[0];
    const offerId = params?.msg_id.split('_')[1];

    firebaseUtility.Firestore().collection('tasks')
      .doc(taskId)
      .collection('offers')
      .doc(offerId)
      .get()
      .then(async (res) => {
        const taskerUid = res.data().uid;

        const taskerData = await firebaseUtility.Firestore()
          .collection('users')
          .doc(taskerUid)
          .get();
        setTasker({ ...taskerData.data() });

        const task = await firebaseUtility.Firestore().collection('tasks').doc(taskId).get();
        const posterUid = task.data().uid;
        const posterData = await firebaseUtility.Firestore()
          .collection('users')
          .doc(posterUid)
          .get();
        setPoster({ ...posterData.data() });
      });

  }, [params.msg_id]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div style={{
        zIndex: 1300,
        padding: '0 24px',
        boxSizing: 'border-box',
        backdropFilter: 'blur(10px)',
        background: 'rgba(254, 254, 254, 0.8)',
        borderBottom: '2px solid rgb(240,240,240,1)',
        height: '64px',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
          }}
      >
        <div style={{ display: 'flex' }}>
          <Avatar
            name={`${poster?.firstName} ${poster?.lastName}`}
            profilePicture={poster?.profilePicture}
            size={22}
          />
          <Typography variant="body2" style={{ marginLeft: '12px' }}>
            { poster?.firstName && poster?.lastName ? `${poster?.firstName} ${poster?.lastName}`: 'Loading...'}
            {' '}
            &#8226;
          </Typography>
          <Typography variant="caption">
            Poster
          </Typography>
        </div>
        <Typography variant="h6" style={{ marginLeft: '12px' }}>-</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            name={`${tasker?.firstName} ${tasker?.lastName}`}
            profilePicture={tasker?.profilePicture}
            size={22}
          />
          <Typography variant="body2" bold style={{ marginLeft: '12px' }}>
            {tasker?.firstName && tasker?.lastName ? `${tasker?.firstName} ${tasker?.lastName}` : 'Loading...'}
            {' '}
            &#8226;
          </Typography>
          <Typography variant="caption">
            Tasker
          </Typography>
        </div>
      </div>
      <MessageViewerList allowSend={allowSend} admin={admin} />
      {allowSend
              && <MessageViewerBottom admin={admin} />}
    </div>
  );
};

export default MessageViewer;
