import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Slider from '@material-ui/core/Slider';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Formik, Field, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { baseUrl } from "../../utils/constant.urls"
import { ref } from 'yup';
import typography from '../../theme/typography';
import axios from 'axios';
import firebaseUtility, { auth } from '../../utils/firebase.utils';
import exportConfigs from '../../configs';
import { toast } from 'react-toastify';
import DisputeContainer from './disputeContainer';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RadioButton = ({ label, ...rest }) => {
  return (
    <FormControlLabel
      control={<Radio {...rest} />}
      label={label}
      labelPlacement="start"
      style={{
        justifyContent: 'space-between',
        width: '100%'
      }}
    />
  );
};

const CustomTextField = React.forwardRef(({ InputProps, ref, ...rest }) => {
  return (
    <TextField
      inputRef={ref}
      InputProps={{ disableUnderline: true, ...InputProps }}
      {...rest}
    />
  );
});

const useStyles = makeStyles(theme => ({
 
  formControl: {
    marginLeft:'-7px',
    
    width:'394px',
    position:'relative',
    Button:'200px',
    fontSize:'11px',
    
  },
  inputRoot: {
    width: '150px',
    marginTop: '0'
  },

  label: {
    justifyContent: 'space-between',
    width: '100%'
  },
  paper: { borderRadius: 25 },
  thumb: {
    color: 'white',
    border: '2px solid gray',
    height: 24,
    width: 24,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  track: {
    color: '#3EDDB8',
    height: '14px',
    borderRadius: 6
  },
  rail: {
    color: 'gray',
    height: '14px',
    borderRadius: 6
  },
  rail2: {
    color: '#16B2DA',
    height: '14px',
    borderRadius: 6
  }
}));
const CancellationDialog = ({ opened = true, closeDialog, task }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(opened);
  const [reason, setReason] = React.useState('');
  const [resolution, setReso] = useState('');
  const [taskerDisabled, setTaskerDisabled] = useState(true);
  const [refundPercentage, setPercentage] = useState(100);
  const [taskerRecieves, setTaskerRecieves] = useState();
  const [showCancelTask, setShowCancelTask] = useState(true);
  const [showCompleteTask, setShowCompleteTask] = useState(true);
  const [sliderValue, setSliderValue] = useState(30);
  const [chargeTaskerValue, setchargeTaskerValue] = useState();
    const taskPrice = parseFloat(
    task?.assignedWorker?.taskPrice.replaceAll(',', '')
  );
  const posterPercentageValue = ((sliderValue / 100) * taskPrice).toFixed(2);
  const taskerRecievesValue = 0.85 * (taskPrice - posterPercentageValue);
  const sydetaskerRecievesValue = 0.15 * (taskPrice - posterPercentageValue);
  const [posterValue, setPosterValue] = useState(posterPercentageValue);
  
  const handleSliderChange = (e, newValue) => {
    setSliderValue(newValue);

  };
  const handleInputChange = (event, setFieldValue) => {
    const value =
      event.target.value === ''
        ? ''
        : (Number(event.target.value / taskPrice) * 100).toFixed(2);
    //setFun(value);
    setSliderValue(value);
    const taskerPerc = (0.15 * (taskPrice - (value / 100) * taskPrice)).toFixed(
      2
    );
    if (taskerPerc >= 0) {
      setFieldValue('taskerPercentage', taskerPerc);
    }
  };
  const handleSliderBlur = () => {
    if (sliderValue < 0) {
      setSliderValue(0);
    } else if (sliderValue > 100) {
      setSliderValue(100);
    }
  };
  const handleChangeReason = event => {
    setReason(event.target.value);
  };
  const cancelTask = async values => {
    console.log(values.chargeTaskerPer)
    console.log( values.chargeUpTo)
    let refund = (sliderValue / 100);
    const body = JSON.stringify({
      reason: reason === "Others" ? values.customReason : reason,
      notes: 'Sydetasker Admin Dispute',
      refundPercentage: refund,
      chargeTaskerPer:values.chargeTaskerPer,
      chargeUpTo:values.chargeUpTo,
      // chargeTasker: values.chargeTasker,
      taskId: task.id,
      type: resolution,
      isBookingFeesRefunded: values.includeBookingFees
    });
    await auth
      .currentUser.getIdToken(true)
      .then(async token => {
        await axios
          .post(
            `${baseUrl}/disputeTaskAPI`,
            body,
            {
              headers: {
                "content-type": "application/json",
                authorization: token
              }
            }
          )
          .then(async result => {
            toast.success('Task cancelled successfully');
            setOpenDialog(false);
            navigate("/app/disputes");
            closeDialog();
          }).catch(async err => {
            toast.error('Could not cancel the task');
            setOpenDialog(false);
            closeDialog();
          });
      })
      .catch(async err => {
        toast.error("Authentication failed. Please try again later.");
        setOpenDialog(false);
        closeDialog();
      });

  };

  const renderOptions = () => {
    return (
      <Select value={reason} onChange={handleChangeReason}>
        <MenuItem className={classes.formControl} value={"Poster found someone else outside Sydetasker to complete the task"}>
          Poster found someone else outside Sydetasker to complete the task
        </MenuItem>
        <MenuItem className={classes.formControl} value={"Poster don’t need the task done anymore"}>
          Poster don’t need the task done anymore</MenuItem>
        <MenuItem className={classes.formControl} value={"Poster is not responding to messages or a delay in response"}>
          Poster is not responding to messages or a delay in response
        </MenuItem>
        <MenuItem className={classes.formControl} value={"Tasker is not available to do the task on the schedules date"}>
          Tasker is not available to do the task on the schedules date
        </MenuItem>
        <MenuItem  className={classes.formControl} value={"Tasker doesn't have the skills/tools/materials needed to complete the task"}>
          Tasker doesn't have the skills/tools/materials needed to complete the
          task
        </MenuItem>
        <MenuItem className={classes.formControl}value={"Tasker is not responding to message or a delay in response"}>
          Tasker is not responding to message or a delay in response
        </MenuItem>
        <MenuItem className={classes.formControl}value={"Tasker did not show up"}>Tasker did not show up</MenuItem>
        <MenuItem className={classes.formControl} value={"Tasker is asking more than the task price"}>Tasker is asking more than the task price</MenuItem>
        <MenuItem className={classes.formControl}  value={"Misunderstanding/Misalignment on the scope of the task"}>
          Misunderstanding/Misalignment on the scope of the task
        </MenuItem>
        <MenuItem className={classes.formControl} value={"Uncontrollable situations such as emergency, weather conditions etc."}>
          Uncontrollable situations such as emergency, weather conditions etc.
        </MenuItem>
        <MenuItem className={classes.formControl} value={"Others"}>Others</MenuItem>
      </Select>
    );
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
          closeDialog();
        }}
        classes={{ paper: classes.paper }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Container maxWidth="xs">
            <Formik
              initialValues={{
                email: '',
                password: '',
                includeBookingFees: false,
                posterPercentage: 100,
                taskerPercentage: 0,
                taskerCharge: 0,
                chargeTasker: false,
                sydetaskerRecieves: 0,
                customReason: "",
                chargeTaskerPer:0,
                chargeUpTo:0
              }}
              validationSchema={Yup.object().shape({
                posterPercentage: Yup.number()
                  .max(
                    100,
                    'Refund percentage must be less than the task price'
                  )
                  .required('Refund percentage is required'),
                chargeTasker: Yup.boolean().default(false),
                reason: Yup.string().optional()
              })}
              onSubmit={values => {
                return cancelTask(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="row" justify="flex-start">
                    <Grid
                      item
                      md={12}
                      style={{ marginBottom: '15px', width: '100%' }}
                    >
                      <Typography variant="h3" style={{ marginLeft: '16px' }}>
                        Resolve Dispute
                      </Typography>
                      <RadioGroup
                        name="userType"
                        value={resolution}
                        onChange={e => setReso(e.target.value)}
                      >
                        {showCancelTask && (
                          <>
                            <div style={{ marginBottom: '1.5rem' }}>
                              <Field
                                type="radio"
                                name="resolutionType"
                                value="cancel"
                                color="primary"
                                onClick={() => {
                                  setShowCancelTask(true);
                                  setShowCompleteTask(false);
                                }}
                                label={
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    Cancel Task
                                  </Typography>
                                }
                                as={RadioButton}
                              />
                              <div>
                                <Typography
                                  variant="body1"
                                  style={{ color: 'gray', marginLeft: '16px' }}
                                >
                                  Refund money to Poster. Tasker will not
                                  receive any money.
                                </Typography>
                              </div>
                            </div>
                            <Divider />
                          </>
                        )}
                    
                        {!showCancelTask && (
                          <>
                            <Divider />
                          </>
                        )}
                      </RadioGroup>
                    </Grid>
                    {resolution === 'cancel' && (
                      <>
                        <Grid md={12} style={{ marginBottom: '15px' }}>
                          <Field type="checkbox" name="includeBookingFees" />
                          <Typography variant="body1" component="span">
                            {' '}
                            Include Booking Fees
                          </Typography>
                        </Grid>
                        <Grid md={6} style={{ marginBottom: '15px' }}>
                          {/* <Field type="checkbox" name="chargeTasker" /> */}
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              marginTop: '12px'
                            }}
                          >
                          <Typography variant="body1" component="span">
                            {' '}
                            Charge Tasker
                          </Typography>
                             <TextField
                              margin="none"
                              name="chargeTaskerPer"
                              value={values.chargeTaskerPer}
                              disabled={false}
                              onChange={ev =>
                                handleChange(ev)
                              }
                              type="text"
                              variant="outlined"
                              as={CustomTextField}
                              InputProps={{
                                disabled: false,
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid md={12} style={{ marginBottom: '15px' }}>
                          {/* <Field type="checkbox" name="chargeTasker" /> */}
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              marginTop: '12px'
                            }}
                          >
                          <Typography variant="body1" component="span">
                            {' '}
                            Up to  {'     '} 
                          </Typography>
                             <TextField
                             style={{marginLeft:"30px"}}
                              margin="none"
                              name="chargeUpTo"
                              value={values.chargeUpTo}
                              disabled={false}
                              onChange={ev =>
                                handleChange(ev)
                              }
                              type="text"
                              variant="outlined"
                              as={CustomTextField}
                              InputProps={{
                                disabled: false,
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid md={12}>
                          <Typography variant="body1">
                            Amount to refund to Poster
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div>
                            <Slider
                              value={sliderValue}
                              min={0}
                              max={100}
                              onChange={handleSliderChange}
                              classes={{
                                thumb: classes.thumb,
                                track: classes.track,
                                rail: classes.rail
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              marginTop: '12px'
                            }}
                          >
                             <TextField
                             
                              error={Boolean(
                                touched.posterPercentage &&
                                errors.posterPercentage
                              )}
                              helperText={
                                touched.posterPercentage &&
                                errors.posterPercentage
                              }
                              margin="none"
                              name="posterPercentage"
                              value={values.includeBookingFees ? (((sliderValue / 100) * taskPrice) + parseFloat(task.assignedWorker.bookingFees)).toFixed(2) : ((sliderValue / 100) * taskPrice).toFixed(2)}
                              disabled={false}
                              onBlur={handleSliderBlur}
                              onChange={ev =>
                                handleInputChange(ev, setFieldValue)
                              }
                              type="text"
                              variant="outlined"
                              as={CustomTextField}
                              InputProps={{
                                disabled: false,
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                            />
                            <Typography
                              variant="body1"
                              style={{
                                display: 'inline-block',
                                marginLeft: '75px',
                                marginTop: '12px'
                              }}
                            >
                              Poster will be refunded {sliderValue + '%'} of the
                              task price.
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item md={12}>
                          <Typography variant="body1">Reason:</Typography>
                          <FormControl variant="outlined" fullWidth>
                            {renderOptions()}
                          </FormControl>
                        </Grid>
                        <Grid md={12} style={{ marginBottom: '15px' }}>
                          {reason === "Others" && (
                            <div style={{ marginTop: "16px" }}>
                              <Typography variant="body1">Other Reason:</Typography>
                              <TextField
                                error={Boolean(
                                  touched.customReason && errors.customReason
                                )}
                                helperText={
                                  touched.customReason && errors.customReason
                                }
                                margin="none"
                                fullWidth
                                InputProps={{
                                  style: { marginTop: "8px" }
                                }}
                                name="customReason"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                multiline
                                rows={5}
                                rowsMax={10}
                                value={values.customReason}
                                as={CustomTextField}

                                variant="outlined"
                              />
                            </div>
                          )}
                        </Grid>
                      </>
                    )}
                    {resolution === 'complete' && (
                      <>
                        <Grid md={12}>
                          <Typography variant="body1">
                            Amount to refund to Poster
                          </Typography>
                        </Grid>
                        <div
                          style={{
                            width: '100%',
                            position: 'relative',
                            height: 'fit-content'
                          }}
                        >
                          <Slider
                            value={
                              typeof sliderValue === 'number' ? sliderValue : 0
                            }
                            onBlur={handleSliderBlur}
                            onChange={handleSliderChange}
                            classes={{
                              thumb: classes.thumb,
                              track: classes.track,
                              rail: classes.rail2
                            }}
                          />
                          <div
                            style={{
                              position: 'absolute',
                              width: (1 - sliderValue / 100) * 15 + `%`,
                              height: '0.90rem',
                              right: '0px',
                              top: '13px',
                              borderTopRightRadius: '24px',
                              borderBottomRightRadius: '24px',
                              border: '1px solid transparent',
                              backgroundColor: '#5B4BFE'
                            }}
                          ></div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '12px'
                          }}
                        >
                          <Grid item style={{ width: '100%' }}>
                          <TextField
                              error={Boolean(
                                touched.posterPercentage &&
                                errors.posterPercentage
                              )}
                              helperText={
                                touched.posterPercentage &&
                                errors.posterPercentage
                              }
                              margin="none"
                              name="posterPercentage"
                              value={((sliderValue / 100) * taskPrice).toFixed(
                                0
                              )}
                              onBlur={handleBlur}
                              onChange={handleInputChange}
                              type="text"
                              variant="outlined"
                              as={CustomTextField}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Typography
                            variant="body1"
                            style={{
                              display: 'inline-block'
                            }}
                          >
                            Poster will be refunded {sliderValue + '%'} of the
                            task price.
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <Grid md={3} style={{ marginBottom: '15px' }}>
                          <TextField
                              error={Boolean(
                                touched.taskerPercentage &&
                                errors.taskerPercentage
                              )}
                              helperText={
                                touched.taskerPercentage &&
                                errors.taskerPercentage
                              }
                              margin="none"
                              name="taskerPercentage"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={
                                0.85 *
                                (taskPrice - (sliderValue / 100) * taskPrice)
                              }
                              disabled={false}
                              variant="outlined"
                              as={CustomTextField}
                              //disabled={taskerDisabled}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>

                          <Typography
                            variant="body1"
                            style={{
                              display: 'inline-block',
                              marginLeft: '75px',
                              marginTop: '12px'
                            }}
                          >
                            tasker receives {'$' + taskerRecievesValue}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%'
                          }}
                        >
                          <Grid md={3} style={{ marginBottom: '15px' }}>
                          <TextField
                              error={Boolean(
                                touched.taskerPercentage &&
                                errors.taskerPercentage
                              )}
                              helperText={
                                touched.taskerPercentage &&
                                errors.taskerPercentage
                              }
                              margin="none"
                              name="taskerPercentage"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={
                                0.15 *
                                (taskPrice - (sliderValue / 100) * taskPrice)
                              }
                              disabled={false}
                              variant="outlined"
                              as={CustomTextField}
                              //disabled={taskerDisabled}
                              InputProps={{
                                classes: {
                                  root: classes.inputRoot
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                          <Typography
                            variant="body1"
                            style={{
                              display: 'inline-block',
                              marginLeft: '75px',
                              marginTop: '12px'
                            }}
                          >
                            Sydetasker receives {'$' + sydetaskerRecievesValue}
                          </Typography>
                        </div>
                      </>
                    )}


                    {/* <Grid item md={12}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Summary:
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      > 
                         {values.includeBookingFees
                          ? `Amount to be refunded to Poster: ${(values.posterPercentage /
                            100) *
                          parseFloat(task?.Worker.taskPrice) +
                          parseFloat(task?.Worker.taskPrice) *
                          0.05}`
                          : `Amount to be refunded to Poster: ${(values.posterPercentage /
                            100) *
                          parseFloat(task?.Worker.taskPrice)}`}
                        $
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Tasker received:{' '}
                        {(values.taskerPercentage / 100) *
                          parseFloat(task?.worker?.taskPrice)}
                        $
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Tasker charged:{' '}
                        {resolution === 'cancel'
                          ? (values.taskerCharge / 100) *
                              parseFloat(task?.Worker?.taskPrice) <=
                            20
                            ? 20
                            : (values.taskerCharge / 100) *
                              parseFloat(task?.Worker?.taskPrice)
                          : 0}
                        $
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Sydetasker keeps:
                        {parseFloat(task?.Worker.totalPaid) -
                          ((values.posterPercentage / 100) *
                            parseFloat(task?.Worker.taskPrice) +
                            (values.taskerPercentage / 100) *
                            parseFloat(task?.Worker.taskPrice) +
                            (values.taskerCharge / 100) *
                            parseFloat(task?.Worker.taskPrice) +
                            (values.includeBookingFees
                              ? parseFloat(task?.Worker.taskPrice) *
                              0.05
                              : 0) +
                            (resolution === 'cancel'
                              ? (values.taskerCharge / 100) *
                                parseFloat(task?.Worker.taskPrice) <=
                                20
                                ? 20
                                : (values.taskerCharge / 100) *
                                parseFloat(task?.Worker.taskPrice)
                              : 0))}
                        $ 
                      </Typography>
                    </Grid> */}
                  </Grid>
                  <div>
                    <Box
                      my={2}
                      pt={3}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Button
                        disabled={isSubmitting}
                        sizeLarge
                        onClick={() => {
                          setOpenDialog(false);
                          closeDialog();
                        }}
                        variant="contained"
                        style={{
                          width: '35%',
                          borderRadius: '25px',
                          border: '2px solid #bdbdbd',
                          wordBreak: 'unset',
                          color: 'rgb(160,160,160)'
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        sizeLarge
                        type="submit"
                        variant="contained"
                        style={{ width: '50%', borderRadius: '25px' }}
                      >
                        {isSubmitting ? 'Processing ...' : 'Confirm'}
                      </Button>
                    </Box>
                  </div>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CancellationDialog;
