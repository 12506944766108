import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badges from '../badges';
import FirebaseUtility from '../../utils/firebase.utils';
import BgCheck from '../../assets/badges/bg_check.png';
import Icon from 'react-eva-icons';
import IdCheck from '../../assets/badges/id_check.png';
import Insurance from '../../assets/badges/insurance.png';

const badgesLabels = [
  {
    label: 'ID',
    icon: BgCheck,
  },
  {
    label: 'Phone',
    icon: <Icon fill={'blue'} name={"phone-outline"} size="medium" />,
  },
  {
    label: 'Insurance',
    icon: Insurance,
  },
  {
    label: 'Background Check',
    icon: IdCheck,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 20px'
  },
  divider: {
    height: '30px'
  }
}));

const BadgesTab = ({ user, setLoading }) => {
  const [badges, setBadges] = useState([]);
  const badgesKeys = ['ID','Phone' ,'Insurance', 'BackgroundCheck'];
  const classes = useStyles();

  const verifyBadgesObject = (userBadges) => {
    const updateBadges = [];
    badgesKeys.forEach((key) => {
      const item = userBadges.find((b) => b.name == key);
      if (!item) {
        updateBadges.push({
          name: key,
          status: 'un-verified',
          verificationId: '',
        });
      } else {
        updateBadges.push(item);
      }
    });
    setBadges(updateBadges);
  };

  const handleBadgeChange = async (key) => {
    setLoading(true);
    const _badges = JSON.parse(JSON.stringify(badges));
    const userHasBadge = _badges.findIndex((b) => b.name == key);
    if (userHasBadge > -1) {
      _badges[userHasBadge].status = badges[userHasBadge].status == 'verified'
        ? 'un-verified'
        : 'verified';
    }

    setBadges(_badges);
    const toSave = _badges.filter((b) => b.status == 'verified');
    await FirebaseUtility.setDocument(
      'users',
      user.uid,
      { badges: toSave },
      true,
    );
    user.badges = toSave;
    toast.success('Saved successfully');
    setLoading(false);
  };

  const hasTheBadge = (key) => {
    const hasBadge = badges.findIndex((b) => b.name == key);
    if (hasBadge < 0) {
      return 'default';
    }
    if (badges[hasBadge].status == 'verified') {
      return 'outline';
    }
    return 'default';
  };

  useEffect(() => {
    setBadges(user.badges);
    verifyBadgesObject(user.badges || []);
  }, []);
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        {badgesLabels && badges
          ? badgesLabels.map((badge, index) => (
            <Grid item md={4}>
              <Badges
                label={badge.label}
                size="medium"
                icon={badge.icon}
                variant={hasTheBadge(badgesKeys[index])}
                handleClick={() => handleBadgeChange(badgesKeys[index])}
              />
            </Grid>
          ))
          : null}
      </Grid>
    </div>
  );
};

export default BadgesTab;
