import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const Toolbar = ({
  className,
  searchTasksMethod,
  isLoading,
  fetchMoreTasksFB,
  filterData,
  ...rest
}) => {
  const [text, setText] = useState('');
  const [disableSearch, setDisable] = useState(false);
  const [filter, setFilter] = useState('');
  const [value, setValue] = React.useState('');

  const handleRadioChange = (event) => {
    setValue(event.target.value); 
    return filterData(filter, event.target.value);
  };

  const filterTasks = ev => {
    const selectedFilter = ev.target.value;
    localStorage.setItem('Filter', selectedFilter);
    if (
      selectedFilter === 'completed' ||
      selectedFilter === 'cancelled' ||
      selectedFilter === 'expired'   ||
      selectedFilter==='disputed'
    ) {
      setDisable(true);
      setText('');
    } else {
      setDisable(false);
    }
    setFilter(selectedFilter);
    return filterData(selectedFilter, value);
  };
  useEffect(() => {
    setFilter('open');
    filterData('open', false);
  }, []);

  return (
    <div className={clsx(className)} {...rest}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item md={4}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    value={text}
                    disabled={!!isLoading || disableSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    onChange={e => {
                      setText(e.target.value);
                      if (e.target.value === '') {
                        searchTasksMethod('', filter);
                      }
                    }}
                    onKeyPress={async e => {
                      const code = e.keyCode ? e.keyCode : e.which;
                      // on press enter
                      if (code == 13) {
                        e.preventDefault();
                        // search
                        await searchTasksMethod(text, filter);
                      }
                    }}
                    placeholder="Search Tasks"
                    variant="outlined"
                  />
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{
                      color: '#c3c3c3'
                    }}
                  >
                    {disableSearch
                      ? 'Text search is not applicable for this status'
                      : 'Please press Enter to search'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box maxWidth="400px">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      fullWidth
                      labelId="status-label"
                      id="status-label"
                      label="Status"
                      value={filter}
                      onChange={ev => {
                        filterTasks(ev);
                      }}
                    >
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="assigned">Assigned</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                      <MenuItem value="expired">Expired</MenuItem>
                      <MenuItem value="disputed">Disputed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{
                      color: '#c3c3c3'
                    }}
                  >
                    Select task status to filter the data
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle1">
                  Filter By Remote Tasks:{' '}
                </Typography>
                <RadioGroup
                  aria-label="isRemote"
                  name="isRemote"
                  value={value}
                  onChange={handleRadioChange}
                  row
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  searchTasksMethod: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  filterData: PropTypes.func
};

export default Toolbar;
