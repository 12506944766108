import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

const SupportView = ({ users, ...rest }) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={3} className={classes.borderRight500}>
          <Divider />
          <Grid item xs={12} style={{ padding: '10px' }}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Divider />
          <List>
            {users.map((user, index) => (
              <ListItem
                button
                selected={user.id === selectedUser ? true : false}
                onClick={() => {
                  setSelectedUser(user.id);
                }}
                key={`${index}`}
              >
                {user.profilePicture && user.profilePicture != '' ? (
                  <ListItemIcon>
                    <Avatar alt={user.firstName} src={user.profilePicture} />
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <Avatar>
                      {user.firstName && user.firstName.substr(0, 1)}
                      {user.lastName && user.lastName.substr(0, 1)}
                    </Avatar>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={`${user.firstName} ${user.lastName}`}
                ></ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={9}>
          <List className={classes.messageArea}>
            <ListItem key="1">
              <Grid container style={{ justifyContent: 'flex-start' }}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '0% 1%'
                  }}
                >
                  <Grid item xs={12}>
                    <ListItemText
                      align="left"
                      primary="Hello, i'm facing an issue with my payment on a task i've created."
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText align="left" secondary="09:30"></ListItemText>
                  </Grid>
                </Paper>
              </Grid>
            </ListItem>
            <ListItem key="2">
              <Grid container style={{ justifyContent: 'flex-end' }}>
                <Paper
                  elevation={3}
                  style={{
                    backgroundColor: '#2c387e',
                    padding: '0% 1%'
                  }}
                >
                  <Grid item xs={12}>
                    <ListItemText
                      style={{ color: 'white' }}
                      align="right"
                      primary="Welcome Ashley, we will start troubleshooting the issue and you will get it fixed ASAP"
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12}>
                    <ListItemText
                      align="right"
                      secondaryTypographyProps={{
                        style: { color: '#ffffffd1' }
                      }}
                      secondary="09:31"
                    ></ListItemText>
                  </Grid>
                </Paper>
              </Grid>
            </ListItem>
          </List>
          <Divider />
          <Grid container>
            <Grid item xs={11}>
              <TextField
                id="outlined-basic-email"
                placeholder="Type Something"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid xs={1} align="right">
              <Fab
                color="primary"
                style={{
                  height: '90%',
                  width: '85%',
                  marginTop: '5%',
                  marginRight: '6%'
                }}
                aria-label="add"
              >
                <SendIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SupportView;
