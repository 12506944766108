import React, { MouseEventHandler } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: "8px 12px",
        background: "white",
        borderRadius: "4px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "rgb(250,250,250)"
        }
    },
    errorItem: {
        padding: "8px 12px",
        background: "white",
        borderRadius: "4px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "rgb(255,248,248)"
        }
    }
}));

const MenuListItem = ({ children, icon, onClick, error }) => {

    const classes = useStyles();

    return (
        <div className={error ? classes.errorItem : classes.item} onClick={onClick}>
            {
                icon &&
                <div style={{ marginRight: "16px", color: error ? "rgb(198,53,89)" : "rgb(101, 119, 134)", transform: "translateY(2px)" }}>
                    {icon}
                </div>
            }
            <Typography variant={"body2"} regular style={{ color: error ? "rgb(198,53,89)" : "rgb(20,20,20)" }}>
                {children}
            </Typography>
        </div>
    )
}

export default MenuListItem;