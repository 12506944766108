import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import typography from '../../theme/typography';
import firebaseUtility from '../../utils/firebase.utils';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles(() => ({
  root: {
    padding: '30px 20px',
  },
  divider: {
    height: '30px',
  },
}));

// eslint-disable-next-line react/prop-types
const ReportTab = ({ contact }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
      >     
            <Typography variant="caption">
                 Description
                <Typography variant="body1">
                    {contact.description}
                  </Typography>
                </Typography>           
      </Grid>
    </div>
  );
};

export default ReportTab;
