import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Text from "../../text";
import { ArrowBack, Close } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import useTheme from "@material-ui/core/styles/useTheme";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomIconButton from "../input-fields/custom-icon-button";

const useStyles = makeStyles((theme) => ({
    dialog: {
        position: "relative",
        zIndex: 1301,
        background: theme.palette.background.default,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: "10px",
        padding: "0",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        transition: theme.transitions.create(["box-shadow"], {
            easing: theme.transitions.easing.easeInOut,
            duration: "0.3s",
        }),
        [theme.breakpoints.down('xs')]: {
            height: "100%",
            borderRadius: "0px",
        }
    }
}));

const DialogWrapper = ({ open, onClose, title, children, root, onReturn, suffixIcon = <div />, disableBackdrop = false, height = "500px", ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth classes={{ paper: classes.dialog }}
            BackdropProps={{
                style: {
                    background: !disableBackdrop ? "rgba(0, 0, 0, 0.5)" : "rgb(0,0,0,0)"
                }
            }}
            style={{ zIndex: 1301 }} fullScreen={xsDown}>
            <DialogTitle style={{ borderBottom: "1.5px solid rgb(220,220,220)", position: "relative", padding: "12px 24px" }}>
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <CustomIconButton style={{ transform: "translateX(-3px)", marginRight: '12px' }} onClick={root ? onClose : onReturn}>
                            <Close style={{ opacity: root ? 1 : 0, transform: `rotate(${root ? "0deg" : "-15deg"})`, transition: "0.3s" }} />
                            <ArrowBack style={{ position: "absolute", opacity: !root ? 1 : 0, transform: `rotate(${!root ? "0deg" : "15deg"})`, transition: "0.3s" }} />
                        </CustomIconButton>
                        <Text variant="body1" black component={"span"}>
                            {title}
                        </Text>
                    </div>
                    <div style={{ marginLeft: "auto" }} />
                    {suffixIcon}
                </div>
            </DialogTitle>
            <DialogContent style={{ maxHeight: xsDown ? "unset" : height }}>
                {children}
            </DialogContent>
        </Dialog >
    )
}

export default DialogWrapper;