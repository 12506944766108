import firebase from 'firebase';
import configs from '../configs';

const configsObj = configs();

firebase.initializeApp(configsObj.firebaseConfigs);

const firebaseUtility = {
  Auth: () => firebase.auth(),
  Firestore: () => firebase.firestore(),
  Storage: () => firebase.storage(),
  CollectionRef: (collectionName) => {
    const collectionRef = firebase.firestore().collection(collectionName);
    return collectionRef;
  },
  GetAllDocumentsByRef: async (collectionRef) => {
    const docsRef = await collectionRef.get();
    const arr = docsRef.docs.map((d) => {
      return { ...d.data(), id: d.id };
    });
    return arr;
  },
  GetAllDocuments: async (collectionName) => {
    const docsRef = await firebase.firestore().collection(collectionName).get();
    const arr = docsRef.docs.map((d) => {
      return { ...d.data(), id: d.id };
    });
    return arr;
  },
  GetNextPageAllDocuments: async (collectionName, pageSize, orderProperty, asc = 'asc') => {
    const docsRef = await firebase.firestore().collection(collectionName)
      .orderBy(orderProperty, asc)
      .limit(pageSize)
      .get();
    const mappedItems = docsRef.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id
      };
    });
    return mappedItems;
  },
  GetNextPageAllDocumentsWhere: async (collectionName, pageSize, ref, orderProperty, property, operation, value) => {
    const docsRef = await firebase.firestore().collection(collectionName)
      .orderBy(orderProperty, 'desc')
      .where(property, operation, value)
      .startAfter(ref)
      .limit(pageSize)
      .get();
    const mappedItems = docsRef.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id
      };
    });
    return mappedItems;
  },

  GetPrevPageAllDocuments: async (collectionName, pageSize, ref, orderProperty, asc = 'asc') => {
    const docsRef = await firebase.firestore().collection(collectionName)
      .orderBy(orderProperty, asc || 'desc')
      .endBefore(ref && ref[orderProperty] || null)
      .limitToLast(pageSize)
      .get();
    const mappedItems = docsRef.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id
      };
    });
    return mappedItems;
  },
  GetOneDocById: async (collectionName, docId) => {
    const docRef = await firebase.firestore().collection(collectionName).doc(docId).get();
    return (docRef.exists) ? docRef.data() : null;
  },
  addNewDocument: async (collectionName, data) => {
    const result = await firebase.firestore().collection(collectionName).add(data);
    return result;
  },
  setDocument: async (collectionName, docId, updatedObject, merge = true) => {
    const result = await firebase.firestore().collection(collectionName).doc(docId).set({
      ...updatedObject
    }, { merge });
    return result;
  },
  UpdateDocument: async (collectionName, docId, updatedObject) => {
    const result = await firebase.firestore().collection(collectionName).doc(docId).update({
      ...updatedObject
    });
    return result;
  },
  findDocumentBy: async (collectionName, property, operation, value) => {
    const userDoc = await firebase.firestore().collection(collectionName).where(property, operation, value).get();
    if (!userDoc.empty) {
      return userDoc.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    }
    return null;
  },
  SignIn: async (email, password, admin = true) => {
    const signInUserResult = await firebase.auth().signInWithEmailAndPassword(email, password);
    return signInUserResult;
  },
  SignOut: async () => {
    const result = await firebase.auth().signOut();
    return result;
  },
  getStatisticsRL: () => {
    return firebase.firestore().collection('analysis').doc('dashboard')
      .onSnapshot;
  }
};
// Collections
export const users = firebase.firestore().collection("users");
export const privateUsers = firebase.firestore().collection("users_stripe");
export const tasks = firebase.firestore().collection("tasks");
export const auth = firebase.auth();
export const notifications = (uid) => firebase.firestore().collection("users").doc(uid).collection("notifications");
export const myTaskAttachments = (uid) => firebase.storage().ref(`/taskAttachments/${uid}`);
export default firebaseUtility;
