import React from "react";
import Bar from "./Bar";
import useAudioPlayer from './useAudioPlayer';

const Audio = ({ src, mine, id, audioDuration, style }) => {
  const { curTime, duration, playing, setPlaying } = useAudioPlayer(id);
  return (
    <div className="player" style={{ width: "100%", height: "inherit" }}>
      <audio id={"audio" + id} >
        <source src={src} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls" style={{ width: "100%", height: "100%", }}>
        <Bar isMine={mine} style={style} isPlaying={playing} togglePlay={setPlaying} curTime={curTime} duration={duration} width={audioDuration} />
      </div>
    </div>
  );
}

export default Audio;