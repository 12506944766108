import React, { useEffect, useRef, useState } from 'react';
import {
  Drawer, Grid, useMediaQuery, useTheme
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CancellationDialog from '../../components/disputeComponents/cancellationDialog';
import firebaseUtility from '../../utils/firebase.utils';
import TaskCard from '../../components/taskCard';
import DisputeContainer from '../../components/disputeComponents/disputeContainer';
import Page from '../../components/Page';
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '24px'
  },
  drawerPaper: {
    transition: '0.3s',
    margin: '10px 0',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '560px'
  },
}));

const DisputesPage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const sentinel = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastDoc, setLastDoc] = useState(0);
  const [selectedTask, setTask] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (hasMore) {
          const tasksRef = firebaseUtility.Firestore()
            .collection('tasks')
            .orderBy('createAt', 'desc')
            .where('isDispute', '==', true)
            .startAfter(lastDoc)
            .limit(5)
            .get();
          if (!tasksRef.empty) {
            const tasksList = [];
            tasksRef.docs.forEach((doc) => {
              tasksList.push(doc.data());
            });
            setLastDoc(tasksRef.docs[tasksRef.docs.length - 1]);
            setTasks([...tasks, ...tasksList]);
            if (tasksRef.docs.length <= 5) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        }
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) observer.observe(sentinel.current);

    return function cleanup() {
      observer.disconnect();
    };
  });

  useEffect(() => {
    (async () => {
      const tasksRef = await firebaseUtility.Firestore()
        .collection('tasks')
        .orderBy('createAt', 'desc')
        .where('isDispute', '==', true)
        .limit(5)
        .get();
      if (!tasksRef.empty) {
        const tasksList = [];
        tasksRef.docs.forEach((doc) => {
          tasksList.push(doc.data());
        });
        setLastDoc(tasksRef.docs[tasksRef.docs.length - 1]);
        setTasks(tasksList);
        if (tasksRef.docs.length <= 5) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    })();
  }, [tasks]);

  const handleOpenDialog = (task) => {
    setOpenDialog(true)
    setTask(task)
  }

  return (
    <Page title="Disputed">
      <Grid
        container
        direction="row"
        justify="space-between"
      >
        <Grid item md={4} className={classes.drawerPaper}>
          {
            (tasks.length) ? tasks.map((task, index) => (
              <>
                <TaskCard task={task} fromDispute={true} />
                <div style={{ height: '16px' }} />
              </>
            ))
              : null
          }
          <div ref={sentinel} />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
          <DisputeContainer openDialog={handleOpenDialog} />
        </Grid>
      </Grid>

      {openDialog
        && (
          <CancellationDialog
            opened={openDialog}
            task={selectedTask}
            closeDialog={() => setOpenDialog(false)}
          />
        )}
    </Page>
  );
};

export default DisputesPage;
