import React from "react";
import Text from "../views/task/edit task/text";
import ReactBnbGallery from "react-bnb-gallery";
const AttachmentHandler = (images, onClick, CustomClick ) => {
    const [isOpen, setOpen] = React.useState(false);
    const [main, setMain] = React.useState(0);
    const imagesList = Array.isArray(images.images) &&images.images.length > 0 ?images.images :[]; 
    const handleOpen = (status, img) => {
        if (CustomClick) {
            onClick(status, img);
        } else {
            if (img) setMain(img);
            setOpen(status);
        }
    };
    const getGrid = () => {
        switch (imagesList.length) {
            case 1:
                return <div onClick={() => handleOpen(true, 0)} style={{ background: `url(${imagesList[0]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", paddingBottom: "60%", borderRadius: "12px", cursor: "pointer" }} />;
            case 2:
                return <div style={{ display: "flex", overflow: "hidden", borderRadius: "12px", width: "100%", maxHeight: "215px" }}>
                    <div onClick={() => handleOpen(true, 0)} style={{ background: `url(${imagesList[0]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "calc(50% - 2px)", paddingBottom: "60%", cursor: "pointer" }} />
                    <div style={{ width: "4px", height: "100%", background: "white" }} />
                    <div onClick={() => handleOpen(true, 1)} style={{ background: `url(${imagesList[1]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "calc(50% - 2px)", paddingBottom: "60%", cursor: "pointer" }} />
                </div>;
            case 3:
                return <div style={{ display: "flex", overflow: "hidden", borderRadius: "12px", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        <div onClick={() => handleOpen(true, 0)} style={{ background: `url(${imagesList[0]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                        <div style={{ height: "4px", width: "100%", background: "white" }} />
                        <div onClick={() => handleOpen(true, 1)} style={{ background: `url(${imagesList[1]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                    </div>
                    <div style={{ width: "4px", height: "100%", background: "white" }} />
                    <div onClick={() => handleOpen(true, 2)} style={{ background: `url(${imagesList[2]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "calc(50% - 2px)", paddingBottom: "60%", cursor: "pointer" }} />
                </div>;
            case 4:
                return <div style={{ display: "flex", overflow: "hidden", borderRadius: "12px", width: "100%" }}>
                    <div style={{ paddingBottom: "60%" }} />
                    <div style={{ flex: 1 }}>
                        <div onClick={() => handleOpen(true, 0)} style={{ background: `url(${imagesList[0]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                        <div style={{ height: "4px", width: "100%", background: "white" }} />
                        <div onClick={() => handleOpen(true, 1)} style={{ background: `url(${imagesList[1]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                    </div>
                    <div style={{ width: "4px", height: "100%", background: "white" }} />
                    <div style={{ flex: 1 }}>
                        <div onClick={() => handleOpen(true, 2)} style={{ background: `url(${imagesList[2]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                        <div style={{ height: "4px", width: "100%", background: "white" }} />
                        <div onClick={() => handleOpen(true, 3)} style={{ background: `url(${imagesList[3]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                    </div>
                </div>;
            default:
                return <div style={{ display: "flex", overflow: "hidden", borderRadius: "12px", width: "100%" }}>
                    <div style={{ paddingBottom: "60%" }} />
                    <div style={{ flex: 1 }}>
                        <div onClick={() => handleOpen(true, 0)} style={{ background: `url(${imagesList[0]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                        <div style={{ height: "4px", width: "100%", background: "white" }} />
                        <div onClick={() => handleOpen(true, 1)} style={{ background: `url(${imagesList[1]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                    </div>
                    <div style={{ width: "4px", height: "100%", background: "white" }} />
                    <div style={{ flex: 1 }}>
                        <div onClick={() => handleOpen(true, 2)} style={{ background: `url(${imagesList[2]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} />
                        <div style={{ height: "4px", width: "100%", background: "white" }} />
                        <div onClick={() => handleOpen(true, 3)} style={{ background: `url(${imagesList[3]}) center center / cover`, backgroundColor: "rgb(240,240,240)", width: "100%", height: "calc(50% - 2px)", cursor: "pointer" }} >
                            <div style={{ width: "100%", height: "100%", background: "rgb(0 0 0 / 77%)", display: "flex", justifyContent: "center" }}>
                                <Text variant={"body2"} style={{ color: "white", margin: "auto", width: "fit-content" }} >{`+${images.length - 3}`}</Text>
                            </div>
                        </div>
                    </div>
                </div>;
        }
    };


    return <div>
        {getGrid()}
        <ReactBnbGallery show={isOpen} activePhotoIndex={main} photos={imagesList} onClose={() => setOpen(false)} opacity={0.8} showThumbnails={false} />
   
    </div>

};

export default AttachmentHandler;