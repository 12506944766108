import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
// import DashboardView from 'src/views/reports/DashboardView';
import LoginView from './views/auth/LoginView';
import NotFoundView from './views/errors/NotFoundView';
import UsersView from './views/users/index';
import GrantAdminView from './views/grantAdmin/index';
import SupportView from './views/support-view/index';
import TasksView from './views/tasks';
import TaskView from './views/task';
import SupportPage from './views/SupportView/index';
import SupportEdit from './views/support-view/edit-article';
import Logs from './views/logs/index';
import Messaging from './components/private-messaging/messaging-index';
import OfferChat from './components/offerChat/offerChat';
import BlogsEditor from './views/blogs/index';
import BlogsList from './views/blogs/indexx'; //this blog list page
import EditBlog from './views/blogs/edit-blog';//


import DisputesPage from './views/disputes/disputesPage';
import Verifications from './views/verifications/index'
import Reports from './views/users-reports/index';
import ContactUs from './views/contact-us/index'
import PopularSearch from './views/popularSearch';
import AddArticle from './views/support-view/add-article';

const routes = (isAuth) => {
  return [
    {
      path: 'app',
      element: isAuth ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        // { path: 'dashboard', element: <DashboardView /> },
        { path: 'users', element: <UsersView /> },
        { path: 'users/:id', element: <UsersView /> },
        { path: 'Grant-Admin', element: <GrantAdminView /> },
        { path: 'popular-searches', element: <PopularSearch /> },
        { path: 'Grant-Admin/:id', element: <GrantAdminView /> },
        { path: 'reports', element: <Reports /> },
        { path: 'reports/:id', element: <Reports /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'contact-us/:id', element: <ContactUs /> },
        { path: 'users/:id/messaging/:msg_id', element: <UsersView /> },
        { path: 'support-articles', element: <SupportView /> },
        { path: 'support-articles/edit/:id', element: <SupportEdit /> },
        {path:'support-articles/add', element:<AddArticle />} ,
        { path: 'logs', element: <Logs /> },
        { path: 'support', element: <SupportPage /> },
        { path: 'disputes', element: <DisputesPage /> },
        { path: 'disputes/:id', element: <DisputesPage /> },
        { path: 'disputes/:id/messaging/:msg_id', element: <DisputesPage /> },
        { path: 'tasks', element: <TasksView /> },
        { path: 'verifications', element: <Verifications  /> },
        {path: 'verifications/:id', element: <Verifications />},
        { path: 'tasks/:id/:tab', element: <TaskView /> },
        { path: 'tasks/:id', element: <TaskView /> },

        { path: 'blogs-editor', element: <BlogsEditor /> },
        { path: 'blogs-list', element: <BlogsList /> },
        { path: 'blogs-list/edit/:id', element: <EditBlog /> },
 

        { path: 'tasks/:id/messaging/:msg_id', element: <TaskView /> },
        { path: '*', element: <Navigate to="/404" /> },
 /*       { path: 'messaging', element: <OfferChat /> },*/

      ]
    },
    {
      path: '/',
      element: !isAuth ? <MainLayout /> : <Navigate to="/app/users" />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];
};
export default routes;
