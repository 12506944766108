import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import Avatar from '../avatar';
import firebaseUtility, { users } from '../../utils/firebase.utils';

const Fade = require('react-reveal/Fade');

const useStyles = makeStyles(() => ({
  padding: {
    padding: '12px 16px',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
}));

const MessageViewerBottom = ({ admin }) => {
  const params = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [typing, setTyping] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const taskId = params.msg_id.split('_')[0];
    const offerId = params.msg_id.split('_')[1];
    const messageStatusStream = firebaseUtility.Firestore().collection('tasks')
      .doc(taskId)
      .collection('offers')
      .doc(offerId)
      .collection('messaging')
      .doc(params.msg_id)
      .onSnapshot(async (snapshot) => {
        if (snapshot.exists) {
          /*          const data = snapshot.data().typing;
                    Object.keys(data).forEach((key) => {
                      if (key !== userData?.uid) { setTyping(data[key]); }
                    }); */
        } else {
          const task = await firebaseUtility.Firestore().collection('tasks')
            .doc(taskId).get();
          const taskData = task.data();
          taskData.id = task.id;

          const posterUid = taskData.uid;
          const { title } = taskData;

          const offer = await firebaseUtility.Firestore().collection('tasks')
            .doc(taskId)
            .collection('offers')
            .doc(offerId)
            .get();
          const taskerUid = offer.data().uid;

          firebaseUtility.Firestore().collection('tasks')
            .doc(taskId)
            .collection('offers')
            .doc(offerId)
            .collection('messaging')
            .doc(params.msg_id)
            .set({
              expired: null,
              title,
              poster: posterUid,
              tasker: taskerUid,
              typing: {
                [posterUid]: false,
                [taskerUid]: false
              },
              users: [posterUid, taskerUid],
              lastRead: {
                [posterUid]: null,
                [taskerUid]: null
              },
              unRead: {
                [posterUid]: 0,
                [taskerUid]: 0
              }
            })
            .then();
        }
        setLoading(false);
      });

    return () => {
      messageStatusStream();
    };
  }, [params.msg_id]);

  const sendMessage = (e) => {
    e.preventDefault();
    return new Promise(((resolve, reject) => {
      const taskId = params.msg_id.split('_')[0];
      const offerId = params.msg_id.split('_')[1];
      if (message) {
        const m = message;
        setMessage('');

        if (m) {
          const ref = firebaseUtility.Firestore().collection('tasks')
            .doc(taskId)
            .collection('offers')
            .doc(offerId)
            .collection('messaging')
            .doc(params.msg_id);

          ref.get().then((doc) => {
            if (doc.exists) {
              const usersList = doc.data().users;
              let userExist = false;
              usersList.forEach((u) => {
                if (u == admin.uid) {
                  userExist = true;
                }
              });

              if (userExist) {
                ref.update({
                  users: firebase.firestore.FieldValue.arrayRemove(admin.uid)
                })
                  .then(() => {
                    ref.update({
                      users: firebase.firestore.FieldValue.arrayUnion(admin.uid)
                    });
                  });
              } else {
                ref.update({
                  users: firebase.firestore.FieldValue.arrayUnion(admin.uid)
                }).then();
              }
              ref.update({
                [`typing.${admin.uid}`]: false,
                [`unRead.${usersList.filter(item => !(item==admin.uid))}`]: firebase.firestore.FieldValue.increment(1),
                "updatedAt": new Date(),
                "lastMessage": {
                    "uid": admin.uid,
                    "message": message
                }
            }).then().catch((e) => console.log(e));

            }
          });
          
          ref
            .collection('messages')
            .add({
              text: message,
              uid: admin.uid,
              type: 'text',
              createAt: new Date(),
              isSupport: true
            })
            .then(() => setMessage(''));
          resolve('Success');
        }
      }
    }));
  };


  return (
    <div style={{
      zIndex: 1300,
      borderRadius: '8px',
      background: 'linear-gradient(0deg, rgba(247, 248, 251, 1), rgba(247, 248, 251, 0))',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '22px 0',
    }}
    >
      <div style={{
        border: '2px solid rgb(240,240,240)',
        borderRadius: '32px',
        boxShadow: '0 4px 18px 0 rgba(0,0,0,0.04)',
        width: 'calc(100% - 48px)',
        background: 'white'
      }}
      >
        <form onSubmit={sendMessage}>
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="standard"
            placeholder="Message..."
            fullWidth
            InputProps={{
              classes: {
                underline: classes.underline
              },
              startAdornment:
  <Avatar
    name={`${admin?.firstName} ${admin?.lastName}`}
    profilePicture={admin?.profilePicture}
    size={20}
    style={{ margin: '0 16px' }}
  />,
              endAdornment:
  <Button
    type="submit"
    disableRipple
    disableFocusRipple
    disableTouchRipple
    variant="text"
    style={{
      marginRight: '22px',
      padding: '4px 12px',
      textTransform: 'capitalize'
    }}
  >
    <Typography variant="body2">Send</Typography>
  </Button>
            }}
            classes={{
              root: classes.padding,
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default MessageViewerBottom;
