import React, { useEffect, useRef } from 'react';
import { Popper, Theme } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    zIndex: 1301,
    position: 'relative',
    padding: '12px',
    background: 'white',
    borderRadius: '6px',
    boxShadow: '0 10px 20px -14px rgba(50,50,50,0.3)',
    border: '1.5px solid rgb(240,240,240)',
    minWidth: '240px',
    boxSizing: 'border-box'
  }
}));

const MenuWrapper = ({ children, items }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <div
        ref={anchorRef}
        aria-controls={open ? 'menu' : "nundefied"}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1301 }}
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <div className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                {items}
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default MenuWrapper;
